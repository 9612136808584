import axios from "axios";

const authApi = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
});

authApi.interceptors.request.use((config) => {
  const authUser = JSON.parse(localStorage.getItem("authUser"));
  const selectedCountry = JSON.parse(localStorage.getItem("selectedCountry"));
  const token = authUser.token;
  if (token) {
    config.headers["Authorization"] = "Bearer " + token;
  }
  config.headers["countryId"] = selectedCountry.id;
  config.headers["Content-Type"] = "application/json";
  config.headers["Accept"] = "application/json";
  return config;
});

export default authApi;
