import { Grid, Typography } from "@mui/material";
import { useGroups } from "../../context/group-context";
import { useModal } from "../../context/modal-context";
import LinearLoad from "../UI/LinearLoad";
import GroupBox from "./GroupBox";

const AllGroups = (props) => {
  const { setModalForAdditionalDelete } = props;
  const { groups, loading } = useGroups();
  const { openModal } = useModal();

  return (
    <>
      {loading && <LinearLoad />}
      <Grid container spacing={2}>
        {!loading && groups.length === 0 ? (
          <Grid item xs={12}>
            <Typography variant="h6">
              There are no groups at the moment.
            </Typography>
          </Grid>
        ) : (
          groups.length > 0 &&
          groups.map((group, index) => (
            <GroupBox
              key={index}
              onGroupDelete={() => openModal("delete", "group", group.id)}
              onGroupEdit={() => openModal("edit", "group", group.id)}
              onAddLocation={() => openModal("location", "group", group.id)}
              onAddTechnician={() => openModal("technician", "group", group.id)}
              onDeleteLocationFromGroup={(details) =>
                setModalForAdditionalDelete("location", group.id, details)
              }
              onDeleteTechnicianFromGroup={(details) =>
                setModalForAdditionalDelete("technician", group.id, details)
              }
              group={group}
            />
          ))
        )}
      </Grid>
    </>
  );
};

export default AllGroups;
