import { Delete, Edit, MoreVert } from "@mui/icons-material";
import {
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  ClickAwayListener,
  Grid,
  Grow,
  IconButton,
  Link,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from "@mui/material";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useModal } from "../../context/modal-context";

const CabinetCard = (props) => {
  const { cabinet } = props;
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const { openModal } = useModal();

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    setOpen(false);
  };

  const handleListKeyDown = (event) => {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  };

  return (
    <Grid item xs={12} sm={6} md={3}>
      <Card>
        <CardHeader
          title={`${cabinet.name}`}
          action={
            <div>
              <IconButton
                ref={anchorRef}
                id="composition-button"
                aria-controls={open ? "composition-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
              >
                <MoreVert />
              </IconButton>
              <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                placement="bottom-start"
                transition
                disablePortal
              >
                {({ TransitionProps }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin: "right top",
                    }}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={handleClose}>
                        <MenuList
                          autoFocusItem={open}
                          id="composition-menu"
                          aria-labelledby="composition-button"
                          onKeyDown={handleListKeyDown}
                        >
                          <MenuItem
                            onClick={() => {
                              openModal("edit", "cabinet", cabinet.id);
                              handleClose();
                            }}
                          >
                            <Edit color="success" /> Edit
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              openModal("delete", "cabinet", cabinet.id);
                              handleClose();
                            }}
                          >
                            <Delete color="error" /> Remove
                          </MenuItem>
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </div>
          }
        />
        <CardMedia
          component="img"
          height="194"
          image={`${process.env.REACT_APP_IMAGES_URL}/cabinets/${cabinet.picture}`}
          alt="Cabinet"
          sx={{ objectFit: "contain" }}
        />
        <CardContent sx={{ "&:last-child": { paddingBottom: "12px" } }}>
          <Link
            onClick={() => navigate(`/machines?cid=${cabinet.id}`)}
            sx={{
              cursor: "pointer",
              textDecoration: "none",
              "&:hover": { textDecoration: "underline" },
            }}
          >
            Number of cabinets in clubs: {cabinet.cabinetInLocations}
          </Link>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default CabinetCard;
