import { useCountries } from "../../context/country-context";
import { useModal } from "../../context/modal-context";
import NTSTable from "../UI/NTSTable";

const AllCountries = () => {
  const { countries, loading } = useCountries();
  const { openModal } = useModal();

  const cols = [
    { field: "show_name", headerName: "Name", type: "string", width: 200 },
  ];

  return (
    <NTSTable
      onEdit={(id) => openModal("edit", "country", id)}
      onRemove={(id) => openModal("delete", "country", id)}
      data={countries}
      cols={cols}
      loading={loading}
      actions={true}
    />
  );
};

export default AllCountries;
