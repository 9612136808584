import { useCallback, createContext, useContext, useReducer } from "react";
import httpReducer from "../reducers/http-reducer";
import { useSnackbar } from "./snackbar-context";
import {
  createManufacturer,
  deleteManufacturer,
  getManufacturers,
  updateManufacturer,
} from "../services/api-service";

export const ManufacturerContext = createContext({
  manufacturers: [],
  loading: false,
  loadManufacturers: () => {},
  addManufacturer: (manufacturerObj) => {},
  editManufacturer: (id, manufacturerObj) => {},
  removeManufacturer: (id) => {},
});

export const ManufacturerContextProvider = (props) => {
  const { showSnackbar, resetSnackbar } = useSnackbar();
  const [state, dispatch] = useReducer(httpReducer, {
    data: [],
    loading: false,
  });

  const loadManufacturers = useCallback(async () => {
    dispatch({ type: "START_LOAD" });
    resetSnackbar();
    try {
      const response = await getManufacturers();
      const manufacturersData = response.data.data;
      dispatch({ type: "ADD_DATA", data: manufacturersData });
    } catch (error) {
      dispatch({ type: "END_LOAD" });
      const errorMessage = error.response?.data?.message || error.message;
      showSnackbar(errorMessage, "error", "red");
    }
    // eslint-disable-next-line
  }, []);

  const addManufacturer = async (manufacturerObj) => {
    dispatch({ type: "START_LOAD" });
    resetSnackbar();
    try {
      const response = await createManufacturer(manufacturerObj);
      const manufacturer = response.data.data;
      dispatch({ type: "ADD_ITEM", item: manufacturer });
      const message = response.data.message;
      showSnackbar(message, "success", "green");
    } catch (error) {
      dispatch({ type: "END_LOAD" });
      const errorMessage = error.response?.data?.message || error.message;
      showSnackbar(errorMessage, "error", "red");
    }
  };

  const editManufacturer = async (id, manufacturerObj) => {
    dispatch({ type: "START_LOAD" });
    resetSnackbar();
    try {
      const response = await updateManufacturer(id, manufacturerObj);
      const manufacturer = response.data.data;
      dispatch({ type: "EDIT_ITEM", id: id, data: manufacturer });
      const message = response.data.message;
      showSnackbar(message, "success", "green");
    } catch (error) {
      dispatch({ type: "END_LOAD" });
      const errorMessage = error.response?.data?.message || error.message;
      showSnackbar(errorMessage, "error", "red");
    }
  };

  const removeManufacturer = async (id) => {
    dispatch({ type: "START_LOAD" });
    resetSnackbar();
    try {
      const response = await deleteManufacturer(id);
      dispatch({ type: "REMOVE_ITEM", id: id });
      const message = response.data.message;
      showSnackbar(message, "success", "green");
    } catch (error) {
      dispatch({ type: "END_LOAD" });
      const errorMessage = error.response?.data?.message || error.message;
      showSnackbar(errorMessage, "error", "red");
    }
  };

  const ctxValue = {
    manufacturers: state.data,
    loading: state.loading,
    loadManufacturers,
    addManufacturer,
    editManufacturer,
    removeManufacturer,
  };

  return (
    <ManufacturerContext.Provider value={ctxValue}>
      {props.children}
    </ManufacturerContext.Provider>
  );
};

export const useManufacturers = () => useContext(ManufacturerContext);
