import { Container } from "@mui/material";
import { useEffect } from "react";
import AllRoles from "../components/Role/AllRoles";
import RoleModal from "../components/Role/RoleModal";
import CRUDHeader from "../components/UI/CRUDHeader";
import { useModal } from "../context/modal-context";
import { useRoles } from "../context/role-context";

const Roles = () => {
  const { loadRoles } = useRoles();
  const { openModal } = useModal();

  useEffect(() => {
    loadRoles();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Container maxWidth="sm" sx={{ paddingBottom: "16px" }}>
        <CRUDHeader
          title="Roles"
          onAddNew={() => openModal("add", "role")}
          onRefresh={() => loadRoles()}
        />
        <AllRoles />
      </Container>
      <RoleModal />
    </>
  );
};

export default Roles;
