import { Refresh } from "@mui/icons-material";
import { Container } from "@mui/material";
import { useEffect, useMemo, useRef } from "react";
import AllClosedServices from "../components/ServiceHistory/AllClosedServices";
import ChangedAndReturnedPartsModal from "../components/ServiceHistory/ChangedAndReturnedPartsModal";
import ClosedServicesFilter from "../components/ServiceHistory/ClosedServicesFilter";
import MachineProfileModal from "../components/ServiceHistory/MachineProfileModal";
import WorkReportModal from "../components/ServiceHistory/WorkReportModal";
import CRUDHeader from "../components/UI/CRUDHeader";
import { useCompanies } from "../context/company-context";
import { useLocations } from "../context/location-context";
import { useServices } from "../context/service-context";

const ServiceHistory = () => {
  const { clearServicesData, services } = useServices();
  const { loadCompanies } = useCompanies();
  const { searchLocations } = useLocations();
  const closedServicesFilterRef = useRef();

  useEffect(() => {
    clearServicesData();
    loadCompanies();
    searchLocations({ location_type_id: 1 });
    // eslint-disable-next-line
  }, []);

  const onCustom = useMemo(() => {
    return [
      {
        label: "Reset Filters",
        color: "info",
        onClick: () => closedServicesFilterRef.current.resetFilter(),
        icon: <Refresh />,
        sx: { width: 120 },
      },
    ];
  }, []);

  return (
    <>
      <Container maxWidth="xl" sx={{ paddingBottom: "16px" }}>
        <CRUDHeader title="Service History" onCustom={onCustom} />
        <ClosedServicesFilter ref={closedServicesFilterRef} />
        {services.length > 0 && <AllClosedServices />}
      </Container>
      <MachineProfileModal />
      <WorkReportModal />
      <ChangedAndReturnedPartsModal />
    </>
  );
};

export default ServiceHistory;
