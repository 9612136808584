import { Container } from "@mui/material";
import { useEffect, useState } from "react";
import AllGroups from "../components/Group/AllGroups";
import GroupModal from "../components/Group/GroupModal";
import CRUDHeader from "../components/UI/CRUDHeader";
import { useGroups } from "../context/group-context";
import { useModal } from "../context/modal-context";

const Groups = () => {
  const { loadGroups } = useGroups();
  const { openModal } = useModal();
  const [type, setType] = useState({ type: null, groupId: null });

  const setModalForAdditionalDelete = (type, groupId, details) => {
    openModal("delete", "group", groupId, { type, details });
  };

  useEffect(() => {
    loadGroups();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Container maxWidth="xl" sx={{ paddingBottom: "16px" }}>
        <CRUDHeader
          title="Groups"
          onAddNew={() => openModal("add", "group")}
          onRefresh={() => loadGroups()}
        />
        <AllGroups
          addType={type}
          setAddType={setType}
          setModalForAdditionalDelete={setModalForAdditionalDelete}
        />
      </Container>
      <GroupModal />
    </>
  );
};

export default Groups;
