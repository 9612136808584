import { useCallback, createContext, useContext, useReducer } from "react";
import httpReducer from "../reducers/http-reducer";
import {
  assignAbilitiesToRole,
  createRole,
  deleteRole,
  getAbilities,
  getRoles,
  getRoleTypes,
  updateRole,
} from "../services/api-service";
import { useSnackbar } from "./snackbar-context";

export const RoleContext = createContext({
  roles: [],
  roleTypes: [],
  abilities: [],
  rolesLoading: false,
  roleTypesLoading: false,
  abilitiesLoading: false,
  loadAbilities: () => {},
  loadRoles: () => {},
  loadRoleTypes: () => {},
  addRole: (roleObj, abilityIdsObject) => {},
  editRole: (id, roleObj, abilityIdsObject) => {},
  removeRole: (id) => {},
});

export const RoleContextProvider = (props) => {
  const { showSnackbar, resetSnackbar } = useSnackbar();
  const [roleState, roleDispatch] = useReducer(httpReducer, {
    data: [],
    loading: false,
  });
  const [roleTypeState, roleTypeDispatch] = useReducer(httpReducer, {
    data: [],
    loading: false,
  });
  const [abilityState, abilityDispatch] = useReducer(httpReducer, {
    data: [],
    loading: false,
  });

  const loadAbilities = useCallback(async () => {
    abilityDispatch({ type: "START_LOAD" });
    resetSnackbar();
    try {
      const response = await getAbilities();
      const abilitiesData = response.data.data;
      abilityDispatch({ type: "ADD_DATA", data: abilitiesData });
    } catch (error) {
      abilityDispatch({ type: "END_LOAD" });
      const errorMessage = error.response?.data?.message || error.message;
      showSnackbar(errorMessage, "error", "red");
    }
    // eslint-disable-next-line
  }, []);

  const loadRoles = useCallback(async () => {
    roleDispatch({ type: "START_LOAD" });
    resetSnackbar();
    try {
      const response = await getRoles();
      const rolesData = response.data.data;
      roleDispatch({ type: "ADD_DATA", data: rolesData });
    } catch (error) {
      roleDispatch({ type: "END_LOAD" });
      const errorMessage = error.response?.data?.message || error.message;
      showSnackbar(errorMessage, "error", "red");
    }
    // eslint-disable-next-line
  }, []);

  const loadRoleTypes = useCallback(async () => {
    roleTypeDispatch({ type: "START_LOAD" });
    resetSnackbar();
    try {
      const response = await getRoleTypes();
      const roleTypesData = response.data.data;
      roleTypeDispatch({ type: "ADD_DATA", data: roleTypesData });
    } catch (error) {
      roleTypeDispatch({ type: "END_LOAD" });
      const errorMessage = error.response?.data?.message || error.message;
      showSnackbar(errorMessage, "error", "red");
    }
    // eslint-disable-next-line
  }, []);

  const addRole = async (roleObj, abilityIdsObject) => {
    roleDispatch({ type: "START_LOAD" });
    resetSnackbar();
    try {
      const response = await createRole(roleObj);
      const role = response.data.data;
      const response2 = await assignAbilitiesToRole(role.id, abilityIdsObject);
      const roleAfterAssigns = response2.data.data;
      roleDispatch({ type: "ADD_ITEM", item: roleAfterAssigns });
      const message = response.data.message;
      showSnackbar(message, "success", "green");
    } catch (error) {
      roleDispatch({ type: "END_LOAD" });
      const errorMessage = error.response?.data?.message || error.message;
      showSnackbar(errorMessage, "error", "red");
    }
  };

  const editRole = async (id, roleObj, abilityIdsObject) => {
    roleDispatch({ type: "START_LOAD" });
    resetSnackbar();
    try {
      const response = await updateRole(id, roleObj);
      const role = response.data.data;
      const response2 = await assignAbilitiesToRole(role.id, abilityIdsObject);
      const roleAfterAssigns = response2.data.data;
      roleDispatch({ type: "EDIT_ITEM", id: id, data: roleAfterAssigns });
      const message = response.data.message;
      showSnackbar(message, "success", "green");
    } catch (error) {
      roleDispatch({ type: "END_LOAD" });
      const errorMessage = error.response?.data?.message || error.message;
      showSnackbar(errorMessage, "error", "red");
    }
  };

  const removeRole = async (id) => {
    roleDispatch({ type: "START_LOAD" });
    resetSnackbar();
    try {
      const response = await deleteRole(id);
      roleDispatch({ type: "REMOVE_ITEM", id: id });
      const message = response.data.message;
      showSnackbar(message, "success", "green");
    } catch (error) {
      roleDispatch({ type: "END_LOAD" });
      const errorMessage = error.response?.data?.message || error.message;
      showSnackbar(errorMessage, "error", "red");
    }
  };

  const ctxValue = {
    roles: roleState.data,
    roleTypes: roleTypeState.data,
    abilities: abilityState.data,
    rolesLoading: roleState.loading,
    roleTypesLoading: roleTypeState.loading,
    abilitiesLoading: abilityState.loading,
    loadAbilities,
    loadRoles,
    loadRoleTypes,
    addRole,
    editRole,
    removeRole,
  };

  return (
    <RoleContext.Provider value={ctxValue}>
      {props.children}
    </RoleContext.Provider>
  );
};

export const useRoles = () => useContext(RoleContext);
