import { useCallback, createContext, useContext, useReducer } from "react";
import httpReducer from "../reducers/http-reducer";
import {
  createPart,
  getParts,
  updatePart,
  deletePart,
  getPartTypes,
  createPartType,
  updatePartType,
  deletePartType,
  getPartsByFilter,
} from "../services/api-service";
import { useSnackbar } from "./snackbar-context";

export const PartContext = createContext({
  parts: [],
  partTypes: [],
  partsLoading: false,
  partTypesLoading: false,
  loadParts: () => {},
  loadPartTypes: () => {},
  searchParts: (searchObj) => {},
  addPart: (partObj) => {},
  editPart: (id, partObj) => {},
  removePart: (id) => {},
  addPartType: (partTypeObj) => {},
  editPartType: (id, partTypeObj) => {},
  removePartType: (id) => {},
  clearPartsData: () => {},
});

export const PartContextProvider = (props) => {
  const { showSnackbar, resetSnackbar } = useSnackbar();
  const [partsState, partsDispatch] = useReducer(httpReducer, {
    data: [],
    loading: false,
  });
  const [partTypesState, partTypesDispatch] = useReducer(httpReducer, {
    data: [],
    loading: false,
  });

  const loadParts = useCallback(async () => {
    partsDispatch({ type: "START_LOAD" });
    resetSnackbar();
    try {
      const response = await getParts();
      const partsData = response.data.data;
      partsDispatch({ type: "ADD_DATA", data: partsData });
    } catch (error) {
      partsDispatch({ type: "END_LOAD" });
      const errorMessage = error.response?.data?.message || error.message;
      showSnackbar(errorMessage, "error", "red");
    }
    // eslint-disable-next-line
  }, []);

  const loadPartTypes = useCallback(async () => {
    partTypesDispatch({ type: "START_LOAD" });
    resetSnackbar();
    try {
      const response = await getPartTypes();
      const partTypesData = response.data.data;
      partTypesDispatch({ type: "ADD_DATA", data: partTypesData });
    } catch (error) {
      partTypesDispatch({ type: "END_LOAD" });
      const errorMessage = error.response?.data?.message || error.message;
      showSnackbar(errorMessage, "error", "red");
    }
    // eslint-disable-next-line
  }, []);

  const searchParts = async (searchObj) => {
    partsDispatch({ type: "START_LOAD" });
    resetSnackbar();
    try {
      const response = await getPartsByFilter(searchObj);
      const partsData = response.data.data;
      if (partsData.length < 1)
        showSnackbar(
          "No results found according to search criteria",
          "warning",
          "orange"
        );
      partsDispatch({ type: "ADD_DATA", data: partsData });
    } catch (error) {
      partsDispatch({ type: "END_LOAD" });
      const errorMessage = error.response?.data?.message || error.message;
      showSnackbar(errorMessage, "error", "red");
    }
  };

  const addPart = async (partObj) => {
    partsDispatch({ type: "START_LOAD" });
    resetSnackbar();
    try {
      const response = await createPart(partObj);
      partsDispatch({ type: "END_LOAD" });
      const message = response.data.message;
      showSnackbar(message, "success", "green");
    } catch (error) {
      partsDispatch({ type: "END_LOAD" });
      const errorMessage = error.response?.data?.message || error.message;
      showSnackbar(errorMessage, "error", "red");
    }
  };

  const addPartType = async (partTypeObj) => {
    partTypesDispatch({ type: "START_LOAD" });
    resetSnackbar();
    try {
      const response = await createPartType(partTypeObj);
      const partType = response.data.data;
      partTypesDispatch({ type: "ADD_ITEM", item: partType });
      const message = response.data.message;
      showSnackbar(message, "success", "green");
    } catch (error) {
      partTypesDispatch({ type: "END_LOAD" });
      const errorMessage = error.response?.data?.message || error.message;
      showSnackbar(errorMessage, "error", "red");
    }
  };

  const editPart = async (id, partObj) => {
    partsDispatch({ type: "START_LOAD" });
    resetSnackbar();
    try {
      const response = await updatePart(id, partObj);
      const part = response.data.data;
      partsDispatch({ type: "EDIT_ITEM", id: id, data: part });
      const message = response.data.message;
      showSnackbar(message, "success", "green");
    } catch (error) {
      partsDispatch({ type: "END_LOAD" });
      const errorMessage = error.response?.data?.message || error.message;
      showSnackbar(errorMessage, "error", "red");
    }
  };

  const editPartType = async (id, partTypeObj) => {
    partTypesDispatch({ type: "START_LOAD" });
    resetSnackbar();
    try {
      const response = await updatePartType(id, partTypeObj);
      const partType = response.data.data;
      partTypesDispatch({ type: "EDIT_ITEM", id: id, data: partType });
      const message = response.data.message;
      showSnackbar(message, "success", "green");
    } catch (error) {
      partTypesDispatch({ type: "END_LOAD" });
      const errorMessage = error.response?.data?.message || error.message;
      showSnackbar(errorMessage, "error", "red");
    }
  };

  const removePart = async (id) => {
    partsDispatch({ type: "START_LOAD" });
    resetSnackbar();
    try {
      const response = await deletePart(id);
      partsDispatch({ type: "REMOVE_ITEM", id: id });
      const message = response.data.message;
      showSnackbar(message, "success", "green");
    } catch (error) {
      partsDispatch({ type: "END_LOAD" });
      const errorMessage = error.response?.data?.message || error.message;
      showSnackbar(errorMessage, "error", "red");
    }
  };

  const removePartType = async (id) => {
    partTypesDispatch({ type: "START_LOAD" });
    resetSnackbar();
    try {
      const response = await deletePartType(id);
      partTypesDispatch({ type: "REMOVE_ITEM", id: id });
      const message = response.data.message;
      showSnackbar(message, "success", "green");
    } catch (error) {
      partTypesDispatch({ type: "END_LOAD" });
      const errorMessage = error.response?.data?.message || error.message;
      showSnackbar(errorMessage, "error", "red");
    }
  };

  const clearPartsData = useCallback(() => {
    partsDispatch({ type: "CLEAR_DATA" });
  }, []);

  const ctxValue = {
    parts: partsState.data,
    partTypes: partTypesState.data,
    partsLoading: partsState.loading,
    partTypesLoading: partTypesState.loading,
    loadParts,
    loadPartTypes,
    searchParts,
    addPart,
    editPart,
    removePart,
    addPartType,
    editPartType,
    removePartType,
    clearPartsData,
  };

  return (
    <PartContext.Provider value={ctxValue}>
      {props.children}
    </PartContext.Provider>
  );
};

export const useParts = () => useContext(PartContext);
