import { useTheme } from "@emotion/react";
import {
  AlternateEmailOutlined,
  BadgeOutlined,
  CheckOutlined,
  ClearOutlined,
  Close,
  HomeOutlined,
  Key,
  LocalPhoneOutlined,
  PersonPinCircleOutlined,
  PublicOutlined,
  Visibility,
} from "@mui/icons-material";
import {
  Autocomplete,
  Button,
  DialogContentText,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useAuth } from "../../context/auth-context";
import { useCompanies } from "../../context/company-context";
import { useCountries } from "../../context/country-context";
import { useModal } from "../../context/modal-context";
import { useRoles } from "../../context/role-context";
import { useSnackbar } from "../../context/snackbar-context";
import { useUsers } from "../../context/user-context";
import useInput from "../../hooks/use-input";
import { getServiceStatuses, getUser } from "../../services/api-service";
import { getTitleForModal, isInRoleTypes } from "../../services/helpers";
import NTSModal from "../UI/NTSModal";
import NTSModalFooter from "../UI/NTSModalFooter";
import NTSMultiSelect from "../UI/NTSMultiSelect";

const UserModal = () => {
  const theme = useTheme();
  const [showPassword, setShowPassword] = useState(false);
  const { showSnackbar } = useSnackbar();
  const [innerLoading, setInnerLoading] = useState(false);
  const [serviceStatuses, setServiceStatuses] = useState([]);
  const [selectedServiceStatuses, setSelectedServiceStatuses] = useState([]);
  const [assignedCountries, setAssignedCountries] = useState([]);
  const { modalState, closeModal } = useModal();
  const [viewPasswordField, setViewPasswordField] = useState(true);
  const { currentUser, selectedCountry } = useAuth();
  const { users, addUser, editUser, removeUser } = useUsers();
  const { roles, rolesLoading, loadRoles } = useRoles();
  const {
    countries,
    loading: countriesLoading,
    loadCountries,
  } = useCountries();
  const {
    companies,
    loading: companiesLoading,
    loadCompanies,
  } = useCompanies();

  const {
    value: roleValue,
    isValid: roleIsValid,
    hasError: roleHasError,
    valueBlurHandler: roleBlurHandler,
    valueResetHandler: roleResetHandler,
    setValueHandler: setRoleHandler,
  } = useInput((value) => value !== "");
  let roleTypeName = "";
  if (roleValue !== "")
    roleTypeName = roles.find((role) => role.id === roleValue)?.role_type.name;
  const {
    value: companyValue,
    isValid: companyIsValid,
    hasError: companyHasError,
    valueBlurHandler: companyBlurHandler,
    valueResetHandler: companyResetHandler,
    setValueHandler: setCompanyHandler,
  } = useInput(
    (value) =>
      value !== "" ||
      (isInRoleTypes(currentUser.role.role_type.name, ["admin"]) &&
        modalState.type === "add" &&
        roleTypeName === "admin")
  );
  const {
    value: firstNameValue,
    isValid: firstNameIsValid,
    hasError: firstNameHasError,
    valueChangeHandler: firstNameChangeHandler,
    valueBlurHandler: firstNameBlurHandler,
    valueResetHandler: firstNameResetHandler,
    setValueHandler: setFirstNameHandler,
  } = useInput((value) => value.trim().length >= 3);
  const {
    value: lastNameValue,
    isValid: lastNameIsValid,
    hasError: lastNameHasError,
    valueChangeHandler: lastNameChangeHandler,
    valueBlurHandler: lastNameBlurHandler,
    valueResetHandler: lastNameResetHandler,
    setValueHandler: setLastNameHandler,
  } = useInput((value) => value.trim().length >= 3);
  const {
    value: emailValue,
    isValid: emailIsValid,
    hasError: emailHasError,
    valueChangeHandler: emailChangeHandler,
    valueBlurHandler: emailBlurHandler,
    valueResetHandler: emailResetHandler,
    setValueHandler: setEmailHandler,
  } = useInput((value) => value.includes("@") && value.trim().length > 3);
  const {
    value: passwordValue,
    isValid: passwordIsValid,
    hasError: passwordHasError,
    valueChangeHandler: passwordChangeHandler,
    valueBlurHandler: passwordBlurHandler,
    valueResetHandler: passwordResetHandler,
  } = useInput((value) => value.trim().length > 7);
  const {
    value: positionValue,
    isValid: positionIsValid,
    hasError: positionHasError,
    valueChangeHandler: positionChangeHandler,
    valueBlurHandler: positionBlurHandler,
    valueResetHandler: positionResetHandler,
    setValueHandler: setPositionHandler,
  } = useInput((value) => value.trim().length >= 3);
  const {
    value: phoneValue,
    hasError: phoneHasError,
    valueChangeHandler: phoneChangeHandler,
    valueResetHandler: phoneResetHandler,
    setValueHandler: setPhoneHandler,
  } = useInput((value) => value.trim().length >= 3);
  const {
    value: addressValue,
    hasError: addressHasError,
    valueChangeHandler: addressChangeHandler,
    valueResetHandler: addressResetHandler,
    setValueHandler: setAddressHandler,
  } = useInput((value) => value.trim().length >= 3);
  const {
    isValid: assignedCountriesIsValid,
    hasError: assignedCountriesError,
    valueBlurHandler: assignedCountriesBlurHandler,
    valueResetHandler: assignedCountriesResetHandler,
  } = useInput(() => assignedCountries.length > 0);

  const resetForm = useCallback(() => {
    firstNameResetHandler();
    lastNameResetHandler();
    roleResetHandler();
    companyResetHandler();
    emailResetHandler();
    passwordResetHandler();
    positionResetHandler();
    phoneResetHandler();
    addressResetHandler();
    setAssignedCountries([]);
    assignedCountriesResetHandler();
    setViewPasswordField(true);
    setServiceStatuses([]);
    setSelectedServiceStatuses([]);
    // eslint-disable-next-line
  }, []);

  const setFields = useCallback(
    (user) => {
      setViewPasswordField(false);
      setRoleHandler(user?.role?.id);
      setCompanyHandler(user?.company?.id);
      setFirstNameHandler(user?.fname);
      setLastNameHandler(user?.lname);
      setEmailHandler(user?.email);
      setPositionHandler(user?.position);
      setPhoneHandler(user?.phone);
      setAddressHandler(user?.address);
      setAssignedCountries(user?.countries ?? []);
      setSelectedServiceStatuses(user?.notifications ?? []);
    },
    // eslint-disable-next-line
    []
  );

  const fetchUser = async () => {
    setInnerLoading(true);
    try {
      const fetchedUser = await getUser(modalState.id);
      const user = fetchedUser.data.data;
      if (user) setFields(user);
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      showSnackbar(errorMessage, "error", "red");
    }
    setInnerLoading(false);
  };

  useEffect(() => {
    resetForm();
    if (!modalState.type || modalState.model !== "user") return;
    if (!modalState.type !== "view") {
      loadRoles();
      loadCompanies();
      loadCountries();
      (async () => {
        setInnerLoading(true);
        try {
          const fetchedServiceStatuses = await getServiceStatuses();
          const _serviceStatuses = fetchedServiceStatuses.data.data;
          if (_serviceStatuses) setServiceStatuses(_serviceStatuses);
        } catch (error) {
          const errorMessage = error.response?.data?.message || error.message;
          showSnackbar(errorMessage, "error", "red");
        }
        setInnerLoading(false);
      })();
    }
    if (modalState.type === "add") return;

    if (users.length > 0) {
      const user = users.find((user) => user.id === modalState.id);
      if (user) setFields(user);
      else fetchUser();
    } else {
      fetchUser();
    }
    // eslint-disable-next-line
  }, [modalState]);

  const togglePasswordHandler = () => {
    setShowPassword((prevState) => !prevState);
  };

  const toggleAllCountriesHandler = () => {
    setAssignedCountries((prevState) =>
      prevState.length > 0 ? [] : countries
    );
  };

  const toggleStatusHandler = (serviceStatus, newValue) => {
    setSelectedServiceStatuses((prev) =>
      newValue
        ? [...prev, serviceStatus]
        : prev.filter((item) => item !== serviceStatus)
    );
  };

  const submitHandler = (event) => {
    event.preventDefault();
    closeModal();
    const userObj = {
      role_id: roleValue,
      company_id: companyValue !== "" ? companyValue : null,
      fname: firstNameValue,
      lname: lastNameValue,
      email: emailValue,
      password: passwordValue.trim().length !== 0 ? passwordValue : null,
      position: positionValue,
      phone: phoneValue.trim().length !== 0 ? phoneValue : null,
      address: addressValue.trim().length !== 0 ? addressValue : null,
      country_ids: isInRoleTypes(currentUser.role.role_type.name, ["admin"])
        ? assignedCountries.map((country) => country.id)
        : [selectedCountry.id],
      notifications: [...selectedServiceStatuses],
    };
    if (modalState.type === "add") {
      addUser(userObj);
    }
    if (modalState.type === "edit") {
      editUser(modalState.id, userObj);
    }
    if (modalState.type === "delete") {
      removeUser(modalState.id);
    }
    resetForm();
  };

  let title = "";
  let buttons = (
    <>
      <Button onClick={closeModal} endIcon={<ClearOutlined />} color="error">
        Cancel
      </Button>
      <Button
        onClick={submitHandler}
        disabled={
          !firstNameIsValid ||
          !lastNameIsValid ||
          !roleIsValid ||
          (!companyIsValid &&
            roleTypeName !== "admin" &&
            modalState.type !== "delete") ||
          !emailIsValid ||
          (!passwordIsValid && modalState.type === "add") ||
          !positionIsValid ||
          (isInRoleTypes(currentUser.role.role_type.name, ["admin"]) &&
            !assignedCountriesIsValid)
        }
        endIcon={<CheckOutlined />}
        color="success"
      >
        Submit
      </Button>
    </>
  );

  if (modalState.open && modalState.type) {
    title = getTitleForModal(modalState.type);
  }
  if (modalState.type === "view") {
    buttons = (
      <Button onClick={closeModal} endIcon={<Close />} color="primary">
        Close
      </Button>
    );
  }

  return (
    modalState.open &&
    modalState.model === "user" && (
      <NTSModal
        title={`${title} USER`}
        loading={rolesLoading || companiesLoading || innerLoading}
        loadingSx={{ marginBottom: 5 }}
        maxWidth="md"
      >
        {modalState.type === "delete" ? (
          <DialogContentText>{`Are you sure that you want to remove user ${firstNameValue} ${lastNameValue}?`}</DialogContentText>
        ) : (
          <Grid container spacing={2}>
            {modalState.id !== currentUser.id &&
              isInRoleTypes(currentUser.role.role_type.name, ["admin"]) && (
                <>
                  <Grid item xs={12} sm={3}>
                    {(companyValue || modalState.type === "add") && (
                      <FormControl fullWidth sx={{ mt: 2 }}>
                        <Autocomplete
                          id="company"
                          options={companies}
                          value={
                            companies.find(
                              (type) => type.id === companyValue
                            ) || null
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              error={companyHasError}
                              label="Company *"
                            />
                          )}
                          getOptionLabel={(option) => option.groupation_name}
                          onChange={(event, newOption) =>
                            setCompanyHandler(newOption?.id ?? null)
                          }
                          onClose={companyBlurHandler}
                          disabled={modalState.type === "view"}
                          required
                          disableClearable
                        />
                      </FormControl>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <FormControl fullWidth sx={{ mt: 2 }}>
                      <Autocomplete
                        id="role"
                        options={roles}
                        value={
                          roles.find((type) => type.id === roleValue) || null
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            error={roleHasError}
                            label="Role *"
                          />
                        )}
                        getOptionLabel={(option) =>
                          option.show_name ?? option.name
                        }
                        onChange={(event, newOption) =>
                          setRoleHandler(newOption?.id ?? null)
                        }
                        onClose={roleBlurHandler}
                        disabled={modalState.type === "view"}
                        required
                        disableClearable
                      />
                    </FormControl>
                  </Grid>
                </>
              )}
            <Grid item xs={12} sm={6}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email"
                type="email"
                name="email"
                disabled={modalState.type === "view"}
                value={emailValue}
                onChange={emailChangeHandler}
                onBlur={emailBlurHandler}
                error={emailHasError}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AlternateEmailOutlined />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="fname"
                label="First name"
                name="fname"
                disabled={modalState.type === "view"}
                value={firstNameValue}
                onChange={firstNameChangeHandler}
                onBlur={firstNameBlurHandler}
                error={firstNameHasError}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <BadgeOutlined />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="lname"
                label="Last name"
                name="lname"
                disabled={modalState.type === "view"}
                value={lastNameValue}
                onChange={lastNameChangeHandler}
                onBlur={lastNameBlurHandler}
                error={lastNameHasError}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <BadgeOutlined />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            {modalState.type !== "view" && (
              <Grid item xs={12} sm={6} sx={{ display: "grid" }}>
                {viewPasswordField ? (
                  <>
                    <TextField
                      margin="normal"
                      required={modalState.type === "add"}
                      fullWidth
                      name="password"
                      label="Password"
                      type={showPassword ? "text" : "password"}
                      id="password"
                      value={passwordValue}
                      onChange={passwordChangeHandler}
                      onBlur={
                        modalState.type === "add" ? passwordBlurHandler : null
                      }
                      error={passwordHasError}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Key />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <IconButton
                            position="end"
                            onClick={togglePasswordHandler}
                          >
                            <Visibility />
                          </IconButton>
                        ),
                      }}
                    />
                    {modalState.type === "edit" && (
                      <Typography
                        variant="subtitle2"
                        sx={{ color: theme.palette.text.secondary }}
                      >
                        *Fill only if you want to change the password
                      </Typography>
                    )}
                  </>
                ) : (
                  <Link
                    underline="hover"
                    variant="body2"
                    href="#"
                    onClick={() => setViewPasswordField(true)}
                    sx={{ alignSelf: "center", justifySelf: "center" }}
                  >
                    Do you want to change the password?
                  </Link>
                )}
              </Grid>
            )}
            {(modalState.id !== currentUser.id ||
              isInRoleTypes(currentUser.role.role_type.name, ["admin"])) && (
              <Grid item xs={12} sm={6}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="position"
                  label="Position"
                  name="position"
                  disabled={modalState.type === "view"}
                  value={positionValue}
                  onChange={positionChangeHandler}
                  onBlur={positionBlurHandler}
                  error={positionHasError}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PersonPinCircleOutlined />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            )}
            <Grid item xs={12} sm={6}>
              <TextField
                margin="normal"
                fullWidth
                id="phone"
                label="Phone"
                name="phone"
                disabled={modalState.type === "view"}
                value={phoneValue}
                onChange={phoneChangeHandler}
                error={phoneHasError}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LocalPhoneOutlined />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={modalState.type === "view" ? 6 : 12}>
              <TextField
                margin="normal"
                fullWidth
                id="address"
                label="Address"
                name="address"
                disabled={modalState.type === "view"}
                value={addressValue}
                onChange={addressChangeHandler}
                error={addressHasError}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <HomeOutlined />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            {modalState.type !== "view" && (
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Typography
                    variant="body1"
                    sx={{
                      color:
                        modalState.type === "view"
                          ? theme.palette.text.disabled
                          : theme.palette.text.secondary,
                      marginBottom: "5px",
                    }}
                  >
                    Get email for service status:
                  </Typography>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    {serviceStatuses.map((serviceStatus, index) => (
                      <FormControlLabel
                        key={index}
                        control={
                          <Switch
                            checked={selectedServiceStatuses.includes(
                              serviceStatus
                            )}
                            disabled={modalState.type === "view"}
                            onChange={(e, newValue) =>
                              toggleStatusHandler(serviceStatus, newValue)
                            }
                          />
                        }
                        label={serviceStatus}
                      />
                    ))}
                  </Stack>
                </FormControl>
              </Grid>
            )}
            {isInRoleTypes(currentUser.role.role_type.name, ["admin"]) &&
              modalState.id !== currentUser.id && (
                <Grid item xs={12}>
                  <NTSMultiSelect
                    color="success"
                    loading={countriesLoading}
                    assigns={assignedCountries}
                    onChange={setAssignedCountries}
                    onBlur={assignedCountriesBlurHandler}
                    required
                    error={assignedCountriesError}
                    values={countries}
                    icon={<PublicOutlined />}
                    title="Countries"
                    disabled={modalState.type === "view"}
                  />
                  {modalState.type !== "view" && (
                    <Link
                      underline="hover"
                      variant="body2"
                      href="#"
                      onClick={toggleAllCountriesHandler}
                    >
                      {assignedCountries.length > 0
                        ? "Remove all countries from user"
                        : "Assign all countries to user"}
                    </Link>
                  )}
                </Grid>
              )}
          </Grid>
        )}
        <NTSModalFooter
          sx={{
            display: "flex",
            justifyContent: "end",
            marginY: 2,
          }}
        >
          {buttons}
        </NTSModalFooter>
      </NTSModal>
    )
  );
};

export default UserModal;
