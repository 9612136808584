import {
  BookOnlineOutlined,
  CheckOutlined,
  ClearOutlined,
  Close,
  ConfirmationNumberOutlined,
  GroupWorkOutlined,
} from "@mui/icons-material";
import {
  Button,
  DialogContentText,
  Grid,
  InputAdornment,
  TextField,
} from "@mui/material";
import { useEffect } from "react";
import { useCallback } from "react";
import { useModal } from "../../context/modal-context";
import { useSpareParts } from "../../context/spare-part-context";
import useInput from "../../hooks/use-input";
import { getTitleForModal } from "../../services/helpers";
import NTSModal from "../UI/NTSModal";
import NTSModalFooter from "../UI/NTSModalFooter";

const SparePartModal = () => {
  const { modalState, closeModal } = useModal();
  const { spareParts, addSparePart, editSparePart, removeSparePart } =
    useSpareParts();

  const {
    value: nameValue,
    isValid: nameIsValid,
    hasError: nameHasError,
    valueChangeHandler: nameChangeHandler,
    valueBlurHandler: nameBlurHandler,
    valueResetHandler: nameResetHandler,
    setValueHandler: setNameHandler,
  } = useInput((value) => value.trim().length > 3);
  const {
    value: articleNumberValue,
    isValid: articleNumberIsValid,
    hasError: articleNumberHasError,
    valueChangeHandler: articleNumberChangeHandler,
    valueBlurHandler: articleNumberBlurHandler,
    valueResetHandler: articleNumberResetHandler,
    setValueHandler: setArticleNumberHandler,
  } = useInput((value) => value.trim().length > 3);
  const {
    value: partNumberValue,
    isValid: partNumberIsValid,
    hasError: partNumberHasError,
    valueChangeHandler: partNumberChangeHandler,
    valueBlurHandler: partNumberBlurHandler,
    valueResetHandler: partNumberResetHandler,
    setValueHandler: setPartNumberHandler,
  } = useInput((value) => value.trim().length > 3);

  const resetForm = useCallback(() => {
    nameResetHandler();
    articleNumberResetHandler();
    partNumberResetHandler();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    resetForm();
    if (
      !modalState.type ||
      modalState.type === "add" ||
      modalState.model !== "sparePart"
    )
      return;

    const sparePart = spareParts.find((part) => part.id === modalState.id);
    setNameHandler(sparePart?.name);
    setArticleNumberHandler(sparePart?.article_number);
    setPartNumberHandler(sparePart?.part_number);
    // eslint-disable-next-line
  }, [modalState]);

  const submitHandler = (event) => {
    event.preventDefault();
    closeModal();
    const sparePartObj = {
      name: nameValue,
      article_number: articleNumberValue,
      part_number: partNumberValue,
    };
    if (modalState.type === "add") {
      addSparePart(sparePartObj);
    }
    if (modalState.type === "edit") {
      editSparePart(modalState.id, sparePartObj);
    }
    if (modalState.type === "delete") {
      removeSparePart(modalState.id);
    }
    resetForm();
  };

  let title = "";
  let buttons = (
    <>
      <Button onClick={closeModal} endIcon={<ClearOutlined />} color="error">
        Cancel
      </Button>
      <Button
        onClick={submitHandler}
        disabled={!nameIsValid || !articleNumberIsValid || !partNumberIsValid}
        endIcon={<CheckOutlined />}
        color="success"
      >
        Submit
      </Button>
    </>
  );

  if (modalState.open && modalState.type) {
    title = getTitleForModal(modalState.type);
  }
  if (modalState.type === "view") {
    buttons = (
      <Button onClick={closeModal} endIcon={<Close />} color="primary">
        Close
      </Button>
    );
  }

  return (
    modalState.open &&
    modalState.model === "sparePart" && (
      <NTSModal title={`${title} SPARE PART`} maxWidth="md">
        {modalState.type === "delete" ? (
          <DialogContentText>{`Are you sure that you want to remove spare part ${nameValue}?`}</DialogContentText>
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="name"
                label="Part type name"
                name="name"
                disabled={modalState.type === "view"}
                value={nameValue}
                onChange={nameChangeHandler}
                onBlur={nameBlurHandler}
                error={nameHasError}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <GroupWorkOutlined />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="article_number"
                label="Article Number"
                name="article_number"
                disabled={modalState.type === "view"}
                value={articleNumberValue}
                onChange={articleNumberChangeHandler}
                onBlur={articleNumberBlurHandler}
                error={articleNumberHasError}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <ConfirmationNumberOutlined />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="part_number"
                label="Part Number"
                name="part_number"
                disabled={modalState.type === "view"}
                value={partNumberValue}
                onChange={partNumberChangeHandler}
                onBlur={partNumberBlurHandler}
                error={partNumberHasError}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <BookOnlineOutlined />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        )}
        <NTSModalFooter
          sx={{
            display: "flex",
            justifyContent: "end",
            marginY: 2,
          }}
        >
          {buttons}
        </NTSModalFooter>
      </NTSModal>
    )
  );
};

export default SparePartModal;
