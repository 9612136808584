import { useDevices } from "../../context/device-context";
import { useModal } from "../../context/modal-context";
import NTSTable from "../UI/NTSTable";

const AllDevices = () => {
  const { devices, devicesLoading } = useDevices();
  const { openModal } = useModal();

  const cols = [
    { field: "name", headerName: "Name", type: "string", width: 200 },
    {
      field: "type",
      headerName: "Type",
      type: "string",
      width: 150,
      valueGetter: (params) => {
        return `${params.row.device_type.name}`;
      },
    },
  ];

  return (
    <NTSTable
      onEdit={(id) => openModal("edit", "device", id)}
      onRemove={(id) => openModal("delete", "device", id)}
      data={devices}
      cols={cols}
      loading={devicesLoading}
      actions={true}
    />
  );
};

export default AllDevices;
