import { Grid, Typography } from "@mui/material";
import { useCabinets } from "../../context/cabinet-context";
import LinearLoad from "../UI/LinearLoad";
import CabinetCard from "./CabinetCard";

const AllCabinets = () => {
  const { cabinets, loading } = useCabinets();

  return (
    <>
      {loading && <LinearLoad />}
      <Grid container spacing={2}>
        {!loading && cabinets.length === 0 ? (
          <Grid item xs={12}>
            <Typography variant="h6">
              There are no cabinets at the moment.
            </Typography>
          </Grid>
        ) : (
          cabinets.length > 0 &&
          cabinets.map((cabinet, index) => (
            <CabinetCard key={index} cabinet={cabinet} />
          ))
        )}
      </Grid>
    </>
  );
};

export default AllCabinets;
