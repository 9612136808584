import { useModal } from "../../context/modal-context";
import { useRoles } from "../../context/role-context";
import NTSTable from "../UI/NTSTable";

const AllRoles = () => {
  const { roles, rolesLoading } = useRoles();
  const { openModal } = useModal();

  const cols = [
    { field: "show_name", headerName: "Name", type: "string", width: 200 },
    {
      field: "Type",
      headerName: "Type",
      type: "string",
      width: 170,
      valueGetter: (params) => {
        return params.row.role_type.show_name;
      },
    },
  ];

  return (
    <NTSTable
      onView={(id) => openModal("view", "role", id)}
      onEdit={(id) => openModal("edit", "role", id)}
      onRemove={(id) => openModal("delete", "role", id)}
      data={roles}
      cols={cols}
      loading={rolesLoading}
      actions={true}
    />
  );
};

export default AllRoles;
