import { AddCircleOutline, PrintOutlined } from "@mui/icons-material";
import {
  Badge,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Paper,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { useState, useRef } from "react";
import { useModal } from "../../context/modal-context";
import { getColorForWarehouseBadge } from "../../services/helpers";
import { useReactToPrint } from "react-to-print";
import PrintWarehouse from "./PrintWarehouse";

const WarehouseBox = (props) => {
  const { warehouse } = props;
  const [value, setValue] = useState("1");
  const { openModal } = useModal();
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `spare-parts-in-${warehouse.name}-${warehouse.city}-${warehouse.company_groupation_name}`,
  });

  let listContent = <></>;
  if (value === "1") {
    listContent =
      warehouse.spare_parts.length > 0 ? (
        warehouse.spare_parts.map((sparePart, index) => (
          <ListItem
            key={index}
            secondaryAction={
              <Badge
                badgeContent={sparePart.quantity}
                sx={{ marginRight: 1 }}
                color={getColorForWarehouseBadge(
                  sparePart.quantity,
                  warehouse.warehouse_type
                )}
              />
            }
          >
            {sparePart.name}
          </ListItem>
        ))
      ) : (
        <ListItem>No results</ListItem>
      );
  }
  if (value === "2") {
    listContent =
      warehouse.users.length > 0 ? (
        warehouse.users.map((user, index) => (
          <ListItem key={index}>
            {user.fname} {user.lname}
          </ListItem>
        ))
      ) : (
        <ListItem>No results</ListItem>
      );
  }
  if (value === "3") {
    listContent = (
      <>
        <ListItemButton
          onClick={() => {
            handlePrint();
          }}
        >
          <div style={{ display: "none" }}>
            <PrintWarehouse warehouse={warehouse} ref={componentRef} />
          </div>
          <ListItemIcon>
            <PrintOutlined color="info" />
          </ListItemIcon>
          <ListItemText primary="Print" />
        </ListItemButton>
        <ListItemButton
          onClick={() => openModal("add", "warehouse", warehouse.id)}
        >
          <ListItemIcon>
            <AddCircleOutline color="info" />
          </ListItemIcon>
          <ListItemText primary="Add part to warehouse" />
        </ListItemButton>
      </>
    );
  }

  return (
    <Grid item xs={12} md={6}>
      <Paper sx={{ height: "300px", overflow: "auto" }}>
        <Tabs
          value={value}
          onChange={(e, value) => setValue(value)}
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab label="Spare Parts" value="1" />
          <Tab label="Users" value="2" />
          <Tab label="Manage" value="3" />
        </Tabs>
        <List
          dense
          sx={{ mt: 1 }}
          subheader={
            <ListSubheader component="div" disableSticky>
              <Typography variant="body1">
                {warehouse.name} - {warehouse.city}
              </Typography>
              <Typography variant="body2">
                Type: {warehouse.warehouse_type}
              </Typography>
            </ListSubheader>
          }
        >
          <Divider sx={{ mb: 1, mt: 1 }} />
          {listContent}
        </List>
      </Paper>
    </Grid>
  );
};

export default WarehouseBox;
