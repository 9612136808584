import LinearLoad from "../UI/LinearLoad";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useModal } from "../../context/modal-context";

const NTSModal = (props) => {
  const { modalState, closeModal } = useModal();

  return (
    <Dialog
      fullWidth={true}
      open={modalState.open}
      onClose={closeModal}
      maxWidth={props.maxWidth}
    >
      {props.title && <DialogTitle>{props.title}</DialogTitle>}
      {props.customHeader && props.customHeader}

      {props.loading ? (
        <LinearLoad sx={props.loadingSx} />
      ) : (
        <DialogContent sx={{ paddingBottom: 0 }}>
          {props.children}
        </DialogContent>
      )}

      {props.actions && (
        <DialogActions sx={{ paddingTop: "10px" }}>
          {props.actions}
        </DialogActions>
      )}
    </Dialog>
  );
};

export default NTSModal;
