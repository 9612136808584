import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";


const MainIn = styled(Box)(({ theme, open }) => ({
    flexGrow: 1,
    padding: 1,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 0,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: `${240}px`,
    }),
  })
);

const MainHeader = styled(Box)(({ theme }) => ({
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Main = (props) => {
  const { open, children } = props;

  return (
    <MainIn open={open}>
      <MainHeader />
      {children}
    </MainIn>
  );
};

export default Main;
