import {
  CheckOutlined,
  ClearOutlined,
  Close,
  TripOriginOutlined,
} from "@mui/icons-material";
import {
  Autocomplete,
  Button,
  DialogContentText,
  FormControl,
  Grid,
  InputAdornment,
  TextField,
} from "@mui/material";
import { useCallback, useEffect } from "react";
import { useModal } from "../../context/modal-context";
import { useParts } from "../../context/part-context";
import useInput from "../../hooks/use-input";
import { getTitleForModal } from "../../services/helpers";
import NTSModal from "../UI/NTSModal";
import NTSModalFooter from "../UI/NTSModalFooter";

const PartModal = () => {
  const { modalState, closeModal } = useModal();
  const {
    parts,
    partTypes,
    partTypesLoading,
    loadPartTypes,
    addPart,
    editPart,
    removePart,
  } = useParts();

  const {
    value: partTypeValue,
    isValid: partTypeIsValid,
    hasError: partTypeHasError,
    valueBlurHandler: partTypeBlurHandler,
    valueResetHandler: partTypeResetHandler,
    setValueHandler: setPartTypeHandler,
  } = useInput((value) => value !== "");
  const {
    value: partNameValue,
    isValid: partNameIsValid,
    hasError: partNameHasError,
    valueChangeHandler: partNameChangeHandler,
    valueBlurHandler: partNameBlurHandler,
    valueResetHandler: partNameResetHandler,
    setValueHandler: setPartNameHandler,
  } = useInput((value) => value.trim().length > 3);

  const resetForm = useCallback(() => {
    partTypeResetHandler();
    partNameResetHandler();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    resetForm();
    if (!modalState.type || modalState.model !== "part") return;
    loadPartTypes();
    if (modalState.type === "add") return;

    const part = parts.find((part) => part.id === modalState.id);
    setPartNameHandler(part?.name);
    setPartTypeHandler(part?.part_type.id);
    // eslint-disable-next-line
  }, [modalState]);

  const submitHandler = (event) => {
    event.preventDefault();
    closeModal();
    const partObj = {
      name: partNameValue,
      part_type_id: partTypeValue,
    };
    if (modalState.type === "add") {
      addPart(partObj);
    }
    if (modalState.type === "edit") {
      editPart(modalState.id, partObj);
    }
    if (modalState.type === "delete") {
      removePart(modalState.id);
    }
    resetForm();
  };

  let title = "";
  let buttons = (
    <>
      <Button onClick={closeModal} endIcon={<ClearOutlined />} color="error">
        Cancel
      </Button>
      <Button
        onClick={submitHandler}
        disabled={!partNameIsValid || !partTypeIsValid}
        endIcon={<CheckOutlined />}
        color="success"
      >
        Submit
      </Button>
    </>
  );

  if (modalState.open && modalState.type) {
    title = getTitleForModal(modalState.type);
  }
  if (modalState.type === "view") {
    buttons = (
      <Button onClick={closeModal} endIcon={<Close />} color="primary">
        Close
      </Button>
    );
  }

  return (
    modalState.open &&
    modalState.model === "part" && (
      <NTSModal
        title={`${title} PART`}
        maxWidth="sm"
        loading={partTypesLoading}
        loadingSx={{ marginBottom: 5 }}
      >
        {modalState.type === "delete" ? (
          <DialogContentText>{`Are you sure that you want to remove part ${partNameValue}?`}</DialogContentText>
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth sx={{ mt: 2 }}>
                <Autocomplete
                  id="part_type"
                  options={partTypes}
                  value={
                    partTypes.find((part) => part.id === partTypeValue) || null
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={partTypeHasError}
                      label="Part Type *"
                    />
                  )}
                  getOptionLabel={(option) => option.show_name ?? option.name}
                  onChange={(event, newOption) =>
                    setPartTypeHandler(newOption?.id ?? null)
                  }
                  onClose={partTypeBlurHandler}
                  disabled={modalState.type === "view"}
                  required
                  disableClearable
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="name"
                label="Part name"
                name="name"
                disabled={modalState.type === "view"}
                value={partNameValue}
                onChange={partNameChangeHandler}
                onBlur={partNameBlurHandler}
                error={partNameHasError}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <TripOriginOutlined />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        )}
        <NTSModalFooter
          sx={{
            display: "flex",
            justifyContent: "end",
            marginY: 2,
          }}
        >
          {buttons}
        </NTSModalFooter>
      </NTSModal>
    )
  );
};

export default PartModal;
