import { CloudUpload } from "@mui/icons-material";
import { Button, Typography, useTheme } from "@mui/material";

const NTSUploadButton = (props) => {
  const theme = useTheme();

  const {
    uploaded,
    onUpload,
    buttonName,
    modalType,
    description,
    required,
    sx,
  } = props;

  return (
    <>
      <Button
        variant={uploaded ? "contained" : "outlined"}
        color={uploaded || !required ? "primary" : "error"}
        component="label"
        onChange={onUpload}
        startIcon={<CloudUpload />}
        sx={{ width: "100%", height: "56px", marginTop: 1, ...sx }}
      >
        {buttonName}
        <input hidden accept="image/*" type="file" />
      </Button>
      {modalType === "edit" && description && (
        <Typography
          variant="subtitle2"
          sx={{ color: theme.palette.text.secondary }}
        >
          {description}
        </Typography>
      )}
    </>
  );
};

export default NTSUploadButton;
