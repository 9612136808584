import {
  Box,
  Badge,
  IconButton,
  Menu,
  Typography,
  Divider,
  MenuItem,
  ListItemIcon,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import NotificationsIcon from "@mui/icons-material/NotificationsNoneOutlined";
import HomeRepairServiceIcon from "@mui/icons-material/HomeRepairServiceOutlined";
import { useServices } from "../../../context/service-context";
import { hasAbility } from "../../../services/helpers";
import { useAuth } from "../../../context/auth-context";

const CustomMenuItemWithNumber = (props) => {
  const { icon, number, text, ...rest } = props;
  return (
    <MenuItem {...rest}>
      <ListItemIcon>{icon}</ListItemIcon>
      <Box sx={{ display: "flex", alignItems: "flex-end" }}>
        <Typography variant="body1" sx={{ fontWeight: "bold", mr: 0.8 }}>
          {number}
        </Typography>
        <Typography variant="body1">{text}</Typography>
      </Box>
    </MenuItem>
  );
};

const NotificationMenu = () => {
  let navigate = useNavigate();
  const { currentUser } = useAuth();
  const { activeServicesCount, fetchActiveServicesCount } = useServices();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const totalCount = useMemo(() => {
    return activeServicesCount;
  }, [activeServicesCount]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    fetchActiveServicesCount();
    // eslint-disable-next-line
  }, []);

  let notificationsMenu = [];
  if (hasAbility("can_manage_service", currentUser.role.abilities)) {
    notificationsMenu = [
      ...notificationsMenu,
      {
        icon: <HomeRepairServiceIcon fontSize="medium" color="info" />,
        number: activeServicesCount,
        text: `Active Service${activeServicesCount === 1 ? "" : "s"}`,
        onClick: () => navigate("/service-manager"),
      },
    ];
  }

  if (notificationsMenu.length < 1) return;

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <IconButton onClick={handleClick}>
          <Badge badgeContent={totalCount} color="error">
            <NotificationsIcon fontSize="large" sx={{ color: "white" }} />
          </Badge>
        </IconButton>
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 4,
          sx: {
            overflow: "visible",
            mt: 1,
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <Box sx={{ p: 1, px: 2 }}>
          <Typography sx={{ fontWeight: "medium" }}>Notifications</Typography>
        </Box>

        <Divider sx={{ mb: 1 }} />

        {notificationsMenu.map((item, index) => (
          <CustomMenuItemWithNumber
            key={index}
            icon={item.icon}
            number={item.number}
            text={item.text}
            onClick={item.onClick}
          />
        ))}
      </Menu>
    </>
  );
};

export default NotificationMenu;
