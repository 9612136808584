import { Alert, Slide, Snackbar, Typography } from "@mui/material";
import { useSnackbar } from "../../context/snackbar-context";

const NTSSnackBar = () => {
  const { snackbarState, resetSnackbar } = useSnackbar();

  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      open={snackbarState.open}
      autoHideDuration={5000}
      onClose={resetSnackbar}
      TransitionComponent={Slide}
      key={snackbarState.message}
    >
      <Alert onClose={resetSnackbar} severity={snackbarState.severity}>
        <Typography
          sx={{
            padding: 0,
            color: snackbarState.color,
            fontWeight: "600",
            fontSize: "1em",
          }}
        >
          {snackbarState.message}
        </Typography>
      </Alert>
    </Snackbar>
  );
};

export default NTSSnackBar;
