import { Refresh } from "@mui/icons-material";
import { Container } from "@mui/material";
import { useMemo, useRef, useEffect } from "react";
import CRUDHeader from "../components/UI/CRUDHeader";
import AllUsers from "../components/User/AllUsers";
import UsersFilter from "../components/User/UsersFilter";
import { useModal } from "../context/modal-context";
import { useUsers } from "../context/user-context";

const Users = () => {
  const { clearUsersData, users } = useUsers();
  const { openModal } = useModal();
  const usersFilterRef = useRef();

  useEffect(() => {
    clearUsersData();
    // eslint-disable-next-line
  }, []);

  const onCustom = useMemo(() => {
    return [
      {
        label: "Reset Filters",
        color: "info",
        onClick: () => usersFilterRef.current.resetFilter(),
        icon: <Refresh />,
        sx: { width: 120 },
      },
    ];
  }, []);

  // The user modal is in ProfileMenu (duplicating modals when on users screen)
  return (
    <Container maxWidth="lg" sx={{ paddingBottom: "16px" }}>
      <CRUDHeader
        title="Users"
        onAddNew={() => openModal("add", "user")}
        onCustom={onCustom}
      />
      <UsersFilter ref={usersFilterRef} />
      {users.length > 0 && <AllUsers />}
    </Container>
  );
};

export default Users;
