import { Box, Divider, Paper, Popper, Stack, styled } from "@mui/material";
import React, { useEffect, useRef, useCallback, useState } from "react";

const CustomCell = styled(Box)(({ theme, justify }) => ({
  alignItems: "center",
  justifyContent: justify ?? "start",
  lineHeight: "24px",
  width: "100%",
  height: "100%",
  position: "relative",
  display: "flex",
  "& .MuiRating-root": {
    marginRight: 1,
  },
  "& .cellValue": {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}));

function isOverflown(element) {
  return (
    element.scrollHeight > element.clientHeight ||
    element.scrollWidth > element.clientWidth
  );
}

const CellExpand = (props) => {
  const {
    width,
    value,
    customCellValue,
    popperWidth,
    justify,
    alwaysShow,
    asChat,
  } = props;
  const wrapper = useRef(null);
  const cellDiv = useRef(null);
  const cellValue = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const [showFullCell, setShowFullCell] = useState(false);
  const [showPopper, setShowPopper] = useState(false);

  const showCell = useCallback(() => {
    setShowFullCell(true);
  }, []);
  const hideCell = useCallback(() => {
    setShowFullCell(false);
  }, []);

  useEffect(() => {
    if (cellDiv.current) {
      setAnchorEl(cellDiv.current);
    }
  }, []);
  useEffect(() => {
    if (cellValue && cellValue.current) {
      const isCurrentlyOverflown = alwaysShow || isOverflown(cellValue.current);
      setShowPopper(isCurrentlyOverflown);
    }
    // eslint-disable-next-line
  }, [width]);

  return (
    <CustomCell
      ref={wrapper}
      justify={justify}
      onMouseEnter={showCell}
      onMouseLeave={hideCell}
    >
      <div
        ref={cellDiv}
        style={{
          height: 1,
          width,
          display: "block",
          position: "absolute",
          top: 0,
        }}
      />
      <div ref={cellValue} className="cellValue">
        {customCellValue ?? value}
      </div>
      {showPopper && (
        <Popper
          id={value}
          open={showFullCell && anchorEl != null}
          anchorEl={anchorEl}
          style={{
            width: popperWidth ?? width,
            maxHeight: "420px",
            overflow: "auto",
            zIndex: 1500,
          }}
        >
          <Paper
            elevation={1}
            style={{
              minHeight: wrapper.current?.offsetHeight - 2,
            }}
          >
            <div style={{ padding: 2, fontSize: "15px" }}>
              {!asChat &&
                value.split(", ").map((item, index) => (
                  <React.Fragment key={index}>
                    <p style={{ margin: "6px" }}>{item}</p>
                    {index < value.split(", ").length - 1 && <Divider />}
                  </React.Fragment>
                ))}
              {asChat &&
                value.split(",, ").map((item, index) => (
                  <React.Fragment key={index}>
                    <Stack direction="column" sx={{ margin: "6px" }}>
                      {item.split(" | ").map((child, i) => (
                        <p
                          key={i}
                          style={{
                            textAlign: "center",
                            margin: 0,
                            fontSize: i === 1 ? "14px" : null,
                            fontWeight:
                              i === item.split(" | ").length - 1 ? 600 : null,
                          }}
                        >
                          {child}
                        </p>
                      ))}
                    </Stack>
                    {index < value.split(",, ").length - 1 && <Divider />}
                  </React.Fragment>
                ))}
            </div>
          </Paper>
        </Popper>
      )}
    </CustomCell>
  );
};

export default function NTSExpandableTableCell(params) {
  return (
    <CellExpand
      alwaysShow={params.alwaysShow}
      asChat={params.asChat}
      justify={params.justify}
      customCellValue={params.customCellValue}
      popperWidth={params.popperWidth}
      value={params.value ? params.value.toString() : ""}
      width={params.colDef.width}
    />
  );
}
