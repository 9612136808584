import {
  Badge,
  createTheme,
  ThemeProvider,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { forwardRef } from "react";
import { getColorForWarehouseBadge } from "../../services/helpers";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const PrintWarehouse = forwardRef((props, ref) => {
  const { warehouse } = props;
  const theme = createTheme();

  const headersSx = { fontWeight: "500", marginLeft: "20px", color: "black" };
  const tableRowCellsSx = { fontWeight: "600", fontSize: "16px" };
  const tableBodyCellsSx = { paddingLeft: "60px", fontSize: "15px" };

  return (
    <div ref={ref}>
      <ThemeProvider theme={theme}>
        <Typography
          variant="h6"
          sx={{
            ...headersSx,
            marginTop: "20px",
          }}
        >
          <strong>Warehouse: </strong>
          {warehouse.name} - {warehouse.city} (
          {warehouse.company_groupation_name})
        </Typography>
        <Typography variant="h6" sx={{ ...headersSx }}>
          <strong>Type: </strong>
          {warehouse.warehouse_type}
        </Typography>
        <TableContainer sx={{ marginTop: "20px" }}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center" sx={{ ...tableRowCellsSx }}>
                  Spare Part
                </TableCell>
                <TableCell align="center" sx={{ ...tableRowCellsSx }}>
                  Quantity
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {warehouse.spare_parts.map((sparePart, index) => (
                <StyledTableRow key={index}>
                  <TableCell align="left" sx={{ ...tableBodyCellsSx }}>
                    {sparePart.name}
                  </TableCell>
                  <TableCell align="center">
                    <Badge
                      badgeContent={sparePart.quantity}
                      color={getColorForWarehouseBadge(
                        sparePart.quantity,
                        warehouse.warehouse_type
                      )}
                    />
                  </TableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </ThemeProvider>
    </div>
  );
});

export default PrintWarehouse;
