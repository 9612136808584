import { useModal } from "../../context/modal-context";
import NTSTable from "../UI/NTSTable";

const AssignsResults = (props) => {
  const { openModal } = useModal();
  const { tableState, loading } = props;

  let cols = [];
  if (tableState.type === "User") {
    cols = [
      {
        field: "type",
        headerName: "Type",
        type: "string",
        width: 150,
        valueGetter: (params) => {
          return `${params.row.location_type.show_name}`;
        },
      },
      {
        field: "name",
        headerName: "Name",
        type: "string",
        width: 170,
      },
      {
        field: "address",
        headerName: "Address",
        type: "string",
        width: 200,
      },
      {
        field: "city",
        headerName: "City",
        type: "string",
        width: 150,
      },
      {
        field: "zip",
        headerName: "Zip",
        type: "string",
        width: 150,
        hide: true,
      },
      {
        field: "phone",
        headerName: "Phone",
        type: "string",
        width: 150,
        hide: true,
      },
      {
        field: "map_location",
        headerName: "Map Location",
        type: "string",
        width: 150,
        hide: true,
      },
    ];
  }
  if (tableState.type === "Location") {
    cols = [
      {
        field: "name",
        headerName: "Name",
        type: "string",
        width: 170,
        valueGetter: (params) => {
          return `${params.row.fname} ${params.row.lname}`;
        },
      },
      {
        field: "role",
        headerName: "Role",
        type: "string",
        width: 150,
        valueGetter: (params) => {
          return `${params.row.role.show_name}`;
        },
      },
      {
        field: "position",
        headerName: "Position",
        type: "string",
        width: 180,
      },
      {
        field: "email",
        headerName: "Email",
        type: "string",
        width: 220,
      },
      {
        field: "address",
        headerName: "Address",
        type: "string",
        width: 200,
        hide: true,
      },
      {
        field: "phone",
        headerName: "Phone",
        type: "string",
        width: 150,
        hide: true,
      },
    ];
  }

  return (
    <NTSTable
      onRemove={(id) => openModal("delete", "assigns", id)}
      data={tableState.data}
      cols={cols}
      loading={loading}
    />
  );
};

export default AssignsResults;
