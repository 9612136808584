import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";

import SignIn from "./features/SignIn";

import NTSSnackBar from "./components/UI/NTSSnackBar";
import MainRoutes from "./components/Routes/MainRoutes";
import Layout from "./components/Layout/Layout";

import { CssBaseline } from "@mui/material";

import { Suspense } from "react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import LinearLoad from "./components/UI/LinearLoad";
import { useAuth } from "./context/auth-context";
import { ModalContextProvider } from "./context/modal-context";
import { CountryContextProvider } from "./context/country-context";
import { CompanyContextProvider } from "./context/company-context";
import { RoleContextProvider } from "./context/role-context";
import { UserContextProvider } from "./context/user-context";
import { LocationContextProvider } from "./context/location-context";
import { GroupContextProvider } from "./context/group-context";
import { CabinetContextProvider } from "./context/cabinet-context";
import { ManufacturerContextProvider } from "./context/manufacturer-context";
import { PartContextProvider } from "./context/part-context";
import { DeviceContextProvider } from "./context/device-context";
import { MachineContextProvider } from "./context/machine-context";
import { SparePartContextProvider } from "./context/spare-part-context";
import { ServiceContextProvider } from "./context/service-context";

dayjs.extend(relativeTime);

function App() {
  const { isLoggedIn } = useAuth();

  return (
    <>
      <CssBaseline />
      <Router>
        {!isLoggedIn ? (
          <Suspense fallback={<LinearLoad />}>
            <Routes>
              <Route path="/" element={<SignIn />} />
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </Suspense>
        ) : (
          <ModalContextProvider>
            <CountryContextProvider>
              <CompanyContextProvider>
                <RoleContextProvider>
                  <UserContextProvider>
                    <LocationContextProvider>
                      <GroupContextProvider>
                        <ManufacturerContextProvider>
                          <CabinetContextProvider>
                            <PartContextProvider>
                              <DeviceContextProvider>
                                <MachineContextProvider>
                                  <SparePartContextProvider>
                                    <ServiceContextProvider>
                                      <Layout>
                                        <MainRoutes />
                                      </Layout>
                                    </ServiceContextProvider>
                                  </SparePartContextProvider>
                                </MachineContextProvider>
                              </DeviceContextProvider>
                            </PartContextProvider>
                          </CabinetContextProvider>
                        </ManufacturerContextProvider>
                      </GroupContextProvider>
                    </LocationContextProvider>
                  </UserContextProvider>
                </RoleContextProvider>
              </CompanyContextProvider>
            </CountryContextProvider>
          </ModalContextProvider>
        )}
      </Router>
      <NTSSnackBar />
    </>
  );
}

export default App;
