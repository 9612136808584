import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  List,
  ListItem,
  Paper,
  styled,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useModal } from "../../context/modal-context";
import NTSModal from "../UI/NTSModal";
import NTSModalFooter from "../UI/NTSModalFooter";

const FlexListItem = styled(ListItem)(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  flexDirection: "row",
  justifyContent: "space-between",
  paddingY: 0,
}));

const DetailsTypography = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
}));

const ChangedAndReturnedPartsModal = () => {
  const theme = useTheme();
  const { modalState, closeModal } = useModal();
  const [changedParts, setChangedParts] = useState([]);
  const [returnedParts, setReturnedParts] = useState([]);

  useEffect(() => {
    if (!modalState.type || modalState.model !== "parts-details") return;

    let _changedParts = [];
    let _returnedParts = [];
    if (
      modalState.additional.changed_parts &&
      modalState.additional.changed_parts.length > 0
    ) {
      _changedParts = modalState?.additional?.changed_parts?.map((details) => {
        const _details = details?.show_name?.split(" | ");
        return {
          partName: _details[1],
          quantity: _details[2],
        };
      });
    }
    if (
      modalState.additional.returned_parts &&
      modalState.additional.returned_parts.length > 0
    ) {
      _returnedParts = modalState?.additional?.returned_parts?.map(
        (details) => {
          const _details = details?.show_name?.split(" | ");
          return {
            partName: _details[1],
            quantity: _details[2],
          };
        }
      );
    }

    setChangedParts([..._changedParts]);
    setReturnedParts([..._returnedParts]);
    // eslint-disable-next-line
  }, [modalState]);

  return (
    modalState.open &&
    modalState.model === "parts-details" && (
      <NTSModal title="Changed & Returned Parts" maxWidth="md">
        {changedParts?.length > 0 && (
          <Paper
            sx={{
              width: "100%",
              maxHeight: "300px",
              overflow: "auto",
            }}
          >
            <Box
              sx={{
                height: "3px",
                backgroundColor: theme.palette.error.main,
              }}
            />
            <List>
              <ListItem sx={{ paddingY: 0 }}>
                <Typography variant="h6">Changed Parts</Typography>
              </ListItem>
              <Divider />
              <FlexListItem>
                <DetailsTypography variant="body1">Part</DetailsTypography>
                <DetailsTypography variant="body1">Quantity</DetailsTypography>
              </FlexListItem>
              <Divider />
              {changedParts.map((part, index) => (
                <React.Fragment key={index}>
                  <FlexListItem>
                    <Typography variant="body1">{part.partName}</Typography>
                    <Typography variant="body1" fontWeight="600">
                      x{part.quantity}
                    </Typography>
                  </FlexListItem>
                  {index < changedParts.length - 1 && <Divider />}
                </React.Fragment>
              ))}
            </List>
          </Paper>
        )}
        {returnedParts?.length > 0 && (
          <Paper
            sx={{
              width: "100%",
              maxHeight: "300px",
              overflow: "auto",
              marginTop: changedParts.length > 0 ? "20px" : null,
            }}
          >
            <Box
              sx={{
                height: "3px",
                backgroundColor: theme.palette.warning.light,
              }}
            />
            <List>
              <ListItem sx={{ paddingY: 0 }}>
                <Typography variant="h6">Returned Parts</Typography>
              </ListItem>
              <Divider />
              <FlexListItem>
                <DetailsTypography variant="body1">Part</DetailsTypography>
                <DetailsTypography variant="body1">Quantity</DetailsTypography>
              </FlexListItem>
              <Divider />
              {returnedParts.map((part, index) => (
                <React.Fragment key={index}>
                  <FlexListItem>
                    <Typography variant="body1">{part.partName}</Typography>
                    <Typography variant="body1" fontWeight="600">
                      x{part.quantity}
                    </Typography>
                  </FlexListItem>
                  {index < returnedParts.length - 1 && <Divider />}
                </React.Fragment>
              ))}
            </List>
          </Paper>
        )}
        <NTSModalFooter
          sx={{
            display: "flex",
            justifyContent: "end",
            marginY: 2,
          }}
        >
          <Button onClick={closeModal} endIcon={<Close />} color="primary">
            Close
          </Button>
        </NTSModalFooter>
      </NTSModal>
    )
  );
};

export default ChangedAndReturnedPartsModal;
