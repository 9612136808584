import Box from "@mui/material/Box";
import { DataGrid, GridActionsCellItem, GridToolbar } from "@mui/x-data-grid";
import { LinearProgress } from "@mui/material";
import { useState } from "react";
import { Delete, Edit, Visibility } from "@mui/icons-material";

const NTSTable = (props) => {
  const {
    onView,
    onEdit,
    onRemove,
    data,
    cols,
    loading,
    actions = false,
    customButtons,
    rowsPerPage,
  } = props;
  const [pageSize, setPageSize] = useState(rowsPerPage ? rowsPerPage[0] : 10);

  const columns = [
    ...cols,
    ...(!onView && !onEdit && !onRemove && !customButtons?.length > 0
      ? []
      : [
          {
            field: "actions",
            type: "actions",
            width: 100,
            getActions: (params) => {
              const buttons = [];
              if (onView)
                buttons.push(
                  <GridActionsCellItem
                    icon={<Visibility color="primary" />}
                    label="View"
                    onClick={() => onView(params.id)}
                  />
                );
              if (onEdit)
                buttons.push(
                  <GridActionsCellItem
                    icon={<Edit color="success" />}
                    label="Edit"
                    onClick={() => onEdit(params.id)}
                    showInMenu={actions}
                  />
                );
              if (onRemove)
                buttons.push(
                  <GridActionsCellItem
                    icon={<Delete color="error" />}
                    label="Remove"
                    color="error"
                    onClick={() => onRemove(params.id)}
                    showInMenu={actions}
                  />
                );
              if (customButtons?.length > 0)
                customButtons.forEach((button) =>
                  buttons.push(
                    <GridActionsCellItem
                      icon={button.icon}
                      label={button.label}
                      onClick={() => button.onClick(params.id)}
                      showInMenu={button.showInMenu}
                    />
                  )
                );

              return buttons;
            },
          },
        ]),
  ];

  return (
    <Box sx={{ width: "100%" }}>
      <DataGrid
        autoHeight
        rows={data}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={rowsPerPage ?? [10, 20, 50, 100]}
        columns={columns}
        components={{
          Toolbar: GridToolbar,
          LoadingOverlay: LinearProgress,
        }}
        loading={loading}
        disableSelectionOnClick
      />
    </Box>
  );
};

export default NTSTable;
