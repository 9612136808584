import { BuildOutlined } from "@mui/icons-material";
import {
  Badge,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Paper,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

const DashboardBox = (props) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { title, content, color } = props;

  return (
    <Grid item xs={12} md={6}>
      <Paper>
        <Stack
          direction="row"
          gap="20px"
          alignItems="center"
          sx={{
            backgroundColor: theme.palette[color].main,
            height: "60px",
          }}
        >
          <BuildOutlined
            sx={{
              fontSize: "35px",
              marginLeft: "20px",
              color: theme.palette.background.default,
            }}
          />
          <Typography
            variant="h6"
            sx={{
              fontWeight: 500,
              color: theme.palette.background.default,
            }}
          >
            {title}
          </Typography>
        </Stack>
        <List sx={{ maxHeight: "160px", overflow: "auto" }}>
          {content.map((item, index) => (
            <ListItem key={index} disablePadding>
              <ListItemButton
                onClick={() => navigate(item.url)}
                sx={{ marginRight: 1 }}
              >
                <ListItemText primary={item.value} />
                <Badge badgeContent={item.count} color={item.color} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Paper>
    </Grid>
  );
};

export default DashboardBox;
