import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";

import AddCircleIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import RefreshIcon from "@mui/icons-material/Refresh";

const CRUDHeader = (props) => {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{ pb: 2, pt: 2 }}
    >
      <Typography variant="h4">{props.title}</Typography>
      <Stack
        direction={{ xs: "column", sm: "row" }}
        spacing={0.5}
        alignItems="center"
      >
        {props.onRefresh && (
          <Chip
            label="Refresh"
            color="primary"
            variant=""
            onClick={props.onRefresh}
            icon={<RefreshIcon />}
            sx={{ width: 101 }}
          />
        )}
        {props.onCustom &&
          props.onCustom.map((item, index) => (
            <Chip
              key={index}
              label={item.label}
              color={item.color}
              variant=""
              onClick={item.onClick}
              icon={item.icon}
              sx={{ ...item.sx }}
            />
          ))}
        {props.onAddNew && (
          <Chip
            label="Add New"
            color="success"
            variant=""
            onClick={props.onAddNew}
            icon={<AddCircleIcon />}
            sx={{ width: 101 }}
          />
        )}
      </Stack>
    </Stack>
  );
};

export default CRUDHeader;
