import { createContext, useContext, useState } from "react";
import { signInUser, signOutUser } from "../services/api-service";
import { useSnackbar } from "./snackbar-context";

export const AuthContext = createContext({
  currentUser: {
    id: "",
    name: "",
    role: "",
    token: "",
  },
  selectedCountry: {
    id: "",
    show_name: "",
    name: "",
  },
  isLoggedIn: false,
  loading: false,
  signIn: (email, password) => {},
  signOut: () => {},
  changeCountry: (countryId) => {},
  rewriteAuthUserAfterEdit: (user) => {},
});

export const AuthContextProvider = (props) => {
  const { showSnackbar, resetSnackbar } = useSnackbar();
  const initialUser = JSON.parse(localStorage.getItem("authUser"));
  const initialCountry = JSON.parse(localStorage.getItem("selectedCountry"));
  const [currentUser, setCurrentUser] = useState(initialUser);
  const [selectedCountry, setSelectedCountry] = useState(initialCountry);

  const [loading, setLoading] = useState(false);

  const signInHandler = async (email, password) => {
    setLoading(true);
    resetSnackbar();
    try {
      const response = await signInUser(email, password);
      const userData = response.data.data;
      const authUser = {
        id: userData.id,
        name: `${userData.fname} ${userData.lname}`,
        role: userData.role,
        abilities: userData.abilities,
        countries: userData.countries,
        company: userData.company,
        token: userData.token,
      };
      setCurrentUser(authUser);
      setSelectedCountry(authUser.countries[0]);
      localStorage.setItem("authUser", JSON.stringify(authUser));
      localStorage.setItem(
        "selectedCountry",
        JSON.stringify(authUser.countries[0])
      );
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      showSnackbar(errorMessage, "error", "red");
    } finally {
      setLoading(false);
    }
  };

  const signOutHandler = async () => {
    setCurrentUser(null);
    localStorage.removeItem("authUser");
    localStorage.removeItem("selectedCountry");
    await signOutUser();
  };

  const changeCountry = (countryId) => {
    const newSelectedCountry = currentUser.countries.find(
      (country) => country.id === countryId
    );
    setSelectedCountry(newSelectedCountry);
    localStorage.setItem("selectedCountry", JSON.stringify(newSelectedCountry));
    window.location.reload(false);
  };

  const rewriteAuthUserAfterEdit = (user) => {
    const localStorageAuthUser = JSON.parse(localStorage.getItem("authUser"));
    const updatedAuthUser = {
      ...localStorageAuthUser,
      name: `${user.fname} ${user.lname}`,
    };
    setCurrentUser(updatedAuthUser);
    localStorage.setItem("authUser", JSON.stringify(updatedAuthUser));
  };

  const ctxValue = {
    currentUser,
    selectedCountry,
    isLoggedIn: !!currentUser,
    loading,
    signIn: signInHandler,
    signOut: signOutHandler,
    changeCountry,
    rewriteAuthUserAfterEdit,
  };

  return (
    <AuthContext.Provider value={ctxValue}>
      {props.children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
