import { Alert, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useServices } from "../../context/service-context";
import {
  getColorForServiceProgress,
  getColorFromStringForHoursPassed,
  getHourFromDatetime,
} from "../../services/helpers";
import LinearLoad from "../UI/LinearLoad";
import DashboardBox from "./DashboardBox";

const DashboardServiceFilters = () => {
  const { services, loading } = useServices();
  const [servicesByProgress, setservicesByProgress] = useState([]);
  const [servicesByType, setServicesByType] = useState([]);
  const [servicesByTime, setservicesByTime] = useState([]);
  const [servicesByLocation, setServicesByLocation] = useState([]);

  useEffect(() => {
    if (loading) return;

    const _servicesByProgress = [
      ...new Set(services.map((service) => service.step)),
    ].map((item) => ({
      value: item === "Closed" ? "Waiting for confirmation" : item,
      count: services.filter((s) => s.step === item).length,
      color: getColorForServiceProgress(item),
      url: `/service-manager?prog=${item.replaceAll(" ", "_")}`,
    }));
    setservicesByProgress(_servicesByProgress);

    const _servicesByTimeForCount = services.map((service) => {
      const hour = getHourFromDatetime(service.created_at);
      if (hour <= 4) return "Within 4 hours";
      if (hour > 4 && hour <= 12) return "Within 4 to 12 hours";
      if (hour > 12) return "More than 12 hours";
      return false;
    });
    const _servicesByTime = [
      ...new Set(
        services.map((service) => {
          const hour = getHourFromDatetime(service.created_at);
          if (hour <= 4) return "Within 4 hours";
          if (hour > 4 && hour <= 12) return "Within 4 to 12 hours";
          if (hour > 12) return "More than 12 hours";
          return false;
        })
      ),
    ].map((item) => ({
      value: item,
      count: _servicesByTimeForCount.filter((i) => i === item).length,
      color: getColorFromStringForHoursPassed(item),
      url: `/service-manager?time=${item.replaceAll(" ", "_")}`,
    }));
    setservicesByTime(_servicesByTime);

    const _servicesByType = [
      ...new Set(services.map((service) => service.machine_type)),
    ].map((item) => ({
      value: item,
      count: services.filter((service) => service.machine_type === item).length,
      color: "primary",
      url: `/service-manager?type=${item.replaceAll(" ", "_")}`,
    }));
    setServicesByType(_servicesByType);

    const _servicesByLocation = [
      ...new Set(services.map((service) => service.location.name)),
    ].map((item) => ({
      value: item,
      count: services.filter((service) => service.location.name === item)
        .length,
      color: "error",
      url: `/service-manager?loc=${item.replaceAll(" ", "_")}`,
    }));
    setServicesByLocation(_servicesByLocation);
    // eslint-disable-next-line
  }, [services, loading]);

  return (
    <>
      {loading && <LinearLoad />}
      <Grid container spacing={2}>
        {!loading &&
        servicesByProgress.length === 0 &&
        servicesByTime.length === 0 &&
        servicesByType.length === 0 &&
        servicesByLocation.length === 0 ? (
          <Grid item xs={12}>
            <Alert variant="outlined" severity="success">
              There are no active services right now
            </Alert>
          </Grid>
        ) : (
          <>
            {servicesByProgress.length > 0 && (
              <DashboardBox
                color="success"
                title="Active Services by Progress"
                content={servicesByProgress}
              />
            )}
            {servicesByType.length > 0 && (
              <DashboardBox
                color="primary"
                title="Active Services by Type"
                content={servicesByType}
              />
            )}
            {servicesByTime.length > 0 && (
              <DashboardBox
                color="warning"
                title="Active Services by Time"
                content={servicesByTime}
              />
            )}
            {servicesByLocation.length > 0 && (
              <DashboardBox
                color="error"
                title="Active Services by Location"
                content={servicesByLocation}
              />
            )}
          </>
        )}
      </Grid>
    </>
  );
};

export default DashboardServiceFilters;
