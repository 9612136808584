import { useCallback, useReducer } from "react";

const initialState = { value: "", isTouched: false };

const inputStateReducer = (state, action) => {
  if (action.type === "INPUT")
    return { value: action.value, isTouched: state.isTouched };
  if (action.type === "BLUR") return { value: state.value, isTouched: true };

  return initialState;
};

const useInput = (validation) => {
  const [state, dispatch] = useReducer(inputStateReducer, initialState);

  const valueIsValid = validation(state.value);
  const hasError = !valueIsValid && state.isTouched;

  const valueChangeHandler = (event) => {
    dispatch({ type: "INPUT", value: event.target?.value ?? event });
  };

  const setValueHandler = useCallback((value) => {
    dispatch({ type: "INPUT", value: value || "" });
  }, []);

  const valueBlurHandler = (event) => {
    dispatch({ type: "BLUR" });
  };

  const valueResetHandler = (event) => {
    dispatch({ type: "DECOY" });
  };

  return {
    value: state.value,
    isValid: valueIsValid,
    hasError,
    valueChangeHandler,
    setValueHandler,
    valueBlurHandler,
    valueResetHandler,
  };
};

export default useInput;
