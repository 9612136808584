import { Button, Stack, TextField } from "@mui/material";
import { useEffect } from "react";
import useInput from "../../../../hooks/use-input";

const InProgressAction = (props) => {
  const { onEditService, loading } = props;
  const {
    value: customStartValue,
    isValid: customStartIsValid,
    valueChangeHandler: customStartChangeHandler,
    valueResetHandler: customStartResetHandler,
  } = useInput((value) => value.trim().length >= 1);
  const {
    value: closeValue,
    isValid: closeIsValid,
    valueChangeHandler: closeChangeHandler,
    valueResetHandler: closeResetHandler,
  } = useInput((value) => value.trim().length >= 1);

  useEffect(() => {
    customStartResetHandler();
    closeResetHandler();
    // eslint-disable-next-line
  }, []);

  const submitHandler = (e, response) => {
    let message = null;
    switch (response) {
      case "Start Work":
        message = "Start work in club";
        break;
      case "Custom Start":
        message = customStartValue;
        break;
      case "Close":
        message = closeValue;
        break;
      default:
        message = null;
    }
    if (message) {
      const serviceObj = {
        responseType: response,
        message,
      };
      onEditService(serviceObj);
    }
    customStartResetHandler();
    closeResetHandler();
  };

  return (
    <>
      <Stack direction="column" sx={{ paddingX: "16px", gap: "10px" }}>
        <Button
          variant="contained"
          color="success"
          sx={{ height: "54px" }}
          onClick={(e) => submitHandler(e, "Start Work")}
          disabled={loading}
        >
          Start Work
        </Button>
        <TextField
          fullWidth
          name="custom_start"
          label="Custom Start"
          type="text"
          id="custom_start"
          variant="outlined"
          value={customStartValue}
          onChange={customStartChangeHandler}
          disabled={loading}
          InputProps={{
            endAdornment: (
              <Button
                position="end"
                color="primary"
                variant="contained"
                disabled={!customStartIsValid || loading}
                onClick={(e) => submitHandler(e, "Custom Start")}
              >
                Start
              </Button>
            ),
          }}
        />
        <TextField
          fullWidth
          name="close"
          label="Close without Intervention"
          type="text"
          id="close"
          color="error"
          variant="outlined"
          value={closeValue}
          onChange={closeChangeHandler}
          disabled={loading}
          InputProps={{
            endAdornment: (
              <Button
                position="end"
                color="error"
                variant="contained"
                disabled={!closeIsValid || loading}
                onClick={(e) => submitHandler(e, "Close")}
              >
                Close
              </Button>
            ),
          }}
        />
      </Stack>
    </>
  );
};

export default InProgressAction;
