import useInput from "../../hooks/use-input"
import { useAuth } from "../../context/auth-context"
import { Box, Button, CircularProgress, Grid, InputAdornment, Link, TextField, Typography } from "@mui/material"
import { AccountCircle, Key } from "@mui/icons-material"

const SignInForm = () => {
  const { signIn, loading } = useAuth()

  const {
    value: emailValue,
    isValid: emailIsValid,
    hasError: emailInputHasError,
    valueChangeHandler: emailInputChangeHandler,
    valueBlurHandler: emailInputBlurHandler
  } = useInput((value) => value.includes("@") && value.trim().length > 3)

  const {
    value: passwordValue,
    isValid: passwordIsValid,
    hasError: passwordInputHasError,
    valueChangeHandler: passwordInputChangeHandler,
    valueBlurHandler: passwordInputBlurHandler
  } = useInput((value) => value.trim().length > 7)


  const submitHandler = (event) => {
    event.preventDefault()
    signIn(emailValue, passwordValue)
  }

  const formIsValid = emailIsValid && passwordIsValid

  return (
    <Box sx={{ my: 8, mx: 4, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

      {/* <img src="logo192.png" alt="Logo" style={{width: "50px", height: "50px", marginBottom: "7px"}} /> */}
      <Typography component="h1" variant="h5">
        Sign in
      </Typography>

      <Box component="form" onSubmit={submitHandler} sx={{ mt: 1, width: "95%" }}>
        <TextField
          margin="normal" required fullWidth 
          id="email" label="Email Address" name="email"
          autoComplete="email" value={emailValue}
          onChange={emailInputChangeHandler} onBlur={emailInputBlurHandler} error={emailInputHasError}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AccountCircle />
              </InputAdornment>
            )
          }}
        />

        <TextField
          margin="normal" required fullWidth
          name="password" label="Password" type="password"
          id="password" autoComplete="current-password" value={passwordValue}
          onChange={passwordInputChangeHandler} onBlur={passwordInputBlurHandler} error={passwordInputHasError}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Key />
              </InputAdornment>
            )
          }}
        />
        
        <Button type="submit" fullWidth variant="contained" disabled={!formIsValid || loading}>
          {loading ? <CircularProgress size={25} /> : "Sign In"}
        </Button>
        <Grid container>
          <Grid item xs>
            <Link href="#" variant="body2">
              Forgot password?
            </Link>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default SignInForm