import { useAuth } from "../../context/auth-context";
import { useLocations } from "../../context/location-context";
import { useModal } from "../../context/modal-context";
import { hasAbility } from "../../services/helpers";
import NTSTable from "../UI/NTSTable";

const AllLocations = () => {
  const { locations, locationsLoading } = useLocations();
  const { openModal } = useModal();
  const { currentUser } = useAuth();

  const cols = [
    {
      field: "company",
      headerName: "Company",
      type: "string",
      width: 170,
      valueGetter: (params) => {
        return `${params.row.company.groupation_name}`;
      },
    },
    {
      field: "type",
      headerName: "Type",
      type: "string",
      width: 150,
      valueGetter: (params) => {
        return `${params.row.location_type.show_name}`;
      },
    },
    {
      field: "name",
      headerName: "Name",
      type: "string",
      width: 170,
    },
    {
      field: "address",
      headerName: "Address",
      type: "string",
      width: 200,
    },
    {
      field: "city",
      headerName: "City",
      type: "string",
      width: 150,
    },
    {
      field: "zip",
      headerName: "Zip",
      type: "string",
      width: 150,
      hide: true,
    },
    {
      field: "phone",
      headerName: "Phone",
      type: "string",
      width: 150,
      hide: true,
    },
    {
      field: "map_location",
      headerName: "Map Location",
      type: "string",
      width: 150,
      hide: true,
    },
  ];

  const canManageLocations = hasAbility(
    "can_manage_location",
    currentUser.role.abilities
  );

  return (
    <NTSTable
      onView={(id) => openModal("view", "location", id)}
      onEdit={
        canManageLocations ? (id) => openModal("edit", "location", id) : false
      }
      onRemove={
        canManageLocations ? (id) => openModal("delete", "location", id) : false
      }
      data={locations}
      cols={cols}
      loading={locationsLoading}
      actions={canManageLocations}
    />
  );
};

export default AllLocations;
