import { useCallback, createContext, useContext, useReducer } from "react";
import httpReducer from "../reducers/http-reducer";
import {
  createDevice,
  getDevices,
  updateDevice,
  deleteDevice,
  getDeviceTypes,
  createDeviceType,
  updateDeviceType,
  deleteDeviceType,
  getDevicesByFilter,
} from "../services/api-service";
import { useSnackbar } from "./snackbar-context";

export const DeviceContext = createContext({
  devices: [],
  deviceTypes: [],
  devicesLoading: false,
  deviceTypesLoading: false,
  loadDevices: () => {},
  loadDeviceTypes: () => {},
  searchDevices: (searchObj) => {},
  addDevice: (deviceObj) => {},
  editDevice: (id, deviceObj) => {},
  removeDevice: (id) => {},
  addDeviceType: (deviceTypeObj) => {},
  editDeviceType: (id, deviceTypeObj) => {},
  removeDeviceType: (id) => {},
  clearDevicesData: () => {},
});

export const DeviceContextProvider = (props) => {
  const { showSnackbar, resetSnackbar } = useSnackbar();
  const [devicesState, devicesDispatch] = useReducer(httpReducer, {
    data: [],
    loading: false,
  });
  const [deviceTypesState, deviceTypesDispatch] = useReducer(httpReducer, {
    data: [],
    loading: false,
  });

  const loadDevices = useCallback(async () => {
    devicesDispatch({ type: "START_LOAD" });
    resetSnackbar();
    try {
      const response = await getDevices();
      const devicesData = response.data.data;
      devicesDispatch({ type: "ADD_DATA", data: devicesData });
    } catch (error) {
      devicesDispatch({ type: "END_LOAD" });
      const errorMessage = error.response?.data?.message || error.message;
      showSnackbar(errorMessage, "error", "red");
    }
    // eslint-disable-next-line
  }, []);

  const loadDeviceTypes = useCallback(async () => {
    deviceTypesDispatch({ type: "START_LOAD" });
    resetSnackbar();
    try {
      const response = await getDeviceTypes();
      const deviceTypesData = response.data.data;
      deviceTypesDispatch({ type: "ADD_DATA", data: deviceTypesData });
    } catch (error) {
      deviceTypesDispatch({ type: "END_LOAD" });
      const errorMessage = error.response?.data?.message || error.message;
      showSnackbar(errorMessage, "error", "red");
    }
    // eslint-disable-next-line
  }, []);

  const searchDevices = async (searchObj) => {
    devicesDispatch({ type: "START_LOAD" });
    resetSnackbar();
    try {
      const response = await getDevicesByFilter(searchObj);
      const devicesData = response.data.data;
      if (devicesData.length < 1)
        showSnackbar(
          "No results found according to search criteria",
          "warning",
          "orange"
        );
      devicesDispatch({ type: "ADD_DATA", data: devicesData });
    } catch (error) {
      devicesDispatch({ type: "END_LOAD" });
      const errorMessage = error.response?.data?.message || error.message;
      showSnackbar(errorMessage, "error", "red");
    }
  };

  const addDevice = async (deviceObj) => {
    devicesDispatch({ type: "START_LOAD" });
    resetSnackbar();
    try {
      const response = await createDevice(deviceObj);
      devicesDispatch({ type: "END_LOAD" });
      const message = response.data.message;
      showSnackbar(message, "success", "green");
    } catch (error) {
      devicesDispatch({ type: "END_LOAD" });
      const errorMessage = error.response?.data?.message || error.message;
      showSnackbar(errorMessage, "error", "red");
    }
  };

  const addDeviceType = async (deviceTypeObj) => {
    deviceTypesDispatch({ type: "START_LOAD" });
    resetSnackbar();
    try {
      const response = await createDeviceType(deviceTypeObj);
      const deviceType = response.data.data;
      deviceTypesDispatch({ type: "ADD_ITEM", item: deviceType });
      const message = response.data.message;
      showSnackbar(message, "success", "green");
    } catch (error) {
      deviceTypesDispatch({ type: "END_LOAD" });
      const errorMessage = error.response?.data?.message || error.message;
      showSnackbar(errorMessage, "error", "red");
    }
  };

  const editDevice = async (id, deviceObj) => {
    devicesDispatch({ type: "START_LOAD" });
    resetSnackbar();
    try {
      const response = await updateDevice(id, deviceObj);
      const device = response.data.data;
      devicesDispatch({ type: "EDIT_ITEM", id: id, data: device });
      const message = response.data.message;
      showSnackbar(message, "success", "green");
    } catch (error) {
      devicesDispatch({ type: "END_LOAD" });
      const errorMessage = error.response?.data?.message || error.message;
      showSnackbar(errorMessage, "error", "red");
    }
  };

  const editDeviceType = async (id, deviceTypeObj) => {
    deviceTypesDispatch({ type: "START_LOAD" });
    resetSnackbar();
    try {
      const response = await updateDeviceType(id, deviceTypeObj);
      const deviceType = response.data.data;
      deviceTypesDispatch({ type: "EDIT_ITEM", id: id, data: deviceType });
      const message = response.data.message;
      showSnackbar(message, "success", "green");
    } catch (error) {
      deviceTypesDispatch({ type: "END_LOAD" });
      const errorMessage = error.response?.data?.message || error.message;
      showSnackbar(errorMessage, "error", "red");
    }
  };

  const removeDevice = async (id) => {
    devicesDispatch({ type: "START_LOAD" });
    resetSnackbar();
    try {
      const response = await deleteDevice(id);
      devicesDispatch({ type: "REMOVE_ITEM", id: id });
      const message = response.data.message;
      showSnackbar(message, "success", "green");
    } catch (error) {
      devicesDispatch({ type: "END_LOAD" });
      const errorMessage = error.response?.data?.message || error.message;
      showSnackbar(errorMessage, "error", "red");
    }
  };

  const removeDeviceType = async (id) => {
    deviceTypesDispatch({ type: "START_LOAD" });
    resetSnackbar();
    try {
      const response = await deleteDeviceType(id);
      deviceTypesDispatch({ type: "REMOVE_ITEM", id: id });
      const message = response.data.message;
      showSnackbar(message, "success", "green");
    } catch (error) {
      deviceTypesDispatch({ type: "END_LOAD" });
      const errorMessage = error.response?.data?.message || error.message;
      showSnackbar(errorMessage, "error", "red");
    }
  };

  const clearDevicesData = useCallback(() => {
    devicesDispatch({ type: "CLEAR_DATA" });
  }, []);

  const ctxValue = {
    devices: devicesState.data,
    deviceTypes: deviceTypesState.data,
    devicesLoading: devicesState.loading,
    deviceTypesLoading: deviceTypesState.loading,
    loadDevices,
    loadDeviceTypes,
    searchDevices,
    addDevice,
    editDevice,
    removeDevice,
    addDeviceType,
    editDeviceType,
    removeDeviceType,
    clearDevicesData,
  };

  return (
    <DeviceContext.Provider value={ctxValue}>
      {props.children}
    </DeviceContext.Provider>
  );
};

export const useDevices = () => useContext(DeviceContext);
