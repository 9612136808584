import { Container } from "@mui/material";
import { useEffect } from "react";
import AllCabinets from "../components/Cabinet/AllCabinets";
import CabinetModal from "../components/Cabinet/CabinetModal";
import CRUDHeader from "../components/UI/CRUDHeader";
import { useCabinets } from "../context/cabinet-context";
import { useModal } from "../context/modal-context";

const Cabinets = () => {
  const { loadCabinets } = useCabinets();
  const { openModal } = useModal();

  useEffect(() => {
    loadCabinets();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Container maxWidth="xl" sx={{ paddingBottom: "16px" }}>
        <CRUDHeader
          title="Cabinets"
          onAddNew={() => openModal("add", "cabinet")}
          onRefresh={() => loadCabinets()}
        />
        <AllCabinets />
      </Container>
      <CabinetModal />
    </>
  );
};

export default Cabinets;
