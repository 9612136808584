import { useDevices } from "../../context/device-context";
import { useModal } from "../../context/modal-context";
import NTSTable from "../UI/NTSTable";

const AllDeviceTypes = () => {
  const { deviceTypes, deviceTypesLoading } = useDevices();
  const { openModal } = useModal();

  const cols = [
    { field: "name", headerName: "Name", type: "string", width: 150 },
  ];

  return (
    <NTSTable
      onEdit={(id) => openModal("edit", "deviceType", id)}
      onRemove={(id) => openModal("delete", "deviceType", id)}
      data={deviceTypes}
      cols={cols}
      loading={deviceTypesLoading}
      actions={true}
    />
  );
};

export default AllDeviceTypes;
