import { SearchOutlined } from "@mui/icons-material";
import {
  Autocomplete,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  TextField,
} from "@mui/material";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useCompanies } from "../../context/company-context";
import { useLocations } from "../../context/location-context";
import { useModal } from "../../context/modal-context";
import { getCities } from "../../services/api-service";

const LocationsFilter = forwardRef((props, ref) => {
  const { modalState } = useModal();
  const {
    loadLocationTypes,
    locationTypesLoading,
    locationTypes,
    searchLocations,
    locationsLoading,
  } = useLocations();
  const {
    companies,
    loading: companiesLoading,
    loadCompanies,
  } = useCompanies();
  const [selectedCompany, setSelectedCompany] = useState("all");
  const [selectedLocationType, setSelectedLocationType] = useState("all");
  const [selectedCity, setSelectedCity] = useState("All");
  const [cities, setCities] = useState([]);
  const [citiesLoading, setCitiesLoading] = useState(false);

  useImperativeHandle(ref, () => ({
    resetFilter: () => {
      setSelectedCompany("all");
      setSelectedLocationType("all");
      setSelectedCity("All");
    },
  }));

  const companyChangeHandler = (value) => {
    setSelectedCompany(value);
  };

  const locationTypeChangeHandler = (value) => {
    setSelectedLocationType(value);
  };

  const cityChangeHandler = (value) => {
    setSelectedCity(value);
  };

  const onSearchHandler = () => {
    const searchObj = {
      company_id: selectedCompany !== "all" ? selectedCompany : null,
      location_type_id:
        selectedLocationType !== "all" ? selectedLocationType : null,
      city: selectedCity !== "All" ? selectedCity : null,
    };
    searchLocations(searchObj);
  };

  useEffect(() => {
    loadLocationTypes();
    loadCompanies();
    const citiesForFilter = async () => {
      setCitiesLoading(true);
      const response = await getCities();
      setCities(Object.values(response.data.data));
      setCitiesLoading(false);
    };
    citiesForFilter();
    // eslint-disable-next-line
  }, []);

  const overallLoading =
    (companiesLoading ||
      locationsLoading ||
      locationTypesLoading ||
      citiesLoading) &&
    !modalState.open;

  return (
    <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
      <Grid item xs={12} sm={3}>
        <FormControl fullWidth>
          <Autocomplete
            id="company"
            options={[{ id: "all", groupation_name: "All" }, ...companies]}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            value={
              [{ id: "all", groupation_name: "All" }, ...companies].find(
                (company) => company.id === selectedCompany
              ) || null
            }
            renderInput={(params) => (
              <TextField {...params} label="Company *" />
            )}
            getOptionLabel={(option) => option.groupation_name}
            onChange={(event, newOption) =>
              companyChangeHandler(newOption?.id ?? null)
            }
            disableClearable
            disabled={overallLoading}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={3}>
        <FormControl fullWidth>
          <Autocomplete
            id="locationType"
            options={[{ id: "all", name: "All" }, ...locationTypes]}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            value={
              [{ id: "all", name: "All" }, ...locationTypes].find(
                (locationType) => locationType.id === selectedLocationType
              ) || null
            }
            renderInput={(params) => (
              <TextField {...params} label="Location Type *" />
            )}
            getOptionLabel={(option) => option.show_name ?? option.name}
            onChange={(event, newOption) =>
              locationTypeChangeHandler(newOption?.id ?? null)
            }
            disableClearable
            disabled={overallLoading}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={3}>
        <FormControl fullWidth>
          <Autocomplete
            id="city"
            options={["All", ...cities]}
            value={selectedCity}
            renderInput={(params) => <TextField {...params} label="City *" />}
            onChange={(event, newOption) =>
              cityChangeHandler(newOption ?? null)
            }
            disableClearable
            disabled={overallLoading}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={3}>
        <Button
          variant="contained"
          sx={{ width: "100%", height: "100%" }}
          startIcon={!overallLoading && <SearchOutlined />}
          onClick={onSearchHandler}
          disabled={overallLoading}
        >
          {overallLoading ? <CircularProgress size={25} /> : "Search"}
        </Button>
      </Grid>
    </Grid>
  );
});

export default LocationsFilter;
