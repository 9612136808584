import { Container } from "@mui/material";
import { useEffect } from "react";
import { useCountries } from "../context/country-context";
import CRUDHeader from "../components/UI/CRUDHeader";
import AllCountries from "../components/Country/AllCountries";
import { useModal } from "../context/modal-context";
import CountryModal from "../components/Country/CountryModal";

const Countries = () => {
  const { loadCountries } = useCountries();
  const { openModal } = useModal();

  useEffect(() => {
    loadCountries();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Container maxWidth="sm" sx={{ paddingBottom: "16px" }}>
        <CRUDHeader
          title="Countries"
          onAddNew={() => openModal("add", "country")}
          onRefresh={() => loadCountries()}
        />
        <AllCountries />
      </Container>
      <CountryModal />
    </>
  );
};

export default Countries;
