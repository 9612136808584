import { useCallback, createContext, useContext, useReducer } from "react";
import httpReducer from "../reducers/http-reducer";
import {
  createCabinet,
  deleteCabinet,
  getCabinet,
  getCabinets,
  updateCabinet,
} from "../services/api-service";
import { useSnackbar } from "./snackbar-context";

export const CabinetContext = createContext({
  cabinets: [],
  loading: false,
  loadCabinets: () => {},
  loadCabinet: (id) => {},
  addCabinet: (cabinetObj) => {},
  editCabinet: (id, cabinetObj) => {},
  removeCabinet: (id) => {},
});

export const CabinetContextProvider = (props) => {
  const { showSnackbar, resetSnackbar } = useSnackbar();
  const [state, dispatch] = useReducer(httpReducer, {
    data: [],
    loading: false,
  });

  const loadCabinets = useCallback(async () => {
    dispatch({ type: "START_LOAD" });
    resetSnackbar();
    try {
      const response = await getCabinets();
      const cabinetsData = response.data.data;
      dispatch({ type: "ADD_DATA", data: cabinetsData });
    } catch (error) {
      dispatch({ type: "END_LOAD" });
      const errorMessage = error.response?.data?.message || error.message;
      showSnackbar(errorMessage, "error", "red");
    }
    // eslint-disable-next-line
  }, []);

  const loadCabinet = useCallback(async (cabinetId) => {
    dispatch({ type: "START_LOAD" });
    resetSnackbar();
    try {
      const response = await getCabinet(cabinetId);
      const cabinetData = response.data.data;
      dispatch({ type: "ADD_DATA", data: [cabinetData] });
    } catch (error) {
      dispatch({ type: "END_LOAD" });
      const errorMessage = error.response?.data?.message || error.message;
      showSnackbar(errorMessage, "error", "red");
    }
    // eslint-disable-next-line
  }, []);

  const addCabinet = async (cabinetObj) => {
    dispatch({ type: "START_LOAD" });
    resetSnackbar();
    try {
      const response = await createCabinet(cabinetObj);
      const cabinet = response.data.data;
      dispatch({ type: "ADD_ITEM", item: cabinet });
      const message = response.data.message;
      showSnackbar(message, "success", "green");
    } catch (error) {
      dispatch({ type: "END_LOAD" });
      const errorMessage = error.response?.data?.message || error.message;
      showSnackbar(errorMessage, "error", "red");
    }
  };

  const editCabinet = async (id, cabinetObj) => {
    dispatch({ type: "START_LOAD" });
    resetSnackbar();
    try {
      const response = await updateCabinet(id, cabinetObj);
      const cabinet = response.data.data;
      dispatch({ type: "EDIT_ITEM", id: id, data: cabinet });
      const message = response.data.message;
      showSnackbar(message, "success", "green");
    } catch (error) {
      dispatch({ type: "END_LOAD" });
      const errorMessage = error.response?.data?.message || error.message;
      showSnackbar(errorMessage, "error", "red");
    }
  };

  const removeCabinet = async (id) => {
    dispatch({ type: "START_LOAD" });
    resetSnackbar();
    try {
      const response = await deleteCabinet(id);
      const message = response.data.message;
      dispatch({ type: "REMOVE_ITEM", id: id });
      showSnackbar(message, "success", "green");
    } catch (error) {
      dispatch({ type: "END_LOAD" });
      const errorMessage = error.response?.data?.message || error.message;
      showSnackbar(errorMessage, "error", "red");
    }
  };

  const ctxValue = {
    cabinets: state.data,
    loading: state.loading,
    loadCabinets,
    loadCabinet,
    addCabinet,
    editCabinet,
    removeCabinet,
  };

  return (
    <CabinetContext.Provider value={ctxValue}>
      {props.children}
    </CabinetContext.Provider>
  );
};

export const useCabinets = () => useContext(CabinetContext);
