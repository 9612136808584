import { MoveDownOutlined } from "@mui/icons-material";
import { Container } from "@mui/material";
import { useEffect, useMemo } from "react";
import CRUDHeader from "../components/UI/CRUDHeader";
import AllWarehouses from "../components/Warehouse/AllWarehouses";
import WarehouseModal from "../components/Warehouse/WarehouseModal";
import { useLocations } from "../context/location-context";
import { useModal } from "../context/modal-context";

const Warehouses = () => {
  const { loadWarehouses } = useLocations();
  const { openModal } = useModal();

  useEffect(() => {
    loadWarehouses();
    // eslint-disable-next-line
  }, []);

  const onCustom = useMemo(() => {
    return [
      {
        label: "Transfer parts",
        color: "warning",
        onClick: () => openModal("transfer", "warehouse"),
        icon: <MoveDownOutlined />,
        sx: { width: 140 },
      },
    ];

    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Container maxWidth="xl" sx={{ paddingBottom: "16px" }}>
        <CRUDHeader
          title="Operate Warehouses"
          onRefresh={() => loadWarehouses()}
          onCustom={onCustom}
        />
        <AllWarehouses />
      </Container>
      <WarehouseModal />
    </>
  );
};

export default Warehouses;
