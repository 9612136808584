import api from "../api/api";
import authApi from "../api/authApi";
import multimediaAuthApi from "../api/multimediaAuthApi";

// Auth
export const signInUser = (email, password) => {
  return api({
    method: "POST",
    url: "/api/user/login",
    data: {
      email,
      password,
    },
  });
};
export const signOutUser = () => {
  return authApi({
    method: "GET",
    url: "/api/user/logout",
  });
};

// User
export const getUsers = () => {
  return authApi({
    method: "GET",
    url: "/api/user/all",
  });
};
export const getUsersByFilter = (filter) => {
  return authApi({
    method: "POST",
    url: "/api/user/byfilter",
    data: filter,
  });
};
export const getUser = (id) => {
  return authApi({
    method: "GET",
    url: `/api/user/${id}`,
  });
};
export const getTechnicians = () => {
  return authApi({
    method: "GET",
    url: "/api/user/technicians",
  });
};
export const createUser = (user) => {
  return authApi({
    method: "POST",
    url: "/api/user/store",
    data: user,
  });
};
export const updateUser = (id, user) => {
  return authApi({
    method: "PUT",
    url: `/api/user/update/${id}`,
    data: user,
  });
};
export const deleteUser = (id) => {
  return authApi({
    method: "DELETE",
    url: `/api/user/delete/${id}`,
  });
};
export const getUsersByLocation = (locationId) => {
  return authApi({
    method: "POST",
    url: "/api/user/bylocation",
    data: locationId,
  });
};

// Country
export const getCountries = () => {
  return authApi({
    method: "GET",
    url: "/api/country/all",
  });
};
export const createCountry = (name) => {
  return authApi({
    method: "POST",
    url: "/api/country/store",
    data: {
      show_name: name,
    },
  });
};
export const updateCountry = (id, country) => {
  return authApi({
    method: "PUT",
    url: `/api/country/update/${id}`,
    data: country,
  });
};

export const deleteCountry = (id) => {
  return authApi({
    method: "DELETE",
    url: `/api/country/delete/${id}`,
  });
};

// Company
export const getCompanies = () => {
  return authApi({
    method: "GET",
    url: "/api/company/all",
  });
};
export const createCompany = (company) => {
  return multimediaAuthApi({
    method: "POST",
    url: "/api/company/store",
    data: company,
  });
};
export const updateCompany = (id, company) => {
  return multimediaAuthApi({
    method: "POST",
    url: `/api/company/update/${id}`,
    data: company,
  });
};
export const deleteCompany = (id) => {
  return authApi({
    method: "DELETE",
    url: `/api/company/delete/${id}`,
  });
};

// Role
export const getRoles = () => {
  return authApi({
    method: "GET",
    url: "/api/role/all",
  });
};
export const getRoleTypes = () => {
  return authApi({
    method: "GET",
    url: "/api/roleType/all",
  });
};
export const createRole = (role) => {
  return authApi({
    method: "POST",
    url: "/api/role/store",
    data: role,
  });
};
export const updateRole = (id, role) => {
  return authApi({
    method: "PUT",
    url: `/api/role/update/${id}`,
    data: role,
  });
};
export const deleteRole = (id) => {
  return authApi({
    method: "DELETE",
    url: `/api/role/delete/${id}`,
  });
};

// Ability
export const getAbilities = () => {
  return authApi({
    method: "GET",
    url: "/api/ability/all",
  });
};

// Assign
export const assignAbilitiesToRole = (id, abilityIds) => {
  return authApi({
    method: "POST",
    url: `/api/assign/${id}/assign-abilities-to-role`,
    data: abilityIds,
  });
};
export const assignLocationsToUser = (id, locationIds) => {
  return authApi({
    method: "POST",
    url: `/api/assign/${id}/assign-locations-to-user`,
    data: locationIds,
  });
};
export const assignCompanyLocationsToUser = (id, company_id) => {
  return authApi({
    method: "POST",
    url: `/api/assign/${id}/attach-company-locations-to-user`,
    data: company_id,
  });
};
export const attachLocationsToUser = (id, locationIds) => {
  return authApi({
    method: "POST",
    url: `/api/assign/${id}/attach-locations-to-user`,
    data: locationIds,
  });
};
export const detachLocationsFromUser = (id, locationIds) => {
  return authApi({
    method: "POST",
    url: `/api/assign/${id}/detach-locations-from-user`,
    data: locationIds,
  });
};
export const assignUsersToLocation = (id, userIds) => {
  return authApi({
    method: "POST",
    url: `/api/assign/${id}/assign-users-to-location`,
    data: userIds,
  });
};
export const attachUsersToLocation = (id, userIds) => {
  return authApi({
    method: "POST",
    url: `/api/assign/${id}/attach-users-to-location`,
    data: userIds,
  });
};
export const detachUsersFromLocation = (id, userIds) => {
  return authApi({
    method: "POST",
    url: `/api/assign/${id}/detach-users-from-location`,
    data: userIds,
  });
};
export const attachLocationToGroup = (id, locationId) => {
  return authApi({
    method: "POST",
    url: `/api/assign/${id}/attach-location-to-group`,
    data: locationId,
  });
};
export const detachLocationFromGroup = (id, locationId) => {
  return authApi({
    method: "POST",
    url: `/api/assign/${id}/detach-location-from-group`,
    data: locationId,
  });
};
export const attachTechnicianToGroup = (id, technicianId) => {
  return authApi({
    method: "POST",
    url: `/api/assign/${id}/attach-technician-to-group`,
    data: technicianId,
  });
};
export const detachTechnicianFromGroup = (id, technicianId) => {
  return authApi({
    method: "POST",
    url: `/api/assign/${id}/detach-technician-from-group`,
    data: technicianId,
  });
};
export const attachSparePartsToWarehouse = (locationId, assignObj) => {
  return authApi({
    method: "POST",
    url: `/api/assign/${locationId}/attach-spare-parts-to-warehouse`,
    data: assignObj,
  });
};
export const transferSpareParts = (assignObj) => {
  return authApi({
    method: "POST",
    url: "/api/assign/warehouses/transfer",
    data: assignObj,
  });
};

// Location
export const getLocations = () => {
  return authApi({
    method: "GET",
    url: "/api/location/all",
  });
};
export const getLocation = (id) => {
  return authApi({
    method: "GET",
    url: `/api/location/${id}`,
  });
};
export const getLocationsByFilter = (filter) => {
  return authApi({
    method: "POST",
    url: "/api/location/byfilter",
    data: filter,
  });
};
export const getWarehouses = () => {
  return authApi({
    method: "GET",
    url: "/api/location/warehouses",
  });
};
export const getCities = () => {
  return authApi({
    method: "GET",
    url: "/api/location/cities",
  });
};
export const createLocation = (location) => {
  return multimediaAuthApi({
    method: "POST",
    url: "/api/location/store",
    data: location,
  });
};
export const updateLocation = (id, location) => {
  return multimediaAuthApi({
    method: "POST",
    url: `/api/location/update/${id}`,
    data: location,
  });
};
export const deleteLocation = (id) => {
  return authApi({
    method: "DELETE",
    url: `/api/location/delete/${id}`,
  });
};
export const getLocationsByUser = (userId) => {
  return authApi({
    method: "POST",
    url: "/api/location/byuser",
    data: userId,
  });
};

// Location Type
export const getLocationTypes = () => {
  return authApi({
    method: "GET",
    url: "/api/locationtype/all",
  });
};

// Location Group
export const getGroups = () => {
  return authApi({
    method: "GET",
    url: "/api/locationgroup/all",
  });
};
export const createGroup = (group) => {
  return authApi({
    method: "POST",
    url: "/api/locationgroup/store",
    data: group,
  });
};
export const updateGroup = (id, group) => {
  return authApi({
    method: "PUT",
    url: `/api/locationgroup/update/${id}`,
    data: group,
  });
};
export const deleteGroup = (id) => {
  return authApi({
    method: "DELETE",
    url: `/api/locationgroup/delete/${id}`,
  });
};

// Manufacturer
export const getManufacturers = () => {
  return authApi({
    method: "GET",
    url: "/api/manufacturer/all",
  });
};
export const createManufacturer = (manufacturer) => {
  return authApi({
    method: "POST",
    url: "/api/manufacturer/store",
    data: manufacturer,
  });
};
export const updateManufacturer = (id, manufacturer) => {
  return authApi({
    method: "PUT",
    url: `/api/manufacturer/update/${id}`,
    data: manufacturer,
  });
};
export const deleteManufacturer = (id) => {
  return authApi({
    method: "DELETE",
    url: `/api/manufacturer/delete/${id}`,
  });
};

// Cabinet
export const getCabinets = () => {
  return authApi({
    method: "GET",
    url: "/api/cabinet/all",
  });
};
export const getCabinet = (id) => {
  return authApi({
    method: "GET",
    url: `/api/cabinet/${id}`,
  });
};
export const createCabinet = (cabinet) => {
  return multimediaAuthApi({
    method: "POST",
    url: "/api/cabinet/store",
    data: cabinet,
  });
};
export const updateCabinet = (id, cabinet) => {
  return multimediaAuthApi({
    method: "POST",
    url: `/api/cabinet/update/${id}`,
    data: cabinet,
  });
};
export const deleteCabinet = (id) => {
  return authApi({
    method: "DELETE",
    url: `/api/cabinet/delete/${id}`,
  });
};

// Part Type
export const getPartTypes = () => {
  return authApi({
    method: "GET",
    url: "/api/parttype/all",
  });
};
export const createPartType = (partType) => {
  return authApi({
    method: "POST",
    url: "/api/parttype/store",
    data: partType,
  });
};
export const updatePartType = (id, partType) => {
  return authApi({
    method: "PUT",
    url: `/api/parttype/update/${id}`,
    data: partType,
  });
};
export const deletePartType = (id) => {
  return authApi({
    method: "DELETE",
    url: `/api/parttype/delete/${id}`,
  });
};

// Part
export const getParts = () => {
  return authApi({
    method: "GET",
    url: "/api/part/all",
  });
};
export const getPartsByFilter = (filter) => {
  return authApi({
    method: "POST",
    url: "/api/part/byfilter",
    data: filter,
  });
};
export const createPart = (part) => {
  return authApi({
    method: "POST",
    url: "/api/part/store",
    data: part,
  });
};
export const updatePart = (id, part) => {
  return authApi({
    method: "PUT",
    url: `/api/part/update/${id}`,
    data: part,
  });
};
export const deletePart = (id) => {
  return authApi({
    method: "DELETE",
    url: `/api/part/delete/${id}`,
  });
};

// Device Type
export const getDeviceTypes = () => {
  return authApi({
    method: "GET",
    url: "/api/devicetype/all",
  });
};
export const createDeviceType = (deviceType) => {
  return authApi({
    method: "POST",
    url: "/api/devicetype/store",
    data: deviceType,
  });
};
export const updateDeviceType = (id, deviceType) => {
  return authApi({
    method: "PUT",
    url: `/api/devicetype/update/${id}`,
    data: deviceType,
  });
};
export const deleteDeviceType = (id) => {
  return authApi({
    method: "DELETE",
    url: `/api/devicetype/delete/${id}`,
  });
};

// Device
export const getDevices = () => {
  return authApi({
    method: "GET",
    url: "/api/device/all",
  });
};
export const getDevicesByFilter = (filter) => {
  return authApi({
    method: "POST",
    url: "/api/device/byfilter",
    data: filter,
  });
};
export const createDevice = (device) => {
  return authApi({
    method: "POST",
    url: "/api/device/store",
    data: device,
  });
};
export const updateDevice = (id, device) => {
  return authApi({
    method: "PUT",
    url: `/api/device/update/${id}`,
    data: device,
  });
};
export const deleteDevice = (id) => {
  return authApi({
    method: "DELETE",
    url: `/api/device/delete/${id}`,
  });
};

// Machine
export const getMachines = () => {
  return authApi({
    method: "GET",
    url: "/api/machine/all",
  });
};
export const getMachine = (id) => {
  return authApi({
    method: "GET",
    url: `/api/machine/${id}`,
  });
};
export const getMachinesByFilter = (filter) => {
  return authApi({
    method: "POST",
    url: "/api/machine/byfilter",
    data: filter,
  });
};
export const createMachine = (machine) => {
  return authApi({
    method: "POST",
    url: "/api/machine/store",
    data: machine,
  });
};
export const updateMachine = (id, machine) => {
  return authApi({
    method: "PUT",
    url: `/api/machine/update/${id}`,
    data: machine,
  });
};
export const deleteMachine = (id) => {
  return authApi({
    method: "DELETE",
    url: `/api/machine/delete/${id}`,
  });
};

// Spare Part
export const getSpareParts = () => {
  return authApi({
    method: "GET",
    url: "/api/sparepart/all",
  });
};
export const createSparePart = (sparePart) => {
  return authApi({
    method: "POST",
    url: "/api/sparepart/store",
    data: sparePart,
  });
};
export const updateSparePart = (id, sparePart) => {
  return authApi({
    method: "PUT",
    url: `/api/sparepart/update/${id}`,
    data: sparePart,
  });
};
export const deleteSparePart = (id) => {
  return authApi({
    method: "DELETE",
    url: `/api/sparepart/delete/${id}`,
  });
};

// Service
export const getServiceStatuses = () => {
  return authApi({
    method: "GET",
    url: "/api/service/statuses",
  });
};
export const getActiveServicesCount = () => {
  return authApi({
    method: "GET",
    url: "/api/service/activeCount",
  });
};
export const getActiveServices = () => {
  return authApi({
    method: "GET",
    url: "/api/service/all",
  });
};
export const getClosedServices = () => {
  return authApi({
    method: "GET",
    url: "/api/service/closed",
  });
};
export const getClosedServicesByFilter = (filter) => {
  return authApi({
    method: "POST",
    url: "/api/service/closed/byfilter",
    data: filter,
  });
};
export const getClosedServicesForMachine = (filter) => {
  return authApi({
    method: "POST",
    url: "/api/service/closed/bymachine",
    data: filter,
  });
};
export const getRelatedFixDescriptions = (errorDescription) => {
  return authApi({
    method: "POST",
    url: "/api/service/related-fix-descriptions",
    data: errorDescription,
  });
};
export const createService = (service) => {
  return multimediaAuthApi({
    method: "POST",
    url: "/api/service/store",
    data: service,
  });
};
export const createServiceConversation = (id, message) => {
  return authApi({
    method: "POST",
    url: `/api/service/serviceConversation/${id}`,
    data: message,
  });
};
export const updateService = (id, service) => {
  return authApi({
    method: "POST",
    url: `/api/service/update/${id}`,
    data: service,
  });
};
export const deleteService = (id) => {
  return authApi({
    method: "DELETE",
    url: `/api/service/delete/${id}`,
  });
};
