import { Container } from "@mui/material";
import { useEffect } from "react";
import AllCompanies from "../components/Company/AllCompanies";
import CompanyModal from "../components/Company/CompanyModal";
import CRUDHeader from "../components/UI/CRUDHeader";
import { useCompanies } from "../context/company-context";
import { useModal } from "../context/modal-context";

const Companies = () => {
  const { loadCompanies } = useCompanies();
  const { openModal } = useModal();

  useEffect(() => {
    loadCompanies();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Container maxWidth="md" sx={{ paddingBottom: "16px" }}>
        <CRUDHeader
          title="Companies"
          onAddNew={() => openModal("add", "company")}
          onRefresh={() => loadCompanies()}
        />
        <AllCompanies />
      </Container>
      <CompanyModal />
    </>
  );
};

export default Companies;
