import { AddCircleOutline, Delete, EditOutlined } from "@mui/icons-material";
import {
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Paper,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { useState } from "react";

const GroupBox = (props) => {
  const [value, setValue] = useState("1");
  const {
    onGroupEdit,
    onGroupDelete,
    onAddTechnician,
    onAddLocation,
    onDeleteLocationFromGroup,
    onDeleteTechnicianFromGroup,
    group,
  } = props;

  let listContent = <></>;
  if (value === "1") {
    listContent =
      group.locations.length > 0 ? (
        group.locations.map((location, index) => (
          <ListItem
            key={index}
            secondaryAction={
              <IconButton
                edge="end"
                onClick={() =>
                  onDeleteLocationFromGroup({
                    id: location.id,
                    name: location.name,
                  })
                }
              >
                <Delete color="error" />
              </IconButton>
            }
          >
            {location.name}
          </ListItem>
        ))
      ) : (
        <ListItem>No results</ListItem>
      );
  }
  if (value === "2") {
    listContent =
      group.technicians.length > 0 ? (
        group.technicians.map((technician, index) => (
          <ListItem
            key={index}
            secondaryAction={
              <IconButton
                edge="end"
                onClick={() =>
                  onDeleteTechnicianFromGroup({
                    id: technician.id,
                    name: `${technician.fname} ${technician.lname}`,
                  })
                }
              >
                <Delete color="error" />
              </IconButton>
            }
          >
            {technician.fname} {technician.lname}
          </ListItem>
        ))
      ) : (
        <ListItem>No results</ListItem>
      );
  }
  if (value === "3") {
    listContent = (
      <>
        <ListItemButton onClick={onAddLocation}>
          <ListItemIcon>
            <AddCircleOutline color="info" />
          </ListItemIcon>
          <ListItemText primary="Assign Locations" />
        </ListItemButton>
        <ListItemButton onClick={onAddTechnician}>
          <ListItemIcon>
            <AddCircleOutline color="info" />
          </ListItemIcon>
          <ListItemText primary="Assign Technicians" />
        </ListItemButton>
        <ListItemButton onClick={onGroupEdit}>
          <ListItemIcon>
            <EditOutlined color="success" />
          </ListItemIcon>
          <ListItemText primary="Edit Group" />
        </ListItemButton>
        <ListItemButton onClick={onGroupDelete}>
          <ListItemIcon>
            <Delete color="error" />
          </ListItemIcon>
          <ListItemText primary="Delete Group" />
        </ListItemButton>
      </>
    );
  }

  return (
    <Grid item xs={12} sm={6} md={4}>
      <Paper sx={{ height: "270px", overflow: "auto" }}>
        <Tabs
          value={value}
          onChange={(e, value) => setValue(value)}
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab label="Locations" value="1" />
          <Tab label="Technicians" value="2" />
          <Tab label="Manage" value="3" />
        </Tabs>
        <List
          dense
          sx={{ mt: 1 }}
          subheader={
            <ListSubheader component="div" disableSticky>
              <Typography variant="body1">
                {group.show_name ?? group.name}
              </Typography>
            </ListSubheader>
          }
        >
          <Divider sx={{ mb: 1, mt: 1 }} />
          {listContent}
        </List>
      </Paper>
    </Grid>
  );
};

export default GroupBox;
