import { Button, Stack, TextField } from "@mui/material";
import { useEffect } from "react";
import { useModal } from "../../../../context/modal-context";
import useInput from "../../../../hooks/use-input";
import FinishModal from "./FinishModal";

const FinishAction = (props) => {
  const { serviceId, errorDescription, onEditService, loading } = props;
  const { openModal } = useModal();

  const {
    value: closeValue,
    isValid: closeIsValid,
    valueChangeHandler: closeChangeHandler,
    valueResetHandler: closeResetHandler,
  } = useInput((value) => value.trim().length >= 1);

  useEffect(() => {
    closeResetHandler();
    // eslint-disable-next-line
  }, []);

  const submitHandler = (e, response) => {
    let message = null;
    switch (response) {
      case "Finish Work":
        openModal("add", "work_report", serviceId, { errorDescription });
        break;
      case "Close":
        message = closeValue;
        break;
      default:
        message = null;
    }
    if (message) {
      const serviceObj = {
        responseType: response,
        message,
      };
      onEditService(serviceObj);
    }
    closeResetHandler();
  };

  return (
    <>
      <Stack direction="column" sx={{ paddingX: "16px", gap: "10px" }}>
        <Button
          variant="contained"
          color="success"
          sx={{ height: "54px" }}
          onClick={(e) => submitHandler(e, "Finish Work")}
          disabled={loading}
        >
          Finish Work
        </Button>
        <TextField
          fullWidth
          name="close"
          label="Close without Intervention"
          type="text"
          id="close"
          color="error"
          variant="outlined"
          value={closeValue}
          onChange={closeChangeHandler}
          disabled={loading}
          InputProps={{
            endAdornment: (
              <Button
                position="end"
                color="error"
                variant="contained"
                disabled={!closeIsValid || loading}
                onClick={(e) => submitHandler(e, "Close")}
              >
                Close
              </Button>
            ),
          }}
        />
      </Stack>
      <FinishModal onEditService={onEditService} />
    </>
  );
};

export default FinishAction;
