import { Refresh } from "@mui/icons-material";
import { Container } from "@mui/material";
import { useMemo, useRef, useEffect } from "react";
import AllLocations from "../components/Location/AllLocations";
import LocationModal from "../components/Location/LocationModal";
import LocationsFilter from "../components/Location/LocationsFilter";
import CRUDHeader from "../components/UI/CRUDHeader";
import { useAuth } from "../context/auth-context";
import { useLocations } from "../context/location-context";
import { useModal } from "../context/modal-context";
import { hasAbility } from "../services/helpers";

const Locations = () => {
  const { clearLocationsData, locations } = useLocations();
  const { openModal } = useModal();
  const { currentUser } = useAuth();
  const locationsFilterRef = useRef();

  let onAddNew = false;
  if (hasAbility("can_create_location", currentUser.role.abilities))
    onAddNew = () => openModal("add", "location");

  useEffect(() => {
    clearLocationsData();
    // eslint-disable-next-line
  }, []);

  const onCustom = useMemo(() => {
    return [
      {
        label: "Reset Filters",
        color: "info",
        onClick: () => locationsFilterRef.current.resetFilter(),
        icon: <Refresh />,
        sx: { width: 120 },
      },
    ];
  }, []);

  return (
    <>
      <Container maxWidth="lg" sx={{ paddingBottom: "16px" }}>
        <CRUDHeader title="Locations" onAddNew={onAddNew} onCustom={onCustom} />
        <LocationsFilter ref={locationsFilterRef} />
        {locations.length > 0 && <AllLocations />}
      </Container>
      <LocationModal />
    </>
  );
};

export default Locations;
