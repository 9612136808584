import React, { useState } from "react";
import { Brightness4, Brightness7 } from "@mui/icons-material";
import { createTheme, IconButton, ThemeProvider, Tooltip } from "@mui/material";
import { deepmerge } from "@mui/utils";

const ThemeHandler = (props) => {
  const initTheme = localStorage.getItem("theme") ?? "light";
  const [themeMode, setThemeMode] = useState(initTheme);

  const switchThemeHandler = () => {
    const newTheme = themeMode === "light" ? "dark" : "light";
    setThemeMode(newTheme);
    localStorage.setItem("theme", newTheme);
  };

  const lightTheme = createTheme({
    palette: {
      background: {
        default: "#fafafa",
      },
    },
    typography: {
      fontFamily: "Lato",
    },
    spacing: 8,
    components: {
      MuiCssBaseline: {
        styleOverrides: (theme) => ({
          body: {
            scrollbarColor: `${theme.palette.background.paper} ${theme.palette.background.default}`,
            "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
              backgroundColor: `${theme.palette.background.paper}`,
              width: "13px",
              height: "13px",
            },
            "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
              borderRadius: 8,
              backgroundColor: `${theme.palette.background.paper}`,
              minHeight: 20,
              border: `1px solid ${theme.palette.text.disabled}`,
            },
            "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus":
              {
                backgroundColor: "#959595",
              },
            "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active":
              {
                backgroundColor: "#959595",
              },
            "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover":
              {
                backgroundColor: "#959595",
              },
            "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
              backgroundColor: `${theme.palette.background.default}`,
            },
          },
        }),
      },
    },
  });

  const darkTheme = createTheme(
    deepmerge(lightTheme, {
      palette: {
        text: {
          primary: "#ffffff",
          secondary: "rgba(255, 255, 255, 0.7)",
          disabled: "rgba(255, 255, 255, 0.5)",
        },
        action: {
          active: "#ffffff",
          hover: "rgba(255, 255, 255, 0.08)",
          label: "#ffffff",
          selected: "rgba(255, 255, 255, 0.16)",
          disabled: "rgba(255, 255, 255, 0.3)",
          disabledBackground: "rgba(255, 255, 255, 0.12)",
        },
        background: {
          paper: "#1F2937",
          default: "#111827",
        },
        divider: "rgba(255, 255, 255, 0.3)",
      },
      components: {
        MuiTextField: {
          styleOverrides: {
            root: {
              "&.MuiOutlinedInput-root": {
                borderColor: "rgba(255, 255, 255, 0.5)",
                backgroundColor: "transparent",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(255, 255, 255, 0.5)",
                backgroundColor: "transparent",
              },
              "input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active":
                {
                  transition: "background-color 5000s ease-in-out 0s",
                },
              "input:-webkit-autofill": {
                "-webkit-text-fill-color": "#ffffff !important",
              },
            },
          },
        },
        MuiInputBase: {
          styleOverrides: {
            root: {
              "&.MuiInputBase-root": {
                borderColor: "rgba(255, 255, 255, 0.5)",
                backgroundColor: "transparent",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(255, 255, 255, 0.5)",
                backgroundColor: "transparent",
              },
            },
          },
        },
      },
    })
  );

  const themeValue = themeMode === "light" ? lightTheme : darkTheme;

  const tooltipText = `Switch to ${
    themeMode === "light" ? "Dark" : "Light"
  } mode`;

  const icon =
    themeMode === "light" ? (
      <Brightness4 fontSize="large" />
    ) : (
      <Brightness7 fontSize="large" />
    );

  return (
    <ThemeProvider theme={themeValue}>
      {props.children}
      <Tooltip title={tooltipText} arrow>
        <IconButton
          onClick={switchThemeHandler}
          sx={{ position: "fixed", bottom: 10, right: 5 }}
        >
          {icon}
        </IconButton>
      </Tooltip>
    </ThemeProvider>
  );
};

export default ThemeHandler;
