import { useModal } from "../../context/modal-context";
import { useUsers } from "../../context/user-context";
import NTSTable from "../UI/NTSTable";

const AllUsers = () => {
  const { users, loading } = useUsers();
  const { openModal } = useModal();

  const cols = [
    {
      field: "name",
      headerName: "Name",
      type: "string",
      width: 170,
      valueGetter: (params) => {
        return `${params.row.fname} ${params.row.lname}`;
      },
    },
    {
      field: "company",
      headerName: "Company",
      type: "string",
      width: 150,
      valueGetter: (params) => {
        return params.row.company?.groupation_name ?? "";
      },
    },
    {
      field: "role",
      headerName: "Role",
      type: "string",
      width: 150,
      valueGetter: (params) => {
        return `${params.row.role.show_name}`;
      },
    },
    {
      field: "position",
      headerName: "Position",
      type: "string",
      width: 180,
    },
    {
      field: "email",
      headerName: "Email",
      type: "string",
      width: 220,
    },
    {
      field: "address",
      headerName: "Address",
      type: "string",
      width: 200,
      hide: true,
    },
    {
      field: "phone",
      headerName: "Phone",
      type: "string",
      width: 150,
      hide: true,
    },
  ];

  return (
    <NTSTable
      onView={(id) => openModal("view", "user", id)}
      onEdit={(id) => openModal("edit", "user", id)}
      onRemove={(id) => openModal("delete", "user", id)}
      data={users}
      cols={cols}
      loading={loading}
      actions={true}
    />
  );
};

export default AllUsers;
