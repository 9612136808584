import {
  CheckOutlined,
  ClearOutlined,
  Close,
  Language,
} from "@mui/icons-material";
import {
  Button,
  DialogContentText,
  InputAdornment,
  TextField,
} from "@mui/material";
import { useCallback, useEffect } from "react";
import { useCountries } from "../../context/country-context";
import { useModal } from "../../context/modal-context";
import useInput from "../../hooks/use-input";
import { getTitleForModal } from "../../services/helpers";
import NTSModal from "../UI/NTSModal";
import NTSModalFooter from "../UI/NTSModalFooter";

const CountryModal = () => {
  const { modalState, closeModal } = useModal();
  const { countries, addCountry, editCountry, removeCountry } = useCountries();

  const {
    value: countryNameValue,
    isValid: countryNameIsValid,
    hasError: countryNameHasError,
    valueChangeHandler: countryNameChangeHandler,
    valueBlurHandler: countryNameBlurHandler,
    valueResetHandler: countryNameResetHandler,
    setValueHandler: setCountryNameHandler,
  } = useInput((value) => value.trim().length > 3);

  const resetForm = useCallback(() => {
    countryNameResetHandler();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    resetForm();
    if (
      !modalState.type ||
      modalState.type === "add" ||
      modalState.model !== "country"
    )
      return;

    const country = countries.find((country) => country.id === modalState.id);
    setCountryNameHandler(country?.show_name);
    // eslint-disable-next-line
  }, [modalState]);

  const submitHandler = (event) => {
    event.preventDefault();
    closeModal();
    if (modalState.type === "add") {
      addCountry(countryNameValue);
    }
    if (modalState.type === "edit") {
      editCountry(modalState.id, { show_name: countryNameValue });
    }
    if (modalState.type === "delete") {
      removeCountry(modalState.id);
    }
    resetForm();
  };

  let title = "";
  let buttons = (
    <>
      <Button onClick={closeModal} endIcon={<ClearOutlined />} color="error">
        Cancel
      </Button>
      <Button
        onClick={submitHandler}
        disabled={!countryNameIsValid}
        endIcon={<CheckOutlined />}
        color="success"
      >
        Submit
      </Button>
    </>
  );

  if (modalState.open && modalState.type) {
    title = getTitleForModal(modalState.type);
  }
  if (modalState.type === "view") {
    buttons = (
      <Button onClick={closeModal} endIcon={<Close />} color="primary">
        Close
      </Button>
    );
  }

  return (
    modalState.open &&
    modalState.model === "country" && (
      <NTSModal title={`${title} COUNTRY`} maxWidth="xs">
        {modalState.type === "delete" ? (
          <DialogContentText>{`Are you sure that you want to remove country ${countryNameValue}?`}</DialogContentText>
        ) : (
          <TextField
            margin="normal"
            required
            fullWidth
            id="name"
            label="Country name"
            name="name"
            disabled={modalState.type === "view"}
            value={countryNameValue}
            onChange={countryNameChangeHandler}
            onBlur={countryNameBlurHandler}
            error={countryNameHasError}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Language />
                </InputAdornment>
              ),
            }}
          />
        )}
        <NTSModalFooter
          sx={{
            display: "flex",
            justifyContent: "end",
            marginY: 2,
          }}
        >
          {buttons}
        </NTSModalFooter>
      </NTSModal>
    )
  );
};

export default CountryModal;
