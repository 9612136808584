import { useModal } from "../../context/modal-context";
import { useParts } from "../../context/part-context";
import NTSTable from "../UI/NTSTable";

const AllPartTypes = () => {
  const { partTypes, partTypesLoading } = useParts();
  const { openModal } = useModal();

  const cols = [
    { field: "name", headerName: "Name", type: "string", width: 150 },
  ];

  return (
    <NTSTable
      onEdit={(id) => openModal("edit", "partType", id)}
      onRemove={(id) => openModal("delete", "partType", id)}
      data={partTypes}
      cols={cols}
      loading={partTypesLoading}
      actions={true}
    />
  );
};

export default AllPartTypes;
