import { useModal } from "../../context/modal-context";
import { useParts } from "../../context/part-context";
import NTSTable from "../UI/NTSTable";

const AllParts = () => {
  const { parts, partsLoading } = useParts();
  const { openModal } = useModal();

  const cols = [
    { field: "name", headerName: "Name", type: "string", width: 200 },
    {
      field: "type",
      headerName: "Type",
      type: "string",
      width: 150,
      valueGetter: (params) => {
        return `${params.row.part_type.name}`;
      },
    },
  ];

  return (
    <NTSTable
      onEdit={(id) => openModal("edit", "part", id)}
      onRemove={(id) => openModal("delete", "part", id)}
      data={parts}
      cols={cols}
      loading={partsLoading}
      actions={true}
    />
  );
};

export default AllParts;
