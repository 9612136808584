const httpReducer = (state, action) => {
  switch (action.type) {
    case "CLEAR_DATA":
      return {
        data: [],
        loading: false,
      };
    case "START_LOAD":
      return { ...state, loading: true };
    case "ADD_DATA":
      return { data: action.data, loading: false };
    case "ADD_ITEM":
      return { data: [action.item, ...state.data], loading: false };
    case "REMOVE_ITEM":
      return {
        data: state.data.filter((item) => item.id !== action.id),
        loading: false,
      };
    case "EDIT_ITEM":
      return {
        data: state.data.map((item) => {
          if (item.id === action.id)
            return { ...item, id: action.id, ...action.data };
          return item;
        }),
        loading: false,
      };
    case "END_LOAD":
      return { data: state.data, loading: false };
    default:
      return {
        data: [],
        loading: false,
      };
  }
};

export default httpReducer;
