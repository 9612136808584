import React from "react"
import SignInForm from "../components/User/SignInForm"
import Copyright from "../components/Copyright/Copyright"

import { Grid, Paper } from "@mui/material"

const SignIn = () => {
  return (
    <Grid container component="main" sx={{ height: '100vh' }}>
      <Grid
        item xs={false} sm={4} md={7}
        sx={{
          backgroundImage: "url(welcome.jpg)",
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <SignInForm />
        <Copyright />
      </Grid>
    </Grid>
  )
}

export default SignIn