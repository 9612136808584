import {
  CheckOutlined,
  ClearOutlined,
  Close,
  TripOriginOutlined,
} from "@mui/icons-material";
import {
  Autocomplete,
  Button,
  DialogContentText,
  FormControl,
  Grid,
  InputAdornment,
  TextField,
} from "@mui/material";
import { useCallback, useEffect } from "react";
import { useDevices } from "../../context/device-context";
import { useModal } from "../../context/modal-context";
import useInput from "../../hooks/use-input";
import { getTitleForModal } from "../../services/helpers";
import NTSModal from "../UI/NTSModal";
import NTSModalFooter from "../UI/NTSModalFooter";

const DeviceModal = () => {
  const { modalState, closeModal } = useModal();
  const {
    devices,
    deviceTypes,
    deviceTypesLoading,
    loadDeviceTypes,
    addDevice,
    editDevice,
    removeDevice,
  } = useDevices();

  const {
    value: deviceTypeValue,
    isValid: deviceTypeIsValid,
    hasError: deviceTypeHasError,
    valueBlurHandler: deviceTypeBlurHandler,
    valueResetHandler: deviceTypeResetHandler,
    setValueHandler: setDeviceTypeHandler,
  } = useInput((value) => value !== "");
  const {
    value: deviceNameValue,
    isValid: deviceNameIsValid,
    hasError: deviceNameHasError,
    valueChangeHandler: deviceNameChangeHandler,
    valueBlurHandler: deviceNameBlurHandler,
    valueResetHandler: deviceNameResetHandler,
    setValueHandler: setDeviceNameHandler,
  } = useInput((value) => value.trim().length > 3);

  const resetForm = useCallback(() => {
    deviceTypeResetHandler();
    deviceNameResetHandler();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    resetForm();
    if (!modalState.type || modalState.model !== "device") return;
    loadDeviceTypes();
    if (modalState.type === "add") return;

    const device = devices.find((device) => device.id === modalState.id);
    setDeviceNameHandler(device?.name);
    setDeviceTypeHandler(device?.device_type.id);
    // eslint-disable-next-line
  }, [modalState]);

  const submitHandler = (event) => {
    event.preventDefault();
    closeModal();
    const deviceObj = {
      name: deviceNameValue,
      device_type_id: deviceTypeValue,
    };
    if (modalState.type === "add") {
      addDevice(deviceObj);
    }
    if (modalState.type === "edit") {
      editDevice(modalState.id, deviceObj);
    }
    if (modalState.type === "delete") {
      removeDevice(modalState.id);
    }
    resetForm();
  };

  let title = "";
  let buttons = (
    <>
      <Button onClick={closeModal} endIcon={<ClearOutlined />} color="error">
        Cancel
      </Button>
      <Button
        onClick={submitHandler}
        disabled={!deviceNameIsValid || !deviceTypeIsValid}
        endIcon={<CheckOutlined />}
        color="success"
      >
        Submit
      </Button>
    </>
  );

  if (modalState.open && modalState.type) {
    title = getTitleForModal(modalState.type);
  }
  if (modalState.type === "view") {
    buttons = (
      <Button onClick={closeModal} endIcon={<Close />} color="primary">
        Close
      </Button>
    );
  }

  return (
    modalState.open &&
    modalState.model === "device" && (
      <NTSModal
        title={`${title} DEVICE`}
        maxWidth="sm"
        loading={deviceTypesLoading}
        loadingSx={{ marginBottom: 5 }}
      >
        {modalState.type === "delete" ? (
          <DialogContentText>{`Are you sure that you want to remove device ${deviceNameValue}?`}</DialogContentText>
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth sx={{ mt: 2 }}>
                <Autocomplete
                  id="device_type"
                  options={deviceTypes}
                  value={
                    deviceTypes.find(
                      (device) => device.id === deviceTypeValue
                    ) || null
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={deviceTypeHasError}
                      label="Device Type *"
                    />
                  )}
                  getOptionLabel={(option) => option.show_name ?? option.name}
                  onChange={(event, newOption) =>
                    setDeviceTypeHandler(newOption?.id ?? null)
                  }
                  onClose={deviceTypeBlurHandler}
                  disabled={modalState.type === "view"}
                  required
                  disableClearable
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="name"
                label="Device name"
                name="name"
                disabled={modalState.type === "view"}
                value={deviceNameValue}
                onChange={deviceNameChangeHandler}
                onBlur={deviceNameBlurHandler}
                error={deviceNameHasError}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <TripOriginOutlined />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        )}
        <NTSModalFooter
          sx={{
            display: "flex",
            justifyContent: "end",
            marginY: 2,
          }}
        >
          {buttons}
        </NTSModalFooter>
      </NTSModal>
    )
  );
};

export default DeviceModal;
