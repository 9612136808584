import { useCompanies } from "../../context/company-context";
import { useModal } from "../../context/modal-context";
import NTSTable from "../UI/NTSTable";

const AllCompanies = () => {
  const { companies, loading } = useCompanies();
  const { openModal } = useModal();

  const cols = [
    {
      field: "country",
      headerName: "Country",
      type: "string",
      width: 150,
      valueGetter: (params) => {
        return params.row.country.show_name;
      },
    },
    {
      field: "company_name",
      headerName: "Company Name",
      type: "string",
      width: 200,
    },
    {
      field: "groupation_name",
      headerName: "Groupation Name",
      type: "string",
      width: 200,
    },
  ];

  return (
    <NTSTable
      onView={(id) => openModal("view", "company", id)}
      onEdit={(id) => openModal("edit", "company", id)}
      onRemove={(id) => openModal("delete", "company", id)}
      data={companies}
      cols={cols}
      loading={loading}
      actions={true}
    />
  );
};

export default AllCompanies;
