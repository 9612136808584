import { useCallback, createContext, useContext, useReducer } from "react";
import httpReducer from "../reducers/http-reducer";
import {
  createCountry,
  deleteCountry,
  getCountries,
  updateCountry,
} from "../services/api-service";
import { useSnackbar } from "./snackbar-context";

export const CountryContext = createContext({
  countries: [],
  loading: false,
  loadCountries: () => {},
  addCountry: (name) => {},
  editCountry: (id, countryObj) => {},
  removeCountry: (id) => {},
});

export const CountryContextProvider = (props) => {
  const { showSnackbar, resetSnackbar } = useSnackbar();
  const [state, dispatch] = useReducer(httpReducer, {
    data: [],
    loading: false,
  });

  const loadCountries = useCallback(async () => {
    dispatch({ type: "START_LOAD" });
    resetSnackbar();
    try {
      const response = await getCountries();
      const countriesData = response.data.data;
      dispatch({ type: "ADD_DATA", data: countriesData });
    } catch (error) {
      dispatch({ type: "END_LOAD" });
      const errorMessage = error.response?.data?.message || error.message;
      showSnackbar(errorMessage, "error", "red");
    }
    // eslint-disable-next-line
  }, []);

  const addCountry = async (name) => {
    dispatch({ type: "START_LOAD" });
    resetSnackbar();
    try {
      const response = await createCountry(name);
      const country = response.data.data;
      dispatch({ type: "ADD_ITEM", item: country });
      const message = response.data.message;
      showSnackbar(message, "success", "green");
    } catch (error) {
      dispatch({ type: "END_LOAD" });
      const errorMessage = error.response?.data?.message || error.message;
      showSnackbar(errorMessage, "error", "red");
    }
  };

  const editCountry = async (id, countryObj) => {
    dispatch({ type: "START_LOAD" });
    resetSnackbar();
    try {
      const response = await updateCountry(id, countryObj);
      const country = response.data.data;
      dispatch({ type: "EDIT_ITEM", id: id, data: country });
      const message = response.data.message;
      showSnackbar(message, "success", "green");
    } catch (error) {
      dispatch({ type: "END_LOAD" });
      const errorMessage = error.response?.data?.message || error.message;
      showSnackbar(errorMessage, "error", "red");
    }
  };

  const removeCountry = async (id) => {
    dispatch({ type: "START_LOAD" });
    resetSnackbar();
    try {
      const response = await deleteCountry(id);
      dispatch({ type: "REMOVE_ITEM", id: id });
      const message = response.data.message;
      showSnackbar(message, "success", "green");
    } catch (error) {
      dispatch({ type: "END_LOAD" });
      const errorMessage = error.response?.data?.message || error.message;
      showSnackbar(errorMessage, "error", "red");
    }
  };

  const ctxValue = {
    countries: state.data,
    loading: state.loading,
    loadCountries,
    addCountry,
    editCountry,
    removeCountry,
  };

  return (
    <CountryContext.Provider value={ctxValue}>
      {props.children}
    </CountryContext.Provider>
  );
};

export const useCountries = () => useContext(CountryContext);
