import { Container } from "@mui/material";
import { useEffect } from "react";
import AllSpareParts from "../components/SparePart/AllSpareParts";
import SparePartModal from "../components/SparePart/SparePartModal";
import CRUDHeader from "../components/UI/CRUDHeader";
import { useModal } from "../context/modal-context";
import { useSpareParts } from "../context/spare-part-context";

const SpareParts = () => {
  const { loadSpareParts } = useSpareParts();
  const { openModal } = useModal();

  useEffect(() => {
    loadSpareParts();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Container maxWidth="md" sx={{ paddingBottom: "16px" }}>
        <CRUDHeader
          title="Spare Parts"
          onAddNew={() => openModal("add", "sparePart")}
          onRefresh={() => loadSpareParts()}
        />
        <AllSpareParts />
      </Container>
      <SparePartModal />
    </>
  );
};

export default SpareParts;
