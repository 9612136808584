import { createContext, useContext, useState } from "react";

export const ModalContext = createContext({
  modalState: {
    open: false,
    model: null,
    type: null,
    id: null,
    additional: null,
  },
  openModal: (type, model, id = null, additional = null) => {},
  closeModal: () => {},
});

export const ModalContextProvider = (props) => {
  const [modalState, setModalState] = useState({
    open: false,
    type: null,
    id: null,
    additional: null,
  });

  const openModal = (type, model, id = null, additional = null) => {
    setModalState({
      open: true,
      model,
      type,
      id,
      additional,
    });
  };

  const closeModal = () => {
    setModalState({
      open: false,
      model: null,
      type: null,
      id: null,
      additional: null,
    });
  };

  const ctxValue = {
    modalState,
    openModal,
    closeModal,
  };

  return (
    <ModalContext.Provider value={ctxValue}>
      {props.children}
    </ModalContext.Provider>
  );
};

export const useModal = () => useContext(ModalContext);
