import { Box, LinearProgress } from "@mui/material";

const LinearLoad = (props) => {
  return (
    <Box {...props}>
      <LinearProgress />
    </Box>
  );
};

export default LinearLoad;
