import { createContext, useCallback, useContext, useReducer } from "react";
import httpReducer from "../reducers/http-reducer";
import {
  attachSparePartsToWarehouse,
  createLocation,
  deleteLocation,
  getLocations,
  getLocationsByFilter,
  getLocationTypes,
  getWarehouses,
  transferSpareParts,
  updateLocation,
} from "../services/api-service";
import { useSnackbar } from "./snackbar-context";

export const LocationContext = createContext({
  locations: [],
  warehouses: [],
  locationTypes: [],
  locationsLoading: false,
  warehousesLoading: false,
  locationTypesLoading: false,
  loadLocations: () => {},
  loadWarehouses: () => {},
  searchLocations: (searchObj) => {},
  loadLocationTypes: () => {},
  addSparePartsToWarehouse: (warehouseId, assignObj) => {},
  transferSparePartsBetweenWarehouses: (assignObj) => {},
  addLocation: (locationObj) => {},
  editLocation: (id, locationObj) => {},
  removeLocation: (id) => {},
  clearLocationsData: () => {},
});

export const LocationContextProvider = (props) => {
  const { showSnackbar, resetSnackbar } = useSnackbar();
  const [locationsState, locationsDispatch] = useReducer(httpReducer, {
    data: [],
    loading: false,
  });
  const [warehousesState, warehousesDispatch] = useReducer(httpReducer, {
    data: [],
    loading: false,
  });
  const [locationTypesState, locationTypesDispatch] = useReducer(httpReducer, {
    data: [],
    loading: false,
  });

  const loadLocations = useCallback(async () => {
    locationsDispatch({ type: "START_LOAD" });
    resetSnackbar();
    try {
      const response = await getLocations();
      const locationsData = response.data.data;
      locationsDispatch({ type: "ADD_DATA", data: locationsData });
    } catch (error) {
      locationsDispatch({ type: "END_LOAD" });
      const errorMessage = error.response?.data?.message || error.message;
      showSnackbar(errorMessage, "error", "red");
    }
    // eslint-disable-next-line
  }, []);

  const loadWarehouses = useCallback(async () => {
    warehousesDispatch({ type: "START_LOAD" });
    resetSnackbar();
    try {
      const response = await getWarehouses();
      const warehousesData = response.data.data;
      warehousesDispatch({ type: "ADD_DATA", data: warehousesData });
    } catch (error) {
      warehousesDispatch({ type: "END_LOAD" });
      const errorMessage = error.response?.data?.message || error.message;
      showSnackbar(errorMessage, "error", "red");
    }

    // eslint-disable-next-line
  }, []);

  const addSparePartsToWarehouse = async (warehouseId, assignObj) => {
    warehousesDispatch({ type: "START_LOAD" });
    resetSnackbar();
    try {
      const response = await attachSparePartsToWarehouse(
        warehouseId,
        assignObj
      );
      const warehouse = response.data.data;
      warehousesDispatch({
        type: "EDIT_ITEM",
        id: warehouseId,
        data: warehouse,
      });
      const message = response.data.message;
      showSnackbar(message, "success", "green");
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      showSnackbar(errorMessage, "error", "red");
    }
  };

  const transferSparePartsBetweenWarehouses = async (assignObj) => {
    warehousesDispatch({ type: "START_LOAD" });
    resetSnackbar();
    try {
      const response = await transferSpareParts(assignObj);
      warehousesDispatch({ type: "CLEAR_DATA" });
      const warehousesData = response.data.data;
      warehousesDispatch({ type: "ADD_DATA", data: warehousesData });
      const message = response.data.message;
      showSnackbar(message, "success", "green");
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      showSnackbar(errorMessage, "error", "red");
    }
  };

  const searchLocations = async (searchObj) => {
    locationsDispatch({ type: "START_LOAD" });
    resetSnackbar();
    try {
      const response = await getLocationsByFilter(searchObj);
      const locationsData = response.data.data;
      if (locationsData.length < 1)
        showSnackbar(
          "No results found according to search criteria",
          "warning",
          "orange"
        );
      locationsDispatch({ type: "ADD_DATA", data: locationsData });
    } catch (error) {
      locationsDispatch({ type: "END_LOAD" });
      const errorMessage = error.response?.data?.message || error.message;
      showSnackbar(errorMessage, "error", "red");
    }
  };

  const loadLocationTypes = useCallback(async () => {
    locationTypesDispatch({ type: "START_LOAD" });
    resetSnackbar();
    try {
      const response = await getLocationTypes();
      const locationTypesData = response.data.data;
      locationTypesDispatch({ type: "ADD_DATA", data: locationTypesData });
    } catch (error) {
      locationTypesDispatch({ type: "END_LOAD" });
      const errorMessage = error.response?.data?.message || error.message;
      showSnackbar(errorMessage, "error", "red");
    }
    // eslint-disable-next-line
  }, []);

  const addLocation = async (locationObj) => {
    locationsDispatch({ type: "START_LOAD" });
    resetSnackbar();
    try {
      const response = await createLocation(locationObj);
      locationsDispatch({ type: "END_LOAD" });
      const message = response.data.message;
      showSnackbar(message, "success", "green");
    } catch (error) {
      locationsDispatch({ type: "END_LOAD" });
      const errorMessage = error.response?.data?.message || error.message;
      showSnackbar(errorMessage, "error", "red");
    }
  };

  const editLocation = async (id, locationObj) => {
    locationsDispatch({ type: "START_LOAD" });
    resetSnackbar();
    try {
      const response = await updateLocation(id, locationObj);
      const location = response.data.data;
      locationsDispatch({ type: "EDIT_ITEM", id: id, data: location });
      const message = response.data.message;
      showSnackbar(message, "success", "green");
    } catch (error) {
      locationsDispatch({ type: "END_LOAD" });
      const errorMessage = error.response?.data?.message || error.message;
      showSnackbar(errorMessage, "error", "red");
    }
  };

  const removeLocation = async (id) => {
    locationsDispatch({ type: "START_LOAD" });
    resetSnackbar();
    try {
      const response = await deleteLocation(id);
      locationsDispatch({ type: "REMOVE_ITEM", id: id });
      const message = response.data.message;
      showSnackbar(message, "success", "green");
    } catch (error) {
      locationsDispatch({ type: "END_LOAD" });
      const errorMessage = error.response?.data?.message || error.message;
      showSnackbar(errorMessage, "error", "red");
    }
  };

  const clearLocationsData = useCallback(() => {
    locationsDispatch({ type: "CLEAR_DATA" });
  }, []);

  const ctxValue = {
    locations: locationsState.data,
    warehouses: warehousesState.data,
    locationTypes: locationTypesState.data,
    locationsLoading: locationsState.loading,
    warehousesLoading: warehousesState.loading,
    locationTypesLoading: locationTypesState.loading,
    loadLocations,
    loadWarehouses,
    searchLocations,
    loadLocationTypes,
    addSparePartsToWarehouse,
    transferSparePartsBetweenWarehouses,
    addLocation,
    editLocation,
    removeLocation,
    clearLocationsData,
  };

  return (
    <LocationContext.Provider value={ctxValue}>
      {props.children}
    </LocationContext.Provider>
  );
};

export const useLocations = () => useContext(LocationContext);
