import { Box } from "@mui/material";

const NTSModalFooter = (props) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "end",
        marginY: 2,
      }}
    >
      {props.children}
    </Box>
  );
};

export default NTSModalFooter;
