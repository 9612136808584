import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

import ThemeHandler from './components/Theme/ThemeHandler';

import { AuthContextProvider } from './context/auth-context';
import { SnackbarContextProvider } from './context/snackbar-context';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ThemeHandler>
      <SnackbarContextProvider>
        <AuthContextProvider>
          <App />
        </AuthContextProvider>
      </SnackbarContextProvider>
    </ThemeHandler>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
