import { Box } from "@mui/material"
import { useEffect, useState } from "react";
import useCheckIsMobile from "../../hooks/use-check-is-mobile";
import Main from "./Main";
import NavBar from "./NavBar/NavBar"
import SideBar from "./SideBar";

const Layout = (props) => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const isMobile = useCheckIsMobile();

  useEffect(() => {
    setOpenDrawer(isMobile ? false : true);
  }, [isMobile])

  const openDrawerHandler = () => {
    setOpenDrawer(true);
  };
  const closeDrawerHandler = () => {
    setOpenDrawer(false);
  };

  return (
    <Box>
      <NavBar open={openDrawer} openDrawerHandler={openDrawerHandler} />
      <SideBar open={openDrawer} closeDrawerHandler={closeDrawerHandler} />
      <Main open={openDrawer}>{props.children}</Main>
    </Box>
  )
}

export default Layout
