import { createContext, useCallback, useContext, useReducer } from "react";
import httpReducer from "../reducers/http-reducer";
import {
  attachLocationToGroup,
  attachTechnicianToGroup,
  createGroup,
  deleteGroup,
  detachLocationFromGroup,
  detachTechnicianFromGroup,
  getGroups,
  updateGroup,
} from "../services/api-service";
import { useSnackbar } from "./snackbar-context";

export const GroupContext = createContext({
  groups: [],
  loading: false,
  loadGroups: () => {},
  addGroup: (groupObj) => {},
  editGroup: (id, groupObj) => {},
  removeGroup: (id) => {},
  addLocationToGroup: (id, locationObj) => {},
  removeLocationFromGroup: (id, locationObj) => {},
  addTechnicianToGroup: (id, technicianObj) => {},
  removeTechnicianFromGroup: (id, technicianObj) => {},
});

export const GroupContextProvider = (props) => {
  const { showSnackbar, resetSnackbar } = useSnackbar();
  const [groupsState, groupsDispatch] = useReducer(httpReducer, {
    data: [],
    loading: false,
  });

  const loadGroups = useCallback(async () => {
    groupsDispatch({ type: "START_LOAD" });
    resetSnackbar();
    try {
      const response = await getGroups();
      const groupsData = response.data.data;
      groupsDispatch({ type: "ADD_DATA", data: groupsData });
    } catch (error) {
      groupsDispatch({ type: "END_LOAD" });
      const errorMessage = error.response?.data?.message || error.message;
      showSnackbar(errorMessage, "error", "red");
    }
    // eslint-disable-next-line
  }, []);

  const addGroup = async (groupObj) => {
    groupsDispatch({ type: "START_LOAD" });
    resetSnackbar();
    try {
      const response = await createGroup(groupObj);
      const group = response.data.data;
      groupsDispatch({ type: "ADD_ITEM", item: group });
      const message = response.data.message;
      showSnackbar(message, "success", "green");
    } catch (error) {
      groupsDispatch({ type: "END_LOAD" });
      const errorMessage = error.response?.data?.message || error.message;
      showSnackbar(errorMessage, "error", "red");
    }
  };

  const editGroup = async (id, groupObj) => {
    groupsDispatch({ type: "START_LOAD" });
    resetSnackbar();
    try {
      const response = await updateGroup(id, groupObj);
      const group = response.data.data;
      groupsDispatch({ type: "EDIT_ITEM", id: id, data: group });
      const message = response.data.message;
      showSnackbar(message, "success", "green");
    } catch (error) {
      groupsDispatch({ type: "END_LOAD" });
      const errorMessage = error.response?.data?.message || error.message;
      showSnackbar(errorMessage, "error", "red");
    }
  };

  const removeGroup = async (id) => {
    groupsDispatch({ type: "START_LOAD" });
    resetSnackbar();
    try {
      const response = await deleteGroup(id);
      groupsDispatch({ type: "REMOVE_ITEM", id: id });
      const message = response.data.message;
      showSnackbar(message, "success", "green");
    } catch (error) {
      groupsDispatch({ type: "END_LOAD" });
      const errorMessage = error.response?.data?.message || error.message;
      showSnackbar(errorMessage, "error", "red");
    }
  };

  const addLocationToGroup = async (id, locationObj) => {
    groupsDispatch({ type: "START_LOAD" });
    resetSnackbar();
    try {
      const response = await attachLocationToGroup(id, locationObj);
      const group = response.data.data;
      groupsDispatch({ type: "EDIT_ITEM", id: id, data: group });
      const message = response.data.message;
      showSnackbar(message, "success", "green");
    } catch (error) {
      groupsDispatch({ type: "END_LOAD" });
      const errorMessage = error.response?.data?.message || error.message;
      showSnackbar(errorMessage, "error", "red");
    }
  };

  const removeLocationFromGroup = async (id, locationObj) => {
    groupsDispatch({ type: "START_LOAD" });
    resetSnackbar();
    try {
      const response = await detachLocationFromGroup(id, locationObj);
      const group = response.data.data;
      groupsDispatch({ type: "EDIT_ITEM", id: id, data: group });
      const message = response.data.message;
      showSnackbar(message, "success", "green");
    } catch (error) {
      groupsDispatch({ type: "END_LOAD" });
      const errorMessage = error.response?.data?.message || error.message;
      showSnackbar(errorMessage, "error", "red");
    }
  };

  const addTechnicianToGroup = async (id, technicianObj) => {
    groupsDispatch({ type: "START_LOAD" });
    resetSnackbar();
    try {
      const response = await attachTechnicianToGroup(id, technicianObj);
      const group = response.data.data;
      groupsDispatch({ type: "EDIT_ITEM", id: id, data: group });
      const message = response.data.message;
      showSnackbar(message, "success", "green");
    } catch (error) {
      groupsDispatch({ type: "END_LOAD" });
      const errorMessage = error.response?.data?.message || error.message;
      showSnackbar(errorMessage, "error", "red");
    }
  };

  const removeTechnicianFromGroup = async (id, technicianObj) => {
    groupsDispatch({ type: "START_LOAD" });
    resetSnackbar();
    try {
      const response = await detachTechnicianFromGroup(id, technicianObj);
      const group = response.data.data;
      groupsDispatch({ type: "EDIT_ITEM", id: id, data: group });
      const message = response.data.message;
      showSnackbar(message, "success", "green");
    } catch (error) {
      groupsDispatch({ type: "END_LOAD" });
      const errorMessage = error.response?.data?.message || error.message;
      showSnackbar(errorMessage, "error", "red");
    }
  };

  const ctxValue = {
    groups: groupsState.data,
    loading: groupsState.loading,
    loadGroups,
    addGroup,
    editGroup,
    removeGroup,
    addLocationToGroup,
    removeLocationFromGroup,
    addTechnicianToGroup,
    removeTechnicianFromGroup,
  };

  return (
    <GroupContext.Provider value={ctxValue}>
      {props.children}
    </GroupContext.Provider>
  );
};

export const useGroups = () => useContext(GroupContext);
