import { Close, ExpandMore, GroupWorkOutlined } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  Paper,
  Stack,
  styled,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useCallback, useState, useMemo } from "react";
import { useModal } from "../../context/modal-context";
import { useServices } from "../../context/service-context";
import { useSnackbar } from "../../context/snackbar-context";
import { getClosedServicesForMachine } from "../../services/api-service";
import {
  formatDateForView,
  getReadableDateWithHours,
} from "../../services/helpers";
import NTSExpandableTableCell from "../UI/NTSExpandableTableCell";
import NTSModal from "../UI/NTSModal";
import NTSTable from "../UI/NTSTable";

const FlexListItem = styled(ListItem)(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  flexDirection: "row",
  justifyContent: "space-between",
  paddingY: 0,
}));

const DetailsTypography = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
}));

const MachineProfileModal = () => {
  const theme = useTheme();
  const { modalState, closeModal, openModal } = useModal();
  const { showSnackbar } = useSnackbar();
  const { services } = useServices();
  const [serviceDetailsLoading, setServiceDetailsLoading] = useState(false);
  const [serviceDetails, setServiceDetails] = useState(null);
  const [machine, setMachine] = useState(null);
  const [changedParts, setChangedParts] = useState([]);

  const cols = useMemo(() => {
    return [
      {
        field: "created_at",
        headerName: "Error Date",
        type: "string",
        width: 150,
        valueGetter: (params) => {
          return formatDateForView(params.row.created_at);
        },
      },
      {
        field: "error_description",
        headerName: "Error Description",
        type: "string",
        width: 200,
        renderCell: NTSExpandableTableCell,
      },
      {
        field: "fix_date",
        headerName: "Fix Date",
        type: "string",
        width: 150,
        valueGetter: (params) => {
          return formatDateForView(params.row.fix_date);
        },
      },
      {
        field: "fix_description",
        headerName: "Fix Description",
        type: "string",
        width: 200,
        renderCell: NTSExpandableTableCell,
      },
      {
        field: "parts_details",
        headerName: "Used Parts",
        type: "string",
        align: "center",
        width: 160,
        renderCell: (params) => {
          const parts_details = JSON.parse(params.value);
          return parts_details?.returned_parts?.length > 0 ? (
            <IconButton
              onClick={() =>
                openModal("view", "parts-details", null, {
                  returned_parts: parts_details.returned_parts,
                })
              }
            >
              <GroupWorkOutlined color="success" />
            </IconButton>
          ) : (
            false
          );
        },
      },
    ];
    // eslint-disable-next-line
  }, []);

  const resetForm = useCallback(() => {
    setServiceDetailsLoading(false);
    setServiceDetails(null);
    setChangedParts([]);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    resetForm();
    if (!modalState.type || modalState.model !== "machine-service-profile")
      return;

    const service = services.find((service) => service.id === modalState.id);
    (async () => {
      setServiceDetailsLoading(true);
      try {
        const response = await getClosedServicesForMachine({
          location_id: service?.location?.id,
          machine_id: service?.machine?.id,
          machine_type: service?.machine_type,
        });
        const allServices = response.data.data;
        const changedParts = allServices
          .map((service) => {
            const parts_details = service?.work_report?.parts_details;
            if (!!parts_details)
              return JSON.parse(parts_details)?.changed_parts;
            return false;
          })
          .flat(1)
          .filter((item) => !!item)
          .map((details) => {
            const _details = details?.show_name?.split(" | ");
            return {
              partName: _details[1],
              quantity: _details[2],
            };
          });
        setChangedParts(changedParts);
        setServiceDetails(allServices);
        setMachine(allServices[0]?.machine);
      } catch (error) {
        const errorMessage = error.response?.data?.message || error.message;
        showSnackbar(errorMessage, "error", "red");
      }
      setServiceDetailsLoading(false);
    })();

    // eslint-disable-next-line
  }, [modalState]);

  return (
    modalState.open &&
    modalState.model === "machine-service-profile" &&
    serviceDetails && (
      <NTSModal
        title="Machine Profile"
        maxWidth="xl"
        loading={serviceDetailsLoading}
        loadingSx={{ marginBottom: 5 }}
        actions={
          <>
            <Button onClick={closeModal} endIcon={<Close />} color="primary">
              Close
            </Button>
          </>
        }
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Paper sx={{ width: "100%", maxHeight: "281px", overflow: "auto" }}>
              <Box
                sx={{
                  height: "3px",
                  backgroundColor: theme.palette.primary.main,
                }}
              />
              <List disablePadding>
                <ListItem sx={{ paddingY: 0 }}>
                  <Typography variant="h6">Basic Info</Typography>
                </ListItem>
                <Divider />
                <FlexListItem>
                  <Typography variant="body1">Cabinet: </Typography>
                  <DetailsTypography variant="body1">
                    {machine?.cabinet?.name ?? ""}
                  </DetailsTypography>
                </FlexListItem>
                <Divider />
                <FlexListItem>
                  <Typography variant="body1">Serial Number: </Typography>
                  <DetailsTypography variant="body1">
                    {machine?.serial_number ?? ""}
                  </DetailsTypography>
                </FlexListItem>
                <Divider />
                <FlexListItem>
                  <Typography variant="body1">Manufacturer Date: </Typography>
                  <DetailsTypography variant="body1">
                    {machine?.manufacturer_date
                      ? machine.manufacturer_date
                      : ""}
                  </DetailsTypography>
                </FlexListItem>
                <Divider />
                <FlexListItem>
                  <Typography variant="body1">Groupation: </Typography>
                  <DetailsTypography variant="body1">
                    {serviceDetails[0].location.company.groupation_name}
                  </DetailsTypography>
                </FlexListItem>
                <Divider />
                <Accordion
                  disableGutters
                  elevation={0}
                  sx={{ "&:before": { display: "none" } }}
                >
                  <FlexListItem
                    component={AccordionSummary}
                    expandIcon={<ExpandMore />}
                    aria-controls="location"
                    id="location"
                    sx={{
                      minHeight: 0,
                      padding: "8px 16px",
                      margin: 0,
                      "& .MuiAccordionSummary-content": {
                        margin: 0,
                      },
                    }}
                  >
                    <Typography variant="body1" sx={{ flex: 1 }}>
                      Location:
                    </Typography>
                    <DetailsTypography variant="body1">
                      {serviceDetails[0].location.name}
                    </DetailsTypography>
                  </FlexListItem>
                  <AccordionDetails
                    sx={{ margin: 0, padding: "0 16px 8px 16px" }}
                  >
                    {machine?.machine_history?.filter(
                      (item) => !!item.location_name
                    )?.length > 0 ? (
                      machine.machine_history
                        .filter((item) => !!item.location_name)
                        .map((item, index) => {
                          return (
                            <Stack
                              key={index}
                              direction="row"
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              <Typography variant="body1" color="text.disabled">
                                {item.location_name}
                              </Typography>
                              <Typography variant="body1" color="text.disabled">
                                {getReadableDateWithHours(item.created_at)}
                              </Typography>
                            </Stack>
                          );
                        })
                    ) : (
                      <Typography variant="body1" color="text.disabled">
                        No Location Changes
                      </Typography>
                    )}
                  </AccordionDetails>
                </Accordion>
                <Divider />
                <FlexListItem>
                  <Typography variant="body1">Number: </Typography>
                  <DetailsTypography variant="body1">
                    {machine?.machine_number ?? ""}
                  </DetailsTypography>
                </FlexListItem>
              </List>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper sx={{ width: "100%", maxHeight: "281px", overflow: "auto" }}>
              <Box
                sx={{
                  height: "3px",
                  backgroundColor: theme.palette.success.main,
                }}
              />
              <List disablePadding>
                <ListItem sx={{ paddingY: 0 }}>
                  <Typography variant="h6">Additional Info</Typography>
                </ListItem>
                <Divider />
                {machine?.parts?.map((part, index) => (
                  <React.Fragment key={index}>
                    {(() => {
                      switch (part.part_type.name) {
                        case "Game":
                        case "Kernel":
                        case "Version":
                          return (
                            <Accordion
                              disableGutters
                              elevation={0}
                              sx={{ "&:before": { display: "none" } }}
                            >
                              <FlexListItem
                                component={AccordionSummary}
                                expandIcon={<ExpandMore />}
                                aria-controls={part.part_type.name}
                                id={part.part_type.name}
                                sx={{
                                  minHeight: "13px",
                                  padding: "8px 16px",
                                  margin: 0,
                                  "& .MuiAccordionSummary-content": {
                                    margin: 0,
                                  },
                                }}
                              >
                                <Typography variant="body1" sx={{ flex: 1 }}>
                                  {part.part_type.name}:
                                </Typography>
                                <DetailsTypography variant="body1">
                                  {part.name}
                                </DetailsTypography>
                              </FlexListItem>
                              <AccordionDetails
                                sx={{ margin: 0, padding: "0 16px 8px 16px" }}
                              >
                                {machine?.machine_history?.filter(
                                  (item) =>
                                    !!item[part.part_type.name.toLowerCase()]
                                )?.length > 0 ? (
                                  machine.machine_history
                                    .filter(
                                      (item) =>
                                        !!item[
                                          part.part_type.name.toLowerCase()
                                        ]
                                    )
                                    .map((item, index) => {
                                      return (
                                        <Stack
                                          key={index}
                                          direction="row"
                                          justifyContent="space-between"
                                          alignItems="center"
                                        >
                                          <Typography
                                            variant="body1"
                                            color="text.disabled"
                                          >
                                            {
                                              item[
                                                part.part_type.name.toLowerCase()
                                              ]
                                            }
                                          </Typography>
                                          <Typography
                                            variant="body1"
                                            color="text.disabled"
                                          >
                                            {getReadableDateWithHours(
                                              item.created_at
                                            )}
                                          </Typography>
                                        </Stack>
                                      );
                                    })
                                ) : (
                                  <Typography
                                    variant="body1"
                                    color="text.disabled"
                                  >
                                    No {part.part_type.name} Changes
                                  </Typography>
                                )}
                              </AccordionDetails>
                            </Accordion>
                          );
                        default:
                          return (
                            <FlexListItem>
                              <Typography variant="body1">
                                {part.part_type.name}:{" "}
                              </Typography>
                              <DetailsTypography variant="body1">
                                {part.name}
                              </DetailsTypography>
                            </FlexListItem>
                          );
                      }
                    })()}

                    <Divider />
                  </React.Fragment>
                ))}
                <Accordion
                  disableGutters
                  elevation={0}
                  sx={{ "&:before": { display: "none" } }}
                >
                  <FlexListItem
                    component={AccordionSummary}
                    expandIcon={<ExpandMore />}
                    aria-controls="ownership"
                    id="ownership"
                    sx={{
                      minHeight: 0,
                      padding: "8px 16px",
                      margin: 0,
                      "& .MuiAccordionSummary-content": {
                        margin: 0,
                      },
                    }}
                  >
                    <Typography variant="body1" sx={{ flex: 1 }}>
                      Ownership:
                    </Typography>
                    <DetailsTypography variant="body1">
                      {machine?.ownership_name}
                    </DetailsTypography>
                  </FlexListItem>
                  <AccordionDetails
                    sx={{ margin: 0, padding: "0 16px 8px 16px" }}
                  >
                    {machine?.machine_history?.filter(
                      (item) => !!item.ownership_name
                    )?.length > 0 ? (
                      machine.machine_history
                        .filter((item) => !!item.ownership_name)
                        .map((item, index) => {
                          return (
                            <Stack
                              key={index}
                              direction="row"
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              <Typography variant="body1" color="text.disabled">
                                {item.ownership_name}
                              </Typography>
                              <Typography variant="body1" color="text.disabled">
                                {getReadableDateWithHours(item.created_at)}
                              </Typography>
                            </Stack>
                          );
                        })
                    ) : (
                      <Typography variant="body1" color="text.disabled">
                        No Ownership Changes
                      </Typography>
                    )}
                  </AccordionDetails>
                </Accordion>
                <Divider />
                <FlexListItem>
                  <Typography variant="body1">Payout Percent:</Typography>
                  <DetailsTypography variant="body1">
                    {machine?.payout_percentage ?? ""}
                  </DetailsTypography>
                </FlexListItem>
                <Divider />
                <FlexListItem>
                  <Typography variant="body1">Warranty:</Typography>
                  <DetailsTypography variant="body1">
                    {machine?.warranty ?? ""}
                  </DetailsTypography>
                </FlexListItem>
              </List>
            </Paper>
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ marginY: "20px" }}>
          <Grid item xs={12} md={7}>
            <Paper
              sx={{
                width: "100%",
                maxHeight: "300px",
                overflow: "auto",
              }}
            >
              <Box
                sx={{
                  height: "3px",
                  backgroundColor: theme.palette.error.main,
                }}
              />
              <List disablePadding>
                <ListItem sx={{ paddingY: 0 }}>
                  <Typography variant="h6">Service History</Typography>
                </ListItem>
                <Divider />
                <NTSTable data={serviceDetails} cols={cols} rowsPerPage={[5]} />
              </List>
            </Paper>
          </Grid>
          <Grid item xs={12} md={5}>
            <Paper
              sx={{
                width: "100%",
                minHeight: "100%",
                maxHeight: "300px",
                overflow: "auto",
              }}
            >
              <Box
                sx={{
                  height: "3px",
                  backgroundColor: theme.palette.error.main,
                }}
              />
              <List disablePadding>
                <ListItem sx={{ paddingY: 0 }}>
                  <Typography variant="h6">Changed Parts</Typography>
                </ListItem>
                <Divider />
                <FlexListItem>
                  <DetailsTypography variant="body1">Part</DetailsTypography>
                  <DetailsTypography variant="body1">
                    Quantity
                  </DetailsTypography>
                </FlexListItem>
                {changedParts.length > 0 && <Divider />}
                {changedParts.map((part, index) => (
                  <React.Fragment key={index}>
                    <FlexListItem>
                      <Typography variant="body1">{part.partName}</Typography>
                      <Typography variant="body1" fontWeight="600">
                        x{part.quantity}
                      </Typography>
                    </FlexListItem>
                    {index < changedParts.length - 1 && <Divider />}
                  </React.Fragment>
                ))}
              </List>
            </Paper>
          </Grid>
        </Grid>
      </NTSModal>
    )
  );
};

export default MachineProfileModal;
