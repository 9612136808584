import { Button, Stack, TextField } from "@mui/material";
import { useEffect } from "react";
import useInput from "../../../../hooks/use-input";

const ConfirmAction = (props) => {
  const { onEditService, loading } = props;

  const {
    value: rejectValue,
    isValid: rejectIsValid,
    valueChangeHandler: rejectChangeHandler,
    valueResetHandler: rejectResetHandler,
  } = useInput((value) => value.trim().length >= 1);

  useEffect(() => {
    rejectResetHandler();
    // eslint-disable-next-line
  }, []);

  const submitHandler = (e, response) => {
    const message = response === "Confirm" ? "Service confirmed" : rejectValue;
    onEditService({ responseType: "Confirmation", response, message });
    rejectResetHandler();
  };

  return (
    <>
      <Stack direction="column" sx={{ paddingX: "16px", gap: "10px", flex: 1 }}>
        <Button
          variant="contained"
          color="success"
          onClick={(e) => submitHandler(e, "Confirm")}
          disabled={loading}
          sx={{ height: "54px" }}
        >
          Confirm Service
        </Button>
        <TextField
          fullWidth
          name="close"
          label="Reject Service"
          type="text"
          id="close"
          color="error"
          variant="outlined"
          value={rejectValue}
          onChange={rejectChangeHandler}
          disabled={loading}
          InputProps={{
            endAdornment: (
              <Button
                position="end"
                color="error"
                variant="contained"
                disabled={!rejectIsValid || loading}
                onClick={(e) => submitHandler(e, "Close")}
              >
                Reject
              </Button>
            ),
          }}
        />
      </Stack>
    </>
  );
};

export default ConfirmAction;
