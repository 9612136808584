import {
  ChatOutlined,
  FileCopyOutlined,
  GroupWorkOutlined,
  ImageOutlined,
  InfoOutlined,
} from "@mui/icons-material";
import { Badge, IconButton, useTheme } from "@mui/material";
import { useModal } from "../../context/modal-context";
import { useServices } from "../../context/service-context";
import {
  getColorForCloseStatus,
  getReadableDateWithHours,
  formatDateForView,
} from "../../services/helpers";
import NTSExpandableTableCell from "../UI/NTSExpandableTableCell";
import NTSTable from "../UI/NTSTable";

const AllClosedServices = () => {
  const theme = useTheme();
  const { services, loading } = useServices();
  const { openModal } = useModal();

  const cols = [
    {
      field: "location",
      headerName: "Location",
      type: "string",
      width: 170,
      valueGetter: (params) => {
        return `${params.row.location.name} - ${params.row.location.city}`;
      },
    },
    {
      field: "machine_type",
      headerName: "Type",
      type: "string",
      width: 80,
    },
    {
      field: "machine",
      headerName: "Machine",
      type: "string",
      width: 220,
      valueGetter: (params) => {
        const machineName = `${params.row.machine?.machine_number} ${params.row.machine?.cabinet?.name} [${params.row.machine?.serial_number}]`;
        return machineName.includes("undefined") ? "/" : machineName;
      },
    },
    {
      field: "ownership",
      headerName: "Ownership",
      type: "string",
      width: 120,
      valueGetter: (params) => {
        return `${params.row.machine?.cabinet?.manufacturer?.show_name ?? "/"}`;
      },
    },
    {
      field: "error_description",
      headerName: "Error Description",
      type: "string",
      width: 260,
      renderCell: (params) => {
        return (
          <span style={{ color: theme.palette.error.main }}>
            {params.row.error_description}
          </span>
        );
      },
    },
    {
      field: "created_at",
      headerName: "Request Date",
      type: "string",
      width: 200,
      valueGetter: (params) => {
        return formatDateForView(params.row.created_at);
      },
      renderCell: (params) => {
        return (
          <span style={{ color: theme.palette.error.main }}>
            {params.value}
          </span>
        );
      },
    },
    {
      field: "request_user",
      headerName: "Request User",
      type: "string",
      width: 260,
      valueGetter: (params) => {
        return `${params.row.request_user.fname} ${params.row.request_user.lname} (${params.row.request_user.role.show_name})`;
      },
      renderCell: (params) => {
        return (
          <span style={{ color: theme.palette.error.main }}>
            {params.value}
          </span>
        );
      },
    },
    {
      field: "fix_description",
      headerName: "Fix Description",
      type: "string",
      width: 280,
      renderCell: (params) => {
        return (
          <span style={{ color: theme.palette.warning.light }}>
            {params.row.fix_description}
          </span>
        );
      },
    },
    {
      field: "fix_date",
      headerName: "Fix Date",
      type: "string",
      width: 200,
      valueGetter: (params) => {
        return formatDateForView(params.row.fix_date);
      },
      renderCell: (params) => {
        return (
          <span style={{ color: theme.palette.warning.light }}>
            {params.value}
          </span>
        );
      },
    },
    {
      field: "fix_user",
      headerName: "Fix User",
      type: "string",
      width: 260,
      valueGetter: (params) => {
        return `${params.row.fix_user.fname} ${params.row.fix_user.lname} (${params.row.fix_user.role.show_name})`;
      },
      renderCell: (params) => {
        return (
          <span style={{ color: theme.palette.warning.light }}>
            {params.value}
          </span>
        );
      },
    },
    {
      field: "parts_details",
      headerName: "Used & Returned Parts",
      type: "string",
      width: 160,
      align: "center",
      renderCell: (params) => {
        const parts_details = JSON.parse(params.value);
        return parts_details?.changed_parts?.length > 0 &&
          parts_details?.returned_parts?.length > 0 ? (
          <IconButton
            onClick={() =>
              openModal("view", "parts-details", null, parts_details)
            }
          >
            <GroupWorkOutlined color="success" />
          </IconButton>
        ) : (
          false
        );
      },
    },
    {
      field: "updated_at",
      headerName: "Close Date",
      type: "string",
      width: 200,
      valueGetter: (params) => {
        return formatDateForView(params.row.close_date);
      },
      renderCell: (params) => {
        return (
          <span style={{ color: theme.palette.success.light }}>
            {params.value}
          </span>
        );
      },
    },
    {
      field: "close_user",
      headerName: "Close User",
      type: "string",
      width: 260,
      valueGetter: (params) => {
        return `${params.row.close_user.fname} ${params.row.close_user.lname} (${params.row.close_user.role.show_name})`;
      },
      renderCell: (params) => {
        return (
          <span style={{ color: theme.palette.success.light }}>
            {params.value}
          </span>
        );
      },
    },
    {
      field: "close_status",
      headerName: "Close Status",
      type: "string",
      width: 150,
      align: "center",
      renderCell: (params) => {
        const value = params.row.close_status;
        return (
          <Badge badgeContent={value} color={getColorForCloseStatus(value)} />
        );
      },
    },
    {
      field: "service_conversation",
      headerName: "",
      valueGetter: (params) => {
        const _value = params.row.service_conversation
          .map(
            (item) =>
              `${item.user_details} | ${getReadableDateWithHours(
                item.created_at
              )} | ${item.message}`
          )
          .join(",, ");
        return _value;
      },
      renderCell: (params) => (
        <NTSExpandableTableCell
          {...params}
          alwaysShow
          asChat
          justify="center"
          popperWidth="250px"
          customCellValue={
            <IconButton color="info" sx={{ justifySelf: "center" }}>
              <ChatOutlined />
            </IconButton>
          }
        />
      ),
    },
  ];

  const customButtons = [
    {
      icon: <InfoOutlined color="secondary" />,
      label: "Service Machine Profile",
      onClick: (id) => openModal("view", "machine-service-profile", id),
    },
    {
      icon: <ImageOutlined color="info" />,
      label: "View Image",
      showInMenu: true,
      onClick: (id) => {
        const imageName = services.find(
          (service) => service.id === id
        )?.picture;
        const imagePath = `${process.env.REACT_APP_IMAGES_URL}/services/${imageName}`;
        window.open(imagePath, "_blank", "noreferrer");
      },
    },
    {
      icon: <FileCopyOutlined color="info" />,
      label: "Work Report",
      showInMenu: true,
      onClick: (id) => openModal("view", "work-report", id),
    },
  ];

  return (
    <NTSTable
      data={services}
      cols={cols}
      loading={loading}
      customButtons={customButtons}
    />
  );
};

export default AllClosedServices;
