import { useState } from "react";
import {
  Diversity2Outlined,
  DriveFileRenameOutlineOutlined,
  ExpandLessOutlined,
  ExpandMoreOutlined,
  GroupOutlined,
  LocationCityOutlined,
  PlaceOutlined,
  Public,
  SecurityOutlined,
  Settings,
} from "@mui/icons-material";
import {
  Box,
  Collapse,
  IconButton,
  List,
  ListItemIcon,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { hasAbility, isInRoleTypes } from "../../../services/helpers";
import { useAuth } from "../../../context/auth-context";

const CustomMenuItem = (props) => {
  const { icon, text, additional, ...rest } = props;
  return (
    <MenuItem {...rest}>
      <ListItemIcon>{icon}</ListItemIcon>
      <Typography variant="body1" sx={{ flexGrow: 1 }}>
        {text}
      </Typography>
      {additional}
    </MenuItem>
  );
};

const SettingsMenu = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { currentUser } = useAuth();
  const open = Boolean(anchorEl);
  const [submenuOpen, setSubmenuOpen] = useState(false);
  const navigate = useNavigate();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  let menuItems = [];
  let dropdownMenuItems = [];
  if (
    isInRoleTypes(currentUser.role.role_type.name, ["admin", "country_admin"])
  ) {
    // if (isInRoleTypes(currentUser.role.role_type.name, ["admin"])) {
    //   menuItems = [
    //     ...menuItems,
    //     {
    //       icon: <Public fontSize="medium" color="info" />,
    //       text: "Countries",
    //       onClick: () => navigate("/countries"),
    //     },
    //   ];
    // }
    // menuItems = [
    //   ...menuItems,
    //   {
    //     icon: <LocationCityOutlined fontSize="medium" color="info" />,
    //     text: "Companies",
    //     onClick: () => navigate("/companies"),
    //   },
    //   {
    //     icon: <SecurityOutlined fontSize="medium" color="info" />,
    //     text: "Roles",
    //     onClick: () => navigate("/roles"),
    //   },
    // ];
  }
  if (hasAbility("can_manage_user", currentUser.role.abilities)) {
    menuItems = [
      ...menuItems,
      {
        icon: <GroupOutlined fontSize="medium" color="info" />,
        text: "Users",
        onClick: () => navigate("/users"),
      },
    ];
  }
  if (hasAbility("can_assign_user", currentUser.role.abilities)) {
    dropdownMenuItems = [
      ...dropdownMenuItems,
      {
        icon: <PlaceOutlined fontSize="medium" color="info" />,
        text: "Locations",
        onClick: () => navigate("/assigns"),
      },
    ];
  }
  if (hasAbility("can_view_group", currentUser.role.abilities)) {
    dropdownMenuItems = [
      ...dropdownMenuItems,
      {
        icon: <Diversity2Outlined fontSize="medium" color="info" />,
        text: "Groups",
        onClick: () => navigate("/groups"),
      },
    ];
  }

  if (menuItems.length === 0) return;

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <IconButton onClick={handleClick}>
          <Settings fontSize="large" sx={{ color: "white" }} />
        </IconButton>
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 4,
          sx: {
            overflow: "visible",
            mt: 1,
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {menuItems.map((item, index) => (
          <CustomMenuItem
            key={index}
            icon={item.icon}
            text={item.text}
            onClick={item.onClick}
          />
        ))}
        {dropdownMenuItems.length > 0 && (
          <CustomMenuItem
            icon={
              <DriveFileRenameOutlineOutlined fontSize="medium" color="info" />
            }
            text="Assigns"
            onClick={(e) => {
              e.stopPropagation();
              setSubmenuOpen((prevState) => !prevState);
            }}
            additional={
              submenuOpen ? <ExpandLessOutlined /> : <ExpandMoreOutlined />
            }
          />
        )}
        <Collapse in={submenuOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {dropdownMenuItems.map((item, index) => (
              <CustomMenuItem
                key={index}
                icon={item.icon}
                text={item.text}
                onClick={item.onClick}
                sx={{ pl: 3 }}
              />
            ))}
          </List>
        </Collapse>
      </Menu>
    </>
  );
};

export default SettingsMenu;
