import { Container } from "@mui/material";
import { useEffect } from "react";
import AllParts from "../components/Part/AllParts";
import PartModal from "../components/Part/PartModal";
import PartsFilter from "../components/Part/PartsFilter";
import CRUDHeader from "../components/UI/CRUDHeader";
import { useModal } from "../context/modal-context";
import { useParts } from "../context/part-context";

const Parts = () => {
  const { clearPartsData, parts } = useParts();
  const { openModal } = useModal();

  useEffect(() => {
    clearPartsData();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Container maxWidth="sm" sx={{ paddingBottom: "16px" }}>
        <CRUDHeader title="Parts" onAddNew={() => openModal("add", "part")} />
        <PartsFilter />
        {parts.length > 0 && <AllParts />}
      </Container>
      <PartModal />
    </>
  );
};

export default Parts;
