import { useCallback, createContext, useContext, useReducer } from "react";
import httpReducer from "../reducers/http-reducer";
import { useSnackbar } from "./snackbar-context";
import {
  createMachine,
  deleteMachine,
  getMachines,
  getMachinesByFilter,
  updateMachine,
} from "../services/api-service";

export const MachineContext = createContext({
  machines: [],
  loading: false,
  loadMachines: () => {},
  searchMachines: (searchObj) => {},
  addMachine: (machineObj) => {},
  editMachine: (id, machineObj) => {},
  removeMachine: (id) => {},
  clearMachinesData: () => {},
});

export const MachineContextProvider = (props) => {
  const { showSnackbar, resetSnackbar } = useSnackbar();
  const [state, dispatch] = useReducer(httpReducer, {
    data: [],
    loading: false,
  });

  const loadMachines = useCallback(async () => {
    dispatch({ type: "START_LOAD" });
    resetSnackbar();
    try {
      const response = await getMachines();
      const machinesData = response.data.data;
      dispatch({ type: "ADD_DATA", data: machinesData });
    } catch (error) {
      dispatch({ type: "END_LOAD" });
      const errorMessage = error.response?.data?.message || error.message;
      showSnackbar(errorMessage, "error", "red");
    }
    // eslint-disable-next-line
  }, []);

  const searchMachines = async (searchObj) => {
    dispatch({ type: "START_LOAD" });
    resetSnackbar();
    try {
      const response = await getMachinesByFilter(searchObj);
      const machinesData = response.data.data;
      if (machinesData.length < 1)
        showSnackbar(
          "No results found according to search criteria",
          "warning",
          "orange"
        );
      dispatch({ type: "ADD_DATA", data: machinesData });
    } catch (error) {
      dispatch({ type: "END_LOAD" });
      const errorMessage = error.response?.data?.message || error.message;
      showSnackbar(errorMessage, "error", "red");
    }
  };

  const addMachine = async (machineObj) => {
    dispatch({ type: "START_LOAD" });
    resetSnackbar();
    try {
      const response = await createMachine(machineObj);
      dispatch({ type: "END_LOAD" });
      const message = response.data.message;
      showSnackbar(message, "success", "green");
    } catch (error) {
      dispatch({ type: "END_LOAD" });
      const errorMessage = error.response?.data?.message || error.message;
      showSnackbar(errorMessage, "error", "red");
    }
  };

  const editMachine = async (id, machineObj) => {
    dispatch({ type: "START_LOAD" });
    resetSnackbar();
    try {
      const response = await updateMachine(id, machineObj);
      const machine = response.data.data;
      dispatch({ type: "EDIT_ITEM", id: id, data: machine });
      const message = response.data.message;
      showSnackbar(message, "success", "green");
    } catch (error) {
      dispatch({ type: "END_LOAD" });
      const errorMessage = error.response?.data?.message || error.message;
      showSnackbar(errorMessage, "error", "red");
    }
  };

  const removeMachine = async (id) => {
    dispatch({ type: "START_LOAD" });
    resetSnackbar();
    try {
      const response = await deleteMachine(id);
      dispatch({ type: "REMOVE_ITEM", id: id });
      const message = response.data.message;
      showSnackbar(message, "success", "green");
    } catch (error) {
      dispatch({ type: "END_LOAD" });
      const errorMessage = error.response?.data?.message || error.message;
      showSnackbar(errorMessage, "error", "red");
    }
  };

  const clearMachinesData = useCallback(() => {
    dispatch({ type: "CLEAR_DATA" });
  }, []);

  const ctxValue = {
    machines: state.data,
    loading: state.loading,
    loadMachines,
    searchMachines,
    addMachine,
    editMachine,
    removeMachine,
    clearMachinesData,
  };

  return (
    <MachineContext.Provider value={ctxValue}>
      {props.children}
    </MachineContext.Provider>
  );
};

export const useMachines = () => useContext(MachineContext);
