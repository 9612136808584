import { Grid } from "@mui/material";
import MachineModal from "../Machine/MachineModal";
import LocationModal from "../Location/LocationModal";
import ServiceBox from "./ServiceBox";

const AllServices = (props) => {
  const { servicesForDisplay } = props;

  return (
    <>
      <Grid container spacing={2}>
        {servicesForDisplay.length > 0 &&
          servicesForDisplay.map((service, index) => (
            <ServiceBox key={index} service={service} />
          ))}
      </Grid>
      <MachineModal />
      <LocationModal />
    </>
  );
};

export default AllServices;
