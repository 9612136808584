import { Grid, Typography } from "@mui/material";
import { useLocations } from "../../context/location-context";
import LinearLoad from "../UI/LinearLoad";
import WarehouseBox from "./WarehouseBox";

const AllWarehouses = () => {
  const { warehouses, warehousesLoading } = useLocations();

  return (
    <>
      {warehousesLoading && <LinearLoad />}
      <Grid container spacing={2}>
        {!warehousesLoading && warehouses.length === 0 ? (
          <Grid item xs={12}>
            <Typography variant="h6">
              There are no warehouses at the moment.
            </Typography>
          </Grid>
        ) : (
          warehouses.length > 0 &&
          warehouses.map((warehouse, index) => (
            <WarehouseBox key={index} warehouse={warehouse} />
          ))
        )}
      </Grid>
    </>
  );
};

export default AllWarehouses;
