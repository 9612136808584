import dayjs from "dayjs";
import { useMachines } from "../../context/machine-context";
import { useModal } from "../../context/modal-context";
import NTSTable from "../UI/NTSTable";

const AllMachines = () => {
  const { machines, loading } = useMachines();
  const { openModal } = useModal();

  const cols = [
    {
      field: "type",
      headerName: "Type",
      type: "string",
      width: 80,
    },
    {
      field: "location",
      headerName: "Location",
      type: "string",
      width: 210,
      valueGetter: (params) => {
        return `${params.row.location.name} - ${params.row.location.company.groupation_name}`;
      },
    },
    {
      field: "cabinet",
      headerName: "Cabinet",
      type: "string",
      width: 150,
      valueGetter: (params) => {
        return `${params.row.cabinet.name} (${params.row.cabinet.manufacturer.show_name})`;
      },
    },
    {
      field: "machine_number",
      headerName: "Machine No.",
      type: "string",
      width: 110,
    },
    {
      field: "serial_number",
      headerName: "Serial No.",
      type: "string",
      width: 110,
    },
    {
      field: "manufacturer_date",
      headerName: "Manuf. Date",
      type: "string",
      width: 100,
      valueGetter: (params) => {
        return !!params.row.manufacturer_date
          ? dayjs(params.row.manufacturer_date).format("MM/YYYY")
          : "";
      },
    },
    {
      field: "game",
      headerName: "Game",
      type: "string",
      width: 140,
      valueGetter: (params) => {
        return `${
          params.row.parts.filter((part) => part.part_type.name === "Game")[0]
            .name
        }`;
      },
    },
    {
      field: "version",
      headerName: "Version",
      type: "string",
      width: 140,
      valueGetter: (params) => {
        return `${
          params.row.parts.filter(
            (part) => part.part_type.name === "Version"
          )[0].name
        }`;
      },
    },
    {
      field: "kernel",
      headerName: "Kernel",
      type: "string",
      width: 140,
      valueGetter: (params) => {
        return `${
          params.row.parts.filter((part) => part.part_type.name === "Kernel")[0]
            .name
        }`;
      },
    },
    {
      field: "ownership",
      headerName: "Ownership",
      type: "string",
      width: 120,
      valueGetter: (params) => {
        return `${params.row.ownership.groupation_name}`;
      },
    },
  ];

  return (
    <NTSTable
      onView={(id) => openModal("view", "machine", id)}
      onEdit={(id) => openModal("edit", "machine", id)}
      onRemove={(id) => openModal("delete", "machine", id)}
      data={machines}
      cols={cols}
      loading={loading}
      actions={true}
    />
  );
};

export default AllMachines;
