import {
  useState,
  useCallback,
  createContext,
  useContext,
  useReducer,
} from "react";
import httpReducer from "../reducers/http-reducer";
import {
  createService,
  createServiceConversation,
  deleteService,
  getActiveServicesCount,
  getRelatedFixDescriptions,
  getActiveServices,
  updateService,
  getClosedServices,
  getClosedServicesByFilter,
} from "../services/api-service";
import { useSnackbar } from "./snackbar-context";

export const ServiceContext = createContext({
  services: [],
  loading: false,
  loadActiveServices: () => {},
  loadClosedServices: () => {},
  addService: (serviceObj) => {},
  addServiceConversation: (id, messageObj) => {},
  editService: (id, serviceObj) => {},
  removeService: (id) => {},
  activeServicesCount: 0,
  fetchActiveServicesCount: () => {},
  relatedFixDescriptions: [],
  relatedFixDescriptionsLoading: false,
  fetchRelatedFixDescriptions: (errorDescriptionObj) => {},
  clearRelatedFixDescriptionsData: () => {},
  clearServicesData: () => {},
  searchClosedServices: (searchObj) => {},
});

export const ServiceContextProvider = (props) => {
  const { showSnackbar, resetSnackbar } = useSnackbar();
  const [state, dispatch] = useReducer(httpReducer, {
    data: [],
    loading: false,
  });
  const [fixDescriptionsState, fixDescriptionsDispatch] = useReducer(
    httpReducer,
    {
      data: [],
      loading: false,
    }
  );
  const [activeServicesCount, setActiveServicesCount] = useState(0);

  const loadActiveServices = useCallback(async () => {
    dispatch({ type: "START_LOAD" });
    resetSnackbar();
    try {
      const response = await getActiveServices();
      const servicesData = response.data.data;
      dispatch({ type: "ADD_DATA", data: servicesData });
    } catch (error) {
      dispatch({ type: "END_LOAD" });
      const errorMessage = error.response?.data?.message || error.message;
      showSnackbar(errorMessage, "error", "red");
    }
    fetchActiveServicesCount();
    // eslint-disable-next-line
  }, []);

  const loadClosedServices = useCallback(async () => {
    dispatch({ type: "START_LOAD" });
    resetSnackbar();
    try {
      const response = await getClosedServices();
      const servicesData = response.data.data;
      dispatch({ type: "ADD_DATA", data: servicesData });
    } catch (error) {
      dispatch({ type: "END_LOAD" });
      const errorMessage = error.response?.data?.message || error.message;
      showSnackbar(errorMessage, "error", "red");
    }
    fetchActiveServicesCount();
    // eslint-disable-next-line
  }, []);

  const searchClosedServices = useCallback(async (searchObj) => {
    dispatch({ type: "START_LOAD" });
    resetSnackbar();
    try {
      const response = await getClosedServicesByFilter(searchObj);
      const servicesData = response.data.data;
      if (servicesData.length < 1)
        showSnackbar(
          "No results found according to search criteria",
          "warning",
          "orange"
        );
      dispatch({ type: "ADD_DATA", data: servicesData });
    } catch (error) {
      dispatch({ type: "END_LOAD" });
      const errorMessage = error.response?.data?.message || error.message;
      showSnackbar(errorMessage, "error", "red");
    }
    fetchActiveServicesCount();
    // eslint-disable-next-line
  }, []);

  const addService = async (serviceObj) => {
    dispatch({ type: "START_LOAD" });
    resetSnackbar();
    try {
      const response = await createService(serviceObj);
      const service = response.data.data;
      dispatch({ type: "ADD_ITEM", item: service });
      const message = response.data.message;
      showSnackbar(message, "success", "green");
    } catch (error) {
      dispatch({ type: "END_LOAD" });
      const errorMessage = error.response?.data?.message || error.message;
      showSnackbar(errorMessage, "error", "red");
    }
    fetchActiveServicesCount();
  };

  const addServiceConversation = async (id, messageObj) => {
    resetSnackbar();
    try {
      const response = await createServiceConversation(id, messageObj);
      const service = response.data.data;
      dispatch({ type: "EDIT_ITEM", id: id, data: service });
      const message = response.data.message;
      showSnackbar(message, "success", "green");
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      showSnackbar(errorMessage, "error", "red");
    }
    fetchActiveServicesCount();
  };

  const editService = async (id, serviceObj) => {
    resetSnackbar();
    try {
      const response = await updateService(id, serviceObj);
      const service = response.data.data;
      !service.close_status
        ? dispatch({ type: "EDIT_ITEM", id: id, data: service })
        : dispatch({ type: "REMOVE_ITEM", id: id });
      const message = response.data.message;
      showSnackbar(message, "success", "green");
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      showSnackbar(errorMessage, "error", "red");
    }
    fetchActiveServicesCount();
  };

  const removeService = async (id) => {
    dispatch({ type: "START_LOAD" });
    resetSnackbar();
    try {
      const response = await deleteService(id);
      dispatch({ type: "REMOVE_ITEM", id: id });
      const message = response.data.message;
      showSnackbar(message, "success", "green");
    } catch (error) {
      dispatch({ type: "END_LOAD" });
      const errorMessage = error.response?.data?.message || error.message;
      showSnackbar(errorMessage, "error", "red");
    }
    fetchActiveServicesCount();
  };

  const fetchActiveServicesCount = useCallback(async () => {
    try {
      const fetchedActiveServicesCount = await getActiveServicesCount();
      const count = fetchedActiveServicesCount.data.data;
      setActiveServicesCount(count);
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      showSnackbar(errorMessage, "error", "red");
    }
    // eslint-disable-next-line
  }, []);

  const fetchRelatedFixDescriptions = useCallback(
    async (errorDescriptionObj) => {
      fixDescriptionsDispatch({ type: "START_LOAD" });
      resetSnackbar();
      try {
        const response = await getRelatedFixDescriptions(errorDescriptionObj);
        const fixDescriptions = response.data.data;
        fixDescriptionsDispatch({
          type: "ADD_DATA",
          data: Object.values(fixDescriptions),
        });
      } catch (error) {
        fixDescriptionsDispatch({ type: "END_LOAD" });
        const errorMessage = error.response?.data?.message || error.message;
        showSnackbar(errorMessage, "error", "red");
      }
    },
    // eslint-disable-next-line
    []
  );

  const clearServicesData = useCallback(() => {
    dispatch({ type: "CLEAR_DATA" });
  }, []);

  const clearRelatedFixDescriptionsData = useCallback(() => {
    fixDescriptionsDispatch({ type: "CLEAR_DATA" });
  }, []);

  const ctxValue = {
    services: state.data,
    loading: state.loading,
    loadActiveServices,
    loadClosedServices,
    addService,
    addServiceConversation,
    editService,
    removeService,
    activeServicesCount,
    fetchActiveServicesCount,
    relatedFixDescriptions: fixDescriptionsState.data,
    relatedFixDescriptionsLoading: fixDescriptionsState.loading,
    fetchRelatedFixDescriptions,
    clearRelatedFixDescriptionsData,
    clearServicesData,
    searchClosedServices,
  };

  return (
    <ServiceContext.Provider value={ctxValue}>
      {props.children}
    </ServiceContext.Provider>
  );
};

export const useServices = () => useContext(ServiceContext);
