import { Button, Stack, TextField } from "@mui/material";
import { useEffect } from "react";
import useInput from "../../../../hooks/use-input";

const WaitingAction = (props) => {
  const { onEditService, loading } = props;
  const {
    value: customDescriptionValue,
    isValid: customDescriptionIsValid,
    valueChangeHandler: customDescriptionChangeHandler,
    valueResetHandler: customDescriptionResetHandler,
  } = useInput((value) => value.trim().length >= 1);

  useEffect(() => {
    customDescriptionResetHandler();
    // eslint-disable-next-line
  }, []);

  const submitHandler = (e, description = null) => {
    onEditService({ message: description ?? customDescriptionValue });
    customDescriptionResetHandler();
  };

  return (
    <>
      <Stack direction="column" sx={{ paddingX: "16px", gap: "10px", flex: 1 }}>
        <Button
          variant="contained"
          color="success"
          onClick={(e) => submitHandler(e, "Coming in 1 hour")}
          sx={{ height: "54px" }}
          disabled={loading}
        >
          1 hour
        </Button>
        <Button
          variant="contained"
          color="warning"
          onClick={(e) => submitHandler(e, "Coming in 4 hours")}
          sx={{ height: "54px" }}
          disabled={loading}
        >
          4 hours
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={(e) => submitHandler(e, "Coming in 12 hours")}
          sx={{ height: "54px" }}
          disabled={loading}
        >
          12 hours
        </Button>
        <TextField
          fullWidth
          name="custom_description"
          label="Custom Response"
          type="text"
          id="custom_description"
          variant="outlined"
          value={customDescriptionValue}
          onChange={customDescriptionChangeHandler}
          disabled={loading}
          InputProps={{
            endAdornment: (
              <Button
                position="end"
                color="primary"
                variant="contained"
                disabled={!customDescriptionIsValid || loading}
                onClick={submitHandler}
              >
                Respond
              </Button>
            ),
          }}
        />
      </Stack>
    </>
  );
};

export default WaitingAction;
