import { Container } from "@mui/material";
import { useEffect } from "react";
import AllPartTypes from "../components/PartType/AllPartTypes";
import PartTypeModal from "../components/PartType/PartTypeModal";
import CRUDHeader from "../components/UI/CRUDHeader";
import { useModal } from "../context/modal-context";
import { useParts } from "../context/part-context";

const PartTypes = () => {
  const { loadPartTypes } = useParts();
  const { openModal } = useModal();

  useEffect(() => {
    loadPartTypes();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Container maxWidth="sm" sx={{ paddingBottom: "16px" }}>
        <CRUDHeader
          title="Part Types"
          onAddNew={() => openModal("add", "partType")}
          onRefresh={() => loadPartTypes()}
        />
        <AllPartTypes />
      </Container>
      <PartTypeModal />
    </>
  );
};

export default PartTypes;
