import {
  Autocomplete,
  Checkbox,
  Chip,
  FormControl,
  Skeleton,
  Stack,
  TextField,
} from "@mui/material";

const NTSMultiSelect = (props) => {
  const {
    loading,
    assigns,
    onChange,
    values,
    icon,
    title,
    color,
    disabled,
    required,
    onBlur,
    error,
    sx,
    flags,
  } = props;

  return (
    <FormControl sx={{ mt: 2, ...sx }} fullWidth>
      {loading ? (
        <Stack spacing={1}>
          <Skeleton variant="text" />
          <Skeleton variant="text" />
        </Stack>
      ) : (
        <Autocomplete
          {...flags}
          multiple
          isOptionEqualToValue={(option, value) => option.id === value.id}
          limitTags={2}
          disableCloseOnSelect
          disableClearable
          disabled={disabled}
          required={required}
          onClose={onBlur}
          options={values}
          value={assigns}
          onChange={(event, newOptions) => onChange(newOptions)}
          getOptionLabel={(option) =>
            option.show_name ?? option.name ?? `${option.fname} ${option.lname}`
          }
          renderInput={(params) => (
            <TextField {...params} error={error} label={`${title}`} />
          )}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox checked={selected} />
              {option.show_name ??
                option.name ??
                `${option.fname} ${option.lname}`}
            </li>
          )}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                variant="outlined"
                icon={icon}
                color={color}
                sx={{ maxHeight: "32px" }}
                label={
                  option.show_name ??
                  option.name ??
                  `${option.fname} ${option.lname}`
                }
                {...getTagProps({ index })}
              />
            ))
          }
        />
      )}
    </FormControl>
  );
};

export default NTSMultiSelect;
