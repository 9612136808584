import { useMemo } from "react";
import { useMachines } from "../../context/machine-context";
import { formatDateForView } from "../../services/helpers";
import NTSExpandableTableCell from "../UI/NTSExpandableTableCell";
import NTSTable from "../UI/NTSTable";

const AllMachineChanges = (props) => {
  const { machines, loading } = useMachines();
  const { changeType } = props;
  const changeTypeAsField = useMemo(() => {
    if (changeType)
      switch (changeType) {
        case "Location Transfer":
          return "location_name";
        case "Ownership":
          return "ownership_name";
        default:
          return changeType?.toLowerCase();
      }
  }, [changeType]);
  const filteredByChangeType = machines.map((machine) => ({
    ...machine,
    machine_history: machine.machine_history.filter(
      (item) => !!item[changeTypeAsField]
    ),
  }));

  const newMachines = [];
  filteredByChangeType.forEach((machine) =>
    machine.machine_history.map((item, index) => {
      let latestChangeType = "";
      switch (changeTypeAsField) {
        case "location_name":
          latestChangeType = `${machine.location.name} - ${machine.location.company.groupation_name}`;
          break;
        case "ownership_name":
          latestChangeType = machine.ownership.groupation_name;
          break;
        default:
          latestChangeType = machine.parts.find(
            (part) => part.part_type.name === changeType
          )?.name;
      }
      newMachines.push({
        id: item.id,
        company_name: machine.location.company.groupation_name,
        location_name: machine.location.name,
        machine_serial: machine.serial_number,
        [`old_${changeTypeAsField}`]: item[changeTypeAsField],
        [`new_${changeTypeAsField}`]:
          index < machine.machine_history.length - 1
            ? machine.machine_history[index + 1][changeTypeAsField]
            : latestChangeType,
        created_at: item.created_at,
      });
      return false;
    })
  );

  const cols = [
    {
      field: "company_name",
      headerName: "Company",
      type: "string",
      width: 150,
    },
    {
      field: "location_name",
      headerName: "Location",
      type: "string",
      width: 110,
    },
    {
      field: "machine_serial",
      headerName: "Machine Serial",
      type: "string",
      width: 170,
    },
    {
      field: `old_${changeTypeAsField}`,
      headerName: `Old ${changeType}`,
      type: "string",
      width: 180,
      renderCell: NTSExpandableTableCell,
    },
    {
      field: `new_${changeTypeAsField}`,
      headerName: `New ${changeType}`,
      type: "string",
      width: 180,
      renderCell: NTSExpandableTableCell,
    },
    {
      field: "created_at",
      headerName: "Date",
      type: "string",
      width: 100,
      valueGetter: (params) => {
        return formatDateForView(params.row.manufacturer_date);
      },
    },
  ];

  return <NTSTable data={newMachines} cols={cols} loading={loading} />;
};

export default AllMachineChanges;
