import { Refresh } from "@mui/icons-material";
import { Container } from "@mui/material";
import { useState, useRef, useEffect, useMemo } from "react";
import AllMachineChanges from "../components/MachineChanges/AllMachineChanges";
import MachineChangesFilter from "../components/MachineChanges/MachineChangesFilter";
import CRUDHeader from "../components/UI/CRUDHeader";
import { useCompanies } from "../context/company-context";
import { useLocations } from "../context/location-context";
import { useMachines } from "../context/machine-context";

const MachineChanges = () => {
  const { clearMachinesData, machines } = useMachines();
  const { loadCompanies } = useCompanies();
  const { loadLocations } = useLocations();
  const machinesFilterRef = useRef();
  const [changeType, setChangeType] = useState(false);

  useEffect(() => {
    clearMachinesData();
    loadCompanies();
    loadLocations();
    // eslint-disable-next-line
  }, []);

  const onCustom = useMemo(() => {
    return [
      {
        label: "Reset Filters",
        color: "info",
        onClick: () => machinesFilterRef.current.resetFilter(),
        icon: <Refresh />,
        sx: { width: 120 },
      },
    ];
  }, []);

  return (
    <Container maxWidth="xl" sx={{ paddingBottom: "16px" }}>
      <CRUDHeader title="Machine Changes" onCustom={onCustom} />
      <MachineChangesFilter
        ref={machinesFilterRef}
        onSearchComplete={(changeType) => setChangeType(changeType)}
      />
      {machines.length > 0 && <AllMachineChanges changeType={changeType} />}
    </Container>
  );
};

export default MachineChanges;
