import { Close, PrintOutlined } from "@mui/icons-material";
import {
  Button,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useState, useEffect, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { useModal } from "../../context/modal-context";
import { useServices } from "../../context/service-context";
import {
  formatDateForView,
  getReadableDateWithHours,
} from "../../services/helpers";
import NTSModal from "../UI/NTSModal";
import WorkReportPrint from "./WorkReportPrint";

const CustomHeader = (props) => {
  const { memorandum, workReportId, createdAt } = props;
  const imagePath = `${process.env.REACT_APP_IMAGES_URL}/memorandums/${memorandum}`;

  return (
    <Stack direction="column" sx={{ width: "100%" }}>
      <img
        src={imagePath}
        alt="memorandum"
        style={{ maxHeight: "100px", maxWidth: "inherit" }}
      />
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ padding: "16px" }}
      >
        <Typography variant="h6" sx={{ fontWeight: 600 }}>
          Work Report No. {workReportId}
        </Typography>
        <Typography variant="h6" sx={{ fontWeight: 600 }}>
          Date: {formatDateForView(createdAt)}
        </Typography>
      </Stack>
    </Stack>
  );
};

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const WorkReportModal = () => {
  const { modalState, closeModal } = useModal();
  const { services } = useServices();
  const [service, setService] = useState(null);
  const [usedParts, setUsedParts] = useState([]);
  const [returnedParts, setReturnedParts] = useState([]);
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `work-report-no-${service?.work_report?.id}`,
  });

  useEffect(() => {
    if (!modalState.type || modalState.model !== "work-report") return;

    const service = services.find((service) => service.id === modalState.id);
    const parts_details = service?.work_report?.parts_details;
    if (!!parts_details) {
      const _usedParts = JSON.parse(parts_details)?.changed_parts?.map(
        (part) => {
          const details = part?.show_name?.split(" | ");
          return {
            partName: details[1],
            quantity: details[2],
          };
        }
      );
      const _returnedParts = JSON.parse(parts_details)?.returned_parts?.map(
        (part) => {
          const details = part?.show_name?.split(" | ");
          return {
            partName: details[1],
            quantity: details[2],
          };
        }
      );
      setUsedParts(_usedParts);
      setReturnedParts(_returnedParts);
    }
    setService(service);
    // eslint-disable-next-line
  }, [modalState]);

  const tableRowCellsSx = {
    fontWeight: "600",
    fontSize: "16px",
  };
  const tableBodyCellsSx = { fontSize: "15px", fontWeight: "500" };

  return (
    modalState.open &&
    modalState.model === "work-report" &&
    service && (
      <NTSModal
        maxWidth="xl"
        customHeader={
          <CustomHeader
            memorandum={service.location.company.memorandum}
            workReportId={service.work_report.id}
            createdAt={service.work_report.created_at}
          />
        }
        actions={
          <>
            <Button onClick={closeModal} endIcon={<Close />} color="primary">
              Close
            </Button>
            <Button
              onClick={() => {
                handlePrint();
              }}
              endIcon={<PrintOutlined />}
              color="success"
            >
              <div style={{ display: "none" }}>
                <WorkReportPrint
                  service={service}
                  usedParts={usedParts}
                  returnedParts={returnedParts}
                  ref={componentRef}
                />
              </div>
              Print
            </Button>
          </>
        }
      >
        <TableContainer sx={{ marginTop: "20px" }}>
          <Table sx={{ minWidth: 650, tableLayout: "fixed" }}>
            <TableHead>
              <TableRow>
                <TableCell align="left" sx={{ ...tableRowCellsSx }}>
                  Company
                </TableCell>
                <TableCell align="left" sx={{ ...tableRowCellsSx }}>
                  Location
                </TableCell>
                <TableCell align="left" sx={{ ...tableRowCellsSx }}>
                  Machine No.
                </TableCell>
                <TableCell align="left" sx={{ ...tableRowCellsSx }}>
                  Type
                </TableCell>
                <TableCell align="left" sx={{ ...tableRowCellsSx }}>
                  Serial Number
                </TableCell>
                <TableCell align="left" sx={{ ...tableRowCellsSx }}>
                  Ownership
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <StyledTableRow>
                <TableCell align="left" sx={{ ...tableBodyCellsSx }}>
                  {service.location.company.groupation_name}
                </TableCell>
                <TableCell align="left" sx={{ ...tableBodyCellsSx }}>
                  {service.location.name}
                </TableCell>
                <TableCell align="left" sx={{ ...tableBodyCellsSx }}>
                  {service?.machine?.machine_number ?? ""}
                </TableCell>
                <TableCell align="left" sx={{ ...tableBodyCellsSx }}>
                  {service.machine_type}
                </TableCell>
                <TableCell align="left" sx={{ ...tableBodyCellsSx }}>
                  {service?.machine?.serial_number ?? ""}
                </TableCell>
                <TableCell align="left" sx={{ ...tableBodyCellsSx }}>
                  {service?.machine?.company_name ?? ""}
                </TableCell>
              </StyledTableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <TableContainer sx={{ marginTop: "20px" }}>
          <Table sx={{ minWidth: 650, tableLayout: "fixed" }}>
            <TableHead>
              <TableRow>
                <TableCell align="left" sx={{ ...tableRowCellsSx }}>
                  Start Date
                </TableCell>
                <TableCell align="left" sx={{ ...tableRowCellsSx }}>
                  Finish Date
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <StyledTableRow>
                <TableCell align="left" sx={{ ...tableBodyCellsSx }}>
                  {getReadableDateWithHours(service.created_at)}
                </TableCell>
                <TableCell align="left" sx={{ ...tableBodyCellsSx }}>
                  {getReadableDateWithHours(service.updated_at)}
                </TableCell>
              </StyledTableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <TableContainer sx={{ marginTop: "20px" }}>
          <Table sx={{ minWidth: 650, tableLayout: "fixed" }}>
            <TableHead>
              <TableRow>
                <TableCell align="left" sx={{ ...tableRowCellsSx }}>
                  Defect Description
                </TableCell>
                <TableCell align="left" sx={{ ...tableRowCellsSx }}>
                  Fix Description
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <StyledTableRow>
                <TableCell align="left" sx={{ ...tableBodyCellsSx }}>
                  {service.work_report.defect_description}
                </TableCell>
                <TableCell align="left" sx={{ ...tableBodyCellsSx }}>
                  {service.work_report.fix_description}
                </TableCell>
              </StyledTableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <TableContainer sx={{ marginTop: "20px" }}>
          <Table sx={{ minWidth: 650, tableLayout: "fixed" }}>
            <TableHead>
              <TableRow>
                <TableCell align="left" sx={{ ...tableRowCellsSx }}>
                  Old Control Numbers
                </TableCell>
                <TableCell align="left" sx={{ ...tableRowCellsSx }}>
                  New Control Numbers
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(service.work_report.old_control_number ||
                service.work_report.new_control_number) && (
                <StyledTableRow>
                  <TableCell align="left" sx={{ ...tableBodyCellsSx }}>
                    {service.work_report.old_control_number}
                  </TableCell>
                  <TableCell align="left" sx={{ ...tableBodyCellsSx }}>
                    {service.work_report.new_control_number}
                  </TableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TableContainer sx={{ marginTop: "20px" }}>
          <Table sx={{ minWidth: 650, tableLayout: "fixed" }}>
            <TableHead>
              <TableRow>
                <TableCell align="left" sx={{ ...tableRowCellsSx }}>
                  Used Spare Parts
                </TableCell>
                <TableCell align="left" sx={{ ...tableRowCellsSx }}>
                  Quantity
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {usedParts?.map((item, index) => (
                <StyledTableRow key={index}>
                  <TableCell align="left" sx={{ ...tableBodyCellsSx }}>
                    {item.partName}
                  </TableCell>
                  <TableCell align="left" sx={{ ...tableBodyCellsSx }}>
                    {item.quantity}
                  </TableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TableContainer sx={{ marginTop: "20px" }}>
          <Table sx={{ minWidth: 650, tableLayout: "fixed" }}>
            <TableHead>
              <TableRow>
                <TableCell align="left" sx={{ ...tableRowCellsSx }}>
                  Returned Spare Parts
                </TableCell>
                <TableCell align="left" sx={{ ...tableRowCellsSx }}>
                  Quantity
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {returnedParts?.map((item, index) => (
                <StyledTableRow key={index}>
                  <TableCell align="left" sx={{ ...tableBodyCellsSx }}>
                    {item.partName}
                  </TableCell>
                  <TableCell align="left" sx={{ ...tableBodyCellsSx }}>
                    {item.quantity}
                  </TableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TableContainer sx={{ marginY: "20px" }}>
          <Table sx={{ minWidth: 650, tableLayout: "fixed" }}>
            <TableHead>
              <TableRow>
                <TableCell align="left" sx={{ ...tableRowCellsSx }}>
                  Service Employee
                </TableCell>
                <TableCell align="left" sx={{ ...tableRowCellsSx }}>
                  Service Employee Signature
                </TableCell>
                <TableCell align="left" sx={{ ...tableRowCellsSx }}>
                  Location
                </TableCell>
                <TableCell align="left" sx={{ ...tableRowCellsSx }}>
                  Location Manager Signature
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <StyledTableRow>
                <TableCell align="left" sx={{ ...tableBodyCellsSx }}>
                  {`${service.fix_user.fname} ${service.fix_user.lname}`}
                </TableCell>
                <TableCell
                  align="left"
                  sx={{ ...tableBodyCellsSx }}
                ></TableCell>
                <TableCell align="left" sx={{ ...tableBodyCellsSx }}>
                  {`${service.location.name} - ${service.location.company.groupation_name}`}
                </TableCell>
                <TableCell
                  align="left"
                  sx={{ ...tableBodyCellsSx }}
                ></TableCell>
              </StyledTableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </NTSModal>
    )
  );
};

export default WorkReportModal;
