import { AddOutlined, CheckOutlined, ClearOutlined } from "@mui/icons-material";
import {
  Autocomplete,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Switch,
  TextField,
  useTheme,
} from "@mui/material";
import { useState, useCallback, useEffect } from "react";
import { useLocations } from "../../../../context/location-context";
import { useModal } from "../../../../context/modal-context";
import { useServices } from "../../../../context/service-context";
import { useSpareParts } from "../../../../context/spare-part-context";
import useInput from "../../../../hooks/use-input";
import NTSModal from "../../../UI/NTSModal";
import NTSModalFooter from "../../../UI/NTSModalFooter";
import NTSMultiSelect from "../../../UI/NTSMultiSelect";

const FinishModal = (props) => {
  const theme = useTheme();
  const { modalState, closeModal } = useModal();
  const { onEditService } = props;
  const {
    clearRelatedFixDescriptionsData,
    fetchRelatedFixDescriptions,
    relatedFixDescriptions,
    relatedFixDescriptionsLoading,
  } = useServices();
  const { loadWarehouses, warehouses, warehousesLoading } = useLocations();
  const {
    loadSpareParts,
    loading: sparePartsLoading,
    spareParts,
  } = useSpareParts();

  const [changedParts, setChangedParts] = useState([]);
  const [returnedParts, setReturnedParts] = useState([]);
  const [isPartReturned, setIsPartReturned] = useState(false);
  const {
    value: fixDescriptionValue,
    isValid: fixDescriptionIsValid,
    hasError: fixDescriptionHasError,
    valueBlurHandler: fixDescriptionBlurHandler,
    valueChangeHandler: fixDescriptionChangeHandler,
    valueResetHandler: fixDescriptionResetHandler,
    setValueHandler: setFixDescriptionHandler,
  } = useInput((value) => value !== "");
  const {
    value: oldControlNumberValue,
    isValid: oldControlNumberIsValid,
    valueChangeHandler: oldControlNumberChangeHandler,
    valueResetHandler: oldControlNumberResetHandler,
  } = useInput((value) => value.trim().length > 0);
  const {
    value: newControlNumberValue,
    isValid: newControlNumberIsValid,
    valueChangeHandler: newControlNumberChangeHandler,
    valueResetHandler: newControlNumberResetHandler,
  } = useInput((value) => value.trim().length > 0);

  const {
    value: warehouseValue,
    isValid: warehouseIsValid,
    hasError: warehouseHasError,
    valueBlurHandler: warehouseBlurHandler,
    valueResetHandler: warehouseResetHandler,
    setValueHandler: setWarehouseHandler,
  } = useInput((value) => value !== "");
  const {
    value: partValue,
    isValid: partIsValid,
    hasError: partHasError,
    valueBlurHandler: partBlurHandler,
    valueResetHandler: partResetHandler,
    setValueHandler: setPartHandler,
  } = useInput((value) => value !== "");
  const {
    value: quantityValue,
    isValid: quantityIsValid,
    hasError: quantityHasError,
    valueBlurHandler: quantityBlurHandler,
    valueChangeHandler: quantityChangeHandler,
    valueResetHandler: quantityResetHandler,
  } = useInput((value) => value >= 1);

  const resetMultiselects = () => {
    setChangedParts([]);
    setReturnedParts([]);
    setIsPartReturned(false);
  };

  const resetMiniForm = () => {
    warehouseResetHandler();
    partResetHandler();
    quantityResetHandler();
  };

  const resetForm = useCallback(() => {
    fixDescriptionResetHandler();
    oldControlNumberResetHandler();
    newControlNumberResetHandler();
    resetMiniForm();
    resetMultiselects();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    resetForm();
    if (!modalState.open || modalState.model !== "work_report") return;
    clearRelatedFixDescriptionsData();
    fetchRelatedFixDescriptions({
      error_description: modalState.additional.errorDescription,
    });
    loadWarehouses();
    loadSpareParts();
    // eslint-disable-next-line
  }, [modalState]);

  const customFixDescriptionHandler = (e, newOption = null) => {
    newOption
      ? setFixDescriptionHandler(newOption)
      : fixDescriptionChangeHandler(e.target.value);
  };

  const checkIfItemExists = (item, array) => {
    return (
      array.filter(
        (arrayItem) =>
          arrayItem.warehouse === item.warehouse && arrayItem.part === item.part
      ).length > 0
    );
  };

  const updateAndReplaceItemInArray = (item, array) => {
    const newArray = [...array];
    const stateItem = newArray.filter(
      (arrayItem) =>
        arrayItem.warehouse === item.warehouse && arrayItem.part === item.part
    )[0];
    stateItem.quantity += item.quantity;
    const show_name_array = stateItem.show_name.split(" | ");
    show_name_array[2] = +show_name_array[2] + +item.quantity;
    stateItem.show_name = show_name_array.join(" | ");

    return newArray;
  };

  const addPartHandler = () => {
    if (!warehouseIsValid || !partIsValid || !quantityIsValid) {
      warehouseBlurHandler();
      partBlurHandler();
      quantityBlurHandler();
      return;
    }
    const warehouse = warehouses.find(
      (warehouse) => warehouse.id === warehouseValue
    );
    const part = spareParts.find((sparePart) => sparePart.id === partValue);
    const show_name = `${warehouse.name} | ${part.name} | ${quantityValue}`;
    const newResult = {
      warehouse: warehouseValue,
      part: partValue,
      quantity: quantityValue,
      show_name,
    };
    if (isPartReturned) {
      const exists = checkIfItemExists(newResult, returnedParts);
      if (exists) {
        const updatedReturnedPartsCopy = updateAndReplaceItemInArray(
          newResult,
          returnedParts
        );
        setReturnedParts(updatedReturnedPartsCopy);
      } else {
        setReturnedParts((prev) => [...prev, newResult]);
      }
    } else {
      const exists = checkIfItemExists(newResult, changedParts);
      if (exists) {
        const updatedChangedPartsCopy = updateAndReplaceItemInArray(
          newResult,
          changedParts
        );
        setChangedParts(updatedChangedPartsCopy);
      } else {
        setChangedParts((prev) => [...prev, newResult]);
      }
    }
    resetMiniForm();
  };

  const submitHandler = () => {
    closeModal();
    const serviceObj = {
      responseType: "Work Report",
      fix_description: fixDescriptionValue,
      message: `A fix has been provided for this service: ${fixDescriptionValue}`,
      old_control_number: oldControlNumberIsValid
        ? oldControlNumberValue
        : null,
      new_control_number: newControlNumberIsValid
        ? newControlNumberValue
        : null,
      parts_details: JSON.stringify({
        changed_parts: changedParts,
        returned_parts: returnedParts,
      }),
    };
    onEditService(serviceObj);
    resetForm();
  };

  return (
    modalState.open &&
    modalState.model === "work_report" && (
      <NTSModal
        title="Create Work Report"
        maxWidth="lg"
        loading={
          relatedFixDescriptionsLoading ||
          warehousesLoading ||
          sparePartsLoading
        }
        loadingSx={{ marginBottom: 5 }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="defect_description"
              label="Defect Description"
              name="defect_description"
              value={modalState.additional.errorDescription}
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth sx={{ mt: 2 }}>
              <Autocomplete
                freeSolo
                id="fix_description"
                options={relatedFixDescriptions}
                value={fixDescriptionValue}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={fixDescriptionHasError}
                    label="Fix Description *"
                    onChange={(e) => customFixDescriptionHandler(e)}
                  />
                )}
                onChange={(e, newOption) => {
                  customFixDescriptionHandler(e, newOption);
                }}
                onClose={fixDescriptionBlurHandler}
                disabled={relatedFixDescriptionsLoading}
                required
                disableClearable
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              margin="normal"
              fullWidth
              id="old_control_number"
              label="Old control number"
              name="old_control_number"
              disabled={modalState.type === "view"}
              value={oldControlNumberValue}
              onChange={oldControlNumberChangeHandler}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              margin="normal"
              fullWidth
              id="new_control_number"
              label="New control number"
              name="new_control_number"
              disabled={modalState.type === "view"}
              value={newControlNumberValue}
              onChange={newControlNumberChangeHandler}
            />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12} md={3}>
            <FormControl fullWidth sx={{ mt: 1 }}>
              <Autocomplete
                id="warehouse"
                isOptionEqualToValue={(option, value) => option.id === value.id}
                value={
                  warehouses.find(
                    (warehouse) => warehouse.id === warehouseValue
                  ) || null
                }
                options={warehouses}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={warehouseHasError}
                    label="Warehouse *"
                  />
                )}
                getOptionLabel={(option) => option.show_name ?? option.name}
                onChange={(event, newOption) =>
                  setWarehouseHandler(newOption?.id ?? null)
                }
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl fullWidth sx={{ mt: 1 }}>
              <Autocomplete
                id="part"
                isOptionEqualToValue={(option, value) => option.id === value.id}
                value={
                  spareParts.find((sparePart) => sparePart.id === partValue) ||
                  null
                }
                options={spareParts}
                renderInput={(params) => (
                  <TextField {...params} error={partHasError} label="Part *" />
                )}
                getOptionLabel={(option) =>
                  option.show_name ?? option.name ?? ""
                }
                onChange={(event, newOption) =>
                  setPartHandler(newOption?.id ?? null)
                }
              />
            </FormControl>
          </Grid>
          <Grid item xs={7} md={2}>
            <FormControl fullWidth sx={{ mt: 1 }}>
              <TextField
                id="quantity"
                label="Quantity"
                name="quantity"
                type="number"
                value={quantityValue}
                onChange={quantityChangeHandler}
                error={quantityHasError}
              />
            </FormControl>
          </Grid>
          <Grid item xs={4} md={2}>
            <FormControlLabel
              sx={{ mt: 2 }}
              control={
                <Switch onChange={(e) => setIsPartReturned((prev) => !prev)} />
              }
              label="Returned"
            />
          </Grid>
          <Grid item xs={1} md={1}>
            <IconButton
              sx={{
                color: "#fff",
                backgroundColor: theme.palette.success.dark,
                mt: 2,
                "&:hover, &.MuiIconButton": {
                  background: theme.palette.success.main,
                },
              }}
              onClick={addPartHandler}
            >
              <AddOutlined color="text" />
            </IconButton>
          </Grid>
          <Grid item xs={12}>
            <Divider sx={{ mt: 1 }} />
          </Grid>
          <Grid item xs={12}>
            <NTSMultiSelect
              color="success"
              assigns={changedParts}
              onChange={setChangedParts}
              flags={{ readOnly: true }}
              values={[]}
              title="Changed Parts"
            />
          </Grid>
          <Grid item xs={12}>
            <NTSMultiSelect
              color="warning"
              assigns={returnedParts}
              onChange={setReturnedParts}
              flags={{ readOnly: true }}
              values={[]}
              title="Returned Parts"
            />
          </Grid>
        </Grid>
        <NTSModalFooter
          sx={{
            display: "flex",
            justifyContent: "end",
            marginY: 2,
          }}
        >
          <Button
            onClick={closeModal}
            endIcon={<ClearOutlined />}
            color="error"
          >
            Cancel
          </Button>
          <Button
            onClick={submitHandler}
            disabled={!fixDescriptionIsValid}
            endIcon={<CheckOutlined />}
            color="success"
          >
            Submit
          </Button>
        </NTSModalFooter>
      </NTSModal>
    )
  );
};

export default FinishModal;
