import dayjs from "dayjs";

export const hasAbility = (ability, userAbilities) => {
  const abilityNames = userAbilities.map((ability) => ability.name);
  return abilityNames.includes(ability);
};

export const isInRoleTypes = (userRoleType, typesArray) => {
  return typesArray.includes(userRoleType);
};

export const getTitleForModal = (modalType) => {
  let title = "";

  if (modalType === "add") {
    title = "ADD NEW";
  } else if (modalType === "edit") {
    title = "EDIT";
  } else if (modalType === "view") {
    title = "PROFILE";
  } else if (modalType === "delete") {
    title = "REMOVE";
  }

  return title;
};

export const getColorForWarehouseBadge = (quantity, warehouseType) => {
  if (warehouseType.toLowerCase().includes("primary")) {
    if (quantity <= 15) return "error";
    if (quantity <= 30) return "warning";
    if (quantity > 30) return "success";
  }
  if (warehouseType.toLowerCase().includes("secondary")) {
    if (quantity <= 5) return "error";
    if (quantity <= 12) return "warning";
    if (quantity > 12) return "success";
  }
};

export const getHourFromDatetime = (date) => {
  return Math.abs(dayjs(date).diff(dayjs(), "hour"));
};

export const getColorForHoursPassed = (date) => {
  const hour = getHourFromDatetime(date);
  if (hour <= 1) return "success";
  if (hour > 1 && hour <= 4) return "warning";
  if (hour > 4) return "error";
};

export const getColorFromStringForHoursPassed = (date) => {
  if (date === "Within 4 hours") return "success";
  if (date === "Within 4 to 12 hours") return "warning";
  if (date === "More than 12 hours") return "error";
};

export const getReadableDateWithHours = (date) => {
  return dayjs(date).format("DD/MM/YYYY h:mm");
};

export const formatDateForView = (date) => {
  return dayjs(date).format("DD/MM/YYYY");
};

export const getColorForCloseStatus = (status) => {
  switch (status) {
    case "Without Intervention":
      return "warning";
    case "Rejected":
      return "error";
    default:
      return "success";
  }
};

export const getColorForServiceProgress = (step) => {
  switch (step) {
    case "Waiting":
      return "error";
    case "Closed":
      return "warning";
    default:
      return "primary";
  }
};
