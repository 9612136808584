import { Container } from "@mui/material";
import { useState, useMemo } from "react";
import SearchAssigns from "../components/Assign/SearchAssigns";
import AssignsResults from "../components/Assign/AssignsResults";
import CRUDHeader from "../components/UI/CRUDHeader";
import AssignModal from "../components/Assign/AssignModal";
import { useModal } from "../context/modal-context";
import { LocationCityOutlined, PlaceOutlined } from "@mui/icons-material";

const Assigns = () => {
  const { openModal } = useModal();
  const [loading, setLoading] = useState(false);
  const [tableState, setTableState] = useState({
    type: null,
    data: null,
    parentId: null,
  });

  const onCustom = useMemo(() => {
    return [
      {
        label: "Assign to Locations",
        color: "warning",
        onClick: () => openModal("userToLocations", "assigns"),
        icon: <PlaceOutlined />,
        sx: { width: 160 },
      },
      {
        label: "Assign to Groupation",
        color: "warning",
        onClick: () => openModal("userToGroupation", "assigns"),
        icon: <LocationCityOutlined />,
        sx: { width: 170 },
      },
    ];

    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Container maxWidth="md" sx={{ paddingBottom: "16px" }}>
        <CRUDHeader title="Search Assigns" onCustom={onCustom} />
        <SearchAssigns
          loading={loading}
          setLoading={setLoading}
          setTableState={setTableState}
        />
        {tableState?.data?.length > 0 && (
          <AssignsResults loading={loading} tableState={tableState} />
        )}
      </Container>
      <AssignModal tableState={tableState} setTableState={setTableState} />
    </>
  );
};

export default Assigns;
