import { Container } from "@mui/system";
import { useEffect } from "react";
import AllManufacturers from "../components/Manufacturer/AllManufacturers";
import ManufacturerModal from "../components/Manufacturer/ManufacturerModal";
import CRUDHeader from "../components/UI/CRUDHeader";
import { useManufacturers } from "../context/manufacturer-context";
import { useModal } from "../context/modal-context";

const Manufacturers = () => {
  const { loadManufacturers } = useManufacturers();
  const { openModal } = useModal();

  useEffect(() => {
    loadManufacturers();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Container maxWidth="sm" sx={{ paddingBottom: "16px" }}>
        <CRUDHeader
          title="Manufacturers"
          onAddNew={() => openModal("add", "manufacturer")}
          onRefresh={() => loadManufacturers()}
        />
        <AllManufacturers />
      </Container>
      <ManufacturerModal />
    </>
  );
};

export default Manufacturers;
