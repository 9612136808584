import { useManufacturers } from "../../context/manufacturer-context";
import { useModal } from "../../context/modal-context";
import NTSTable from "../UI/NTSTable";

const AllManufacturers = () => {
  const { manufacturers, loading } = useManufacturers();
  const { openModal } = useModal();

  const cols = [
    {
      field: "show_name",
      headerName: "Name",
      type: "string",
      width: 200,
    },
  ];

  return (
    <NTSTable
      onEdit={(id) => openModal("edit", "manufacturer", id)}
      onRemove={(id) => openModal("delete", "manufacturer", id)}
      data={manufacturers}
      cols={cols}
      loading={loading}
      actions={true}
    />
  );
};

export default AllManufacturers;
