import {
  AccountCircle,
  Logout,
  ManageAccountsOutlined,
} from "@mui/icons-material";
import {
  Box,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../context/auth-context";
import { useModal } from "../../../context/modal-context";
import UserModal from "../../User/UserModal";

const CustomMenuItem = (props) => {
  const { icon, text, ...rest } = props;
  return (
    <MenuItem {...rest}>
      <ListItemIcon>{icon}</ListItemIcon>
      <Typography variant="body1">{text}</Typography>
    </MenuItem>
  );
};

const ProfileMenu = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const { openModal } = useModal();

  const { currentUser, signOut } = useAuth();

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const editProfileHandler = () => {
    const id = currentUser.id;
    openModal("edit", "user", id, { self: true });
  };

  const signOutHandler = () => {
    signOut();
    navigate("/");
  };

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <IconButton onClick={handleClick}>
          <AccountCircle fontSize="large" sx={{ color: "white" }} />
        </IconButton>
      </Box>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 4,
          sx: {
            overflow: "visible",
            mt: 1,
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <Box sx={{ p: 1, px: 2 }}>
          <Typography variant="body1" component="div">
            Signed in as
            <Typography component="span" sx={{ fontWeight: "bold", ml: 0.8 }}>
              {currentUser.name}
            </Typography>
          </Typography>
        </Box>

        <Divider sx={{ mb: 1 }} />

        <CustomMenuItem
          onClick={editProfileHandler}
          icon={<ManageAccountsOutlined fontSize="medium" color="primary" />}
          text="Profile"
        />
        <CustomMenuItem
          onClick={signOutHandler}
          icon={<Logout fontSize="medium" color="error" />}
          text="Logout"
        />
      </Menu>
      <UserModal />
    </>
  );
};

export default ProfileMenu;
