import {
  useEffect,
  useState,
  useMemo,
  forwardRef,
  useImperativeHandle,
} from "react";
import { Autocomplete, FormControl, Grid, TextField } from "@mui/material";
import { getHourFromDatetime } from "../../services/helpers";
import { useServices } from "../../context/service-context";
import { useSearchParams } from "react-router-dom";

const ServicesFilter = forwardRef((props, ref) => {
  const { onSearchHandler } = props;
  const [queryParams] = useSearchParams();
  const { services } = useServices();
  const [selectedLocation, setSelectedLocation] = useState("All");
  const [selectedStep, setSelectedStep] = useState("All");
  const [selectedType, setSelectedType] = useState("All");
  const [selectedTime, setSelectedTime] = useState("All");

  useImperativeHandle(ref, () => ({
    resetFilter: () => {
      setSelectedLocation("All");
      setSelectedStep("All");
      setSelectedType("All");
      setSelectedTime("All");
    },
  }));

  useEffect(() => {
    if (services.length > 0) {
      if (queryParams.get("prog"))
        setSelectedStep(queryParams.get("prog").replaceAll("_", " "));
      if (queryParams.get("type"))
        setSelectedType(queryParams.get("type").replaceAll("_", " "));
      if (queryParams.get("time"))
        setSelectedTime(queryParams.get("time").replaceAll("_", " "));
      if (queryParams.get("loc"))
        setSelectedLocation(queryParams.get("loc").replaceAll("_", " "));
    }
    // eslint-disable-next-line
  }, [services]);

  useEffect(() => {
    onSearchHandler({
      location: selectedLocation,
      step: selectedStep,
      type: selectedType,
      time: selectedTime,
    });
    // eslint-disable-next-line
  }, [selectedLocation, selectedStep, selectedType, selectedTime]);

  const filtersConfig = useMemo(
    () => ({
      locations: [
        ...new Set(
          services
            ?.filter((item) =>
              selectedStep !== "All" ? item.step === selectedStep : true
            )
            ?.filter((item) =>
              selectedType !== "All" ? item.machine_type === selectedType : true
            )
            ?.filter((item) => {
              const hour = getHourFromDatetime(item.created_at);
              if (selectedTime === "Within 4 hours") return hour <= 4;
              if (selectedTime === "Within 4 to 12 hours")
                return hour > 4 && hour <= 12;
              if (selectedTime === "More than 12 hours") return hour > 12;
              return true;
            })
            ?.map((item) => item.location.name)
        ),
      ],
      steps: [
        ...new Set(
          services
            ?.filter((item) =>
              selectedLocation !== "All"
                ? item.location.name === selectedLocation
                : true
            )
            ?.filter((item) =>
              selectedType !== "All" ? item.machine_type === selectedType : true
            )
            ?.filter((item) => {
              const hour = getHourFromDatetime(item.created_at);
              if (selectedTime === "Within 4 hours") return hour <= 4;
              if (selectedTime === "Within 4 to 12 hours")
                return hour > 4 && hour <= 12;
              if (selectedTime === "More than 12 hours") return hour > 12;
              return true;
            })
            ?.map((item) => item.step)
        ),
      ],
      types: [
        ...new Set(
          services
            ?.filter((item) =>
              selectedLocation !== "All"
                ? item.location.name === selectedLocation
                : true
            )
            ?.filter((item) =>
              selectedStep !== "All" ? item.step === selectedStep : true
            )
            ?.filter((item) => {
              const hour = getHourFromDatetime(item.created_at);
              if (selectedTime === "Within 4 hours") return hour <= 4;
              if (selectedTime === "Within 4 to 12 hours")
                return hour > 4 && hour <= 12;
              if (selectedTime === "More than 12 hours") return hour > 12;
              return true;
            })
            ?.map((item) => item.machine_type)
        ),
      ],
      time: [
        ...new Set(
          services
            ?.filter((item) =>
              selectedLocation !== "All"
                ? item.location.name === selectedLocation
                : true
            )
            ?.filter((item) =>
              selectedStep !== "All" ? item.step === selectedStep : true
            )
            ?.filter((item) =>
              selectedType !== "All" ? item.machine_type === selectedType : true
            )
            ?.map((item) => {
              const hour = getHourFromDatetime(item.created_at);
              if (hour <= 4) return "Within 4 hours";
              if (hour > 4 && hour <= 12) return "Within 4 to 12 hours";
              if (hour > 12) return "More than 12 hours";
              return false;
            })
        ),
      ],
    }),
    // eslint-disable-next-line
    [services, selectedLocation, selectedStep, selectedType, selectedTime]
  );

  return (
    <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
      <Grid item xs={12} sm={3}>
        <FormControl fullWidth>
          <Autocomplete
            id="locations"
            options={["All", ...filtersConfig.locations]}
            value={
              ["All", ...filtersConfig.locations].find(
                (location) => location === selectedLocation
              ) || null
            }
            renderInput={(params) => (
              <TextField {...params} label="Location *" />
            )}
            onChange={(event, newOption) =>
              setSelectedLocation(newOption ?? null)
            }
            disableClearable
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={3}>
        <FormControl fullWidth>
          <Autocomplete
            id="step"
            options={["All", ...filtersConfig.steps]}
            value={
              ["All", ...filtersConfig.steps].find(
                (item) => item === selectedStep
              ) || null
            }
            renderInput={(params) => <TextField {...params} label="Step *" />}
            onChange={(event, newOption) => setSelectedStep(newOption)}
            disableClearable
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={3}>
        <FormControl fullWidth>
          <Autocomplete
            id="type"
            options={["All", ...filtersConfig.types]}
            value={
              ["All", ...filtersConfig.types].find(
                (item) => item === selectedType
              ) || null
            }
            renderInput={(params) => <TextField {...params} label="Type *" />}
            onChange={(event, newOption) => setSelectedType(newOption)}
            disableClearable
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={3}>
        <FormControl fullWidth>
          <Autocomplete
            id="time"
            options={["All", ...filtersConfig.time]}
            value={
              ["All", ...filtersConfig.time].find(
                (item) => item === selectedTime
              ) || null
            }
            renderInput={(params) => <TextField {...params} label="Time *" />}
            onChange={(event, newOption) => setSelectedTime(newOption)}
            disableClearable
          />
        </FormControl>
      </Grid>
    </Grid>
  );
});

export default ServicesFilter;
