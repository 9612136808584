import {
  AdfScannerOutlined,
  CheckOutlined,
  ClearOutlined,
  Close,
} from "@mui/icons-material";
import {
  Autocomplete,
  Button,
  DialogContentText,
  FormControl,
  Grid,
  InputAdornment,
  TextField,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useCabinets } from "../../context/cabinet-context";
import { useManufacturers } from "../../context/manufacturer-context";
import { useModal } from "../../context/modal-context";
import useInput from "../../hooks/use-input";
import { getTitleForModal } from "../../services/helpers";
import NTSModal from "../UI/NTSModal";
import NTSModalFooter from "../UI/NTSModalFooter";
import NTSUploadButton from "../UI/NTSUploadButton";

const CabinetModal = () => {
  const [picture, setPicture] = useState(null);
  const { modalState, closeModal } = useModal();
  const { cabinets, addCabinet, editCabinet, removeCabinet } = useCabinets();
  const {
    manufacturers,
    loadManufacturers,
    loading: manufacturersLoading,
  } = useManufacturers();

  const {
    value: manufacturerValue,
    isValid: manufacturerIsValid,
    hasError: manufacturerHasError,
    valueBlurHandler: manufacturerBlurHandler,
    valueResetHandler: manufacturerResetHandler,
    setValueHandler: setManufacturerHandler,
  } = useInput((value) => value !== "");
  const {
    value: nameValue,
    isValid: nameIsValid,
    hasError: nameHasError,
    valueChangeHandler: nameChangeHandler,
    valueBlurHandler: nameBlurHandler,
    valueResetHandler: nameResetHandler,
    setValueHandler: setNameHandler,
  } = useInput((value) => value.trim().length >= 5);

  const resetForm = useCallback(() => {
    nameResetHandler();
    manufacturerResetHandler();
    setPicture(null);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    resetForm();
    if (!modalState.type || modalState.model !== "cabinet") return;
    loadManufacturers();
    if (modalState.type === "add") return;

    const cabinet = cabinets.find((cabinet) => cabinet.id === modalState.id);
    setNameHandler(cabinet?.name);
    setManufacturerHandler(cabinet?.manufacturer?.id);
    // eslint-disable-next-line
  }, [modalState]);

  const onUpload = (event) => {
    setPicture(event.target.files[0]);
  };

  const submitHandler = (event) => {
    event.preventDefault();
    closeModal();
    const cabinetObj = {
      manufacturer_id: manufacturerValue,
      name: nameValue,
      picture,
    };
    if (modalState.type === "add") {
      addCabinet(cabinetObj);
    }
    if (modalState.type === "edit") {
      editCabinet(modalState.id, cabinetObj);
    }
    if (modalState.type === "delete") {
      removeCabinet(modalState.id);
    }
    resetForm();
  };

  let title = "";
  let buttons = (
    <>
      <Button onClick={closeModal} endIcon={<ClearOutlined />} color="error">
        Cancel
      </Button>
      <Button
        onClick={submitHandler}
        disabled={
          !nameIsValid ||
          !manufacturerIsValid ||
          (!picture && modalState.type === "add")
        }
        endIcon={<CheckOutlined />}
        color="success"
      >
        Submit
      </Button>
    </>
  );

  if (modalState.open && modalState.type) {
    title = getTitleForModal(modalState.type);
  }
  if (modalState.type === "view") {
    buttons = (
      <Button onClick={closeModal} endIcon={<Close />} color="primary">
        Close
      </Button>
    );
  }

  return (
    modalState.open &&
    modalState.model === "cabinet" && (
      <NTSModal
        title={`${title} CABINET`}
        loading={manufacturersLoading}
        loadingSx={{ marginBottom: 5 }}
        maxWidth="sm"
      >
        {modalState.type === "delete" ? (
          <DialogContentText>{`Are you sure that you want to remove cabinet ${nameValue}?`}</DialogContentText>
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth sx={{ mt: 2 }}>
                <Autocomplete
                  id="manufacturer"
                  options={manufacturers}
                  value={
                    manufacturers.find(
                      (manufacturer) => manufacturer.id === manufacturerValue
                    ) || null
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={manufacturerHasError}
                      label="Manufacturer *"
                    />
                  )}
                  getOptionLabel={(option) => option.show_name ?? option.name}
                  onChange={(event, newOption) =>
                    setManufacturerHandler(newOption?.id ?? null)
                  }
                  onClose={manufacturerBlurHandler}
                  disabled={manufacturersLoading}
                  required
                  disableClearable
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="name"
                label="Name"
                name="name"
                disabled={modalState.type === "view"}
                value={nameValue}
                onChange={nameChangeHandler}
                onBlur={nameBlurHandler}
                error={nameHasError}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AdfScannerOutlined />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              {(modalState.type === "add" || modalState.type === "edit") && (
                <NTSUploadButton
                  required={modalState.type === "add"}
                  uploaded={picture}
                  onUpload={onUpload}
                  buttonName={`${
                    modalState.type === "add" ? "Upload" : "Change"
                  } picture`}
                  modalType={modalState.type}
                />
              )}
            </Grid>
          </Grid>
        )}
        <NTSModalFooter
          sx={{
            display: "flex",
            justifyContent: "end",
            marginY: 2,
          }}
        >
          {buttons}
        </NTSModalFooter>
      </NTSModal>
    )
  );
};

export default CabinetModal;
