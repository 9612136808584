import { useTheme } from "@emotion/react";
import { SendOutlined } from "@mui/icons-material";
import {
  Box,
  IconButton,
  Paper,
  Stack,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect } from "react";
import { useState, useRef } from "react";
import { useAuth } from "../../../context/auth-context";
import { useServices } from "../../../context/service-context";
import useInput from "../../../hooks/use-input";
import { getReadableDateWithHours } from "../../../services/helpers";

const ChatBubble = styled(Paper)(({ theme, side }) => ({
  display: "inline-block",
  minWidth: "70%",
  maxWidth: "70%",
  padding: "10px 15px",
  borderRadius: "10px",
  backgroundColor:
    side === "left" ? theme.palette.primary.light : theme.palette.success.light,
  color: "#fff",
  fontSize: "16px",
  position: "relative",
  marginBottom: "10px",
  "&::before": {
    content: `""`,
    position: "absolute",
    width: 0,
    height: 0,
    top: "0",
    ...(side === "left"
      ? {
          borderLeft: "15px solid transparent",
          borderRight: `15px solid ${
            side === "left"
              ? theme.palette.primary.light
              : theme.palette.success.light
          }`,
          borderTop: ` 15px solid ${
            side === "left"
              ? theme.palette.primary.light
              : theme.palette.success.light
          }`,
          borderBottom: "15px solid transparent",
          left: "-16px",
        }
      : {
          borderLeft: `15px solid ${
            side === "left"
              ? theme.palette.primary.light
              : theme.palette.success.light
          }`,
          borderRight: "15px solid transparent",
          borderTop: ` 15px solid ${
            side === "left"
              ? theme.palette.primary.light
              : theme.palette.success.light
          }`,
          borderBottom: "15px solid transparent",
          right: "-16px",
        }),
  },
}));

const Avatar = styled(Box)(({ theme, side }) => ({
  width: "40px",
  height: "40px",
  borderRadius: "50%",
  backgroundColor:
    side === "left" ? theme.palette.primary.light : theme.palette.success.light,
  color: "#fff",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  fontSize: "20px",
  fontWeight: 500,
  ...(side === "left"
    ? {
        marginRight: "15px",
      }
    : {
        marginLeft: "15px",
      }),
}));

const TimelineTabContent = (props) => {
  const theme = useTheme();
  const messagesEndRef = useRef();
  const { serviceConversation, serviceId } = props;
  const { currentUser } = useAuth();
  const { addServiceConversation } = useServices();
  const [innerLoading, setInnerLoading] = useState(false);

  const {
    value: messageValue,
    isValid: messageIsValid,
    valueChangeHandler: messageChangeHandler,
    valueResetHandler: messageResetHandler,
  } = useInput((value) => value.trim().length >= 1);

  const submitHandler = () => {
    setInnerLoading(true);
    addServiceConversation(serviceId, { message: messageValue });
    messageResetHandler();
    setInnerLoading(false);
  };

  useEffect(() => {
    messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
  }, [serviceConversation.length]);

  return (
    <Stack direction="column" sx={{ height: "100%" }}>
      <Stack
        direction="column"
        spacing={1}
        sx={{
          marginX: "8px",
          flex: 1,
          maxHeight: "300px",
          overflow: "auto",
        }}
      >
        {serviceConversation.map((messageDetails, index) => {
          const isMessageFromCurrentUser =
            currentUser.id === messageDetails.user_id;
          return (
            <Stack key={index} direction="row">
              {!isMessageFromCurrentUser && (
                <Avatar side={isMessageFromCurrentUser ? "right" : "left"}>
                  {messageDetails.user_details[0]}
                </Avatar>
              )}
              <Stack
                direction="column"
                alignItems={isMessageFromCurrentUser ? "end" : "start"}
                sx={{ flex: 1, paddingTop: "5px" }}
              >
                <ChatBubble side={isMessageFromCurrentUser ? "right" : "left"}>
                  {messageDetails.message}
                </ChatBubble>
                <Typography
                  variant="body2"
                  sx={{ color: theme.palette.text.secondary }}
                >
                  {messageDetails.user_details} -{" "}
                  {getReadableDateWithHours(messageDetails.created_at)}
                </Typography>
              </Stack>
              {isMessageFromCurrentUser && (
                <Avatar side={isMessageFromCurrentUser ? "right" : "left"}>
                  {messageDetails.user_details[0]}
                </Avatar>
              )}
            </Stack>
          );
        })}
        <div ref={messagesEndRef} />
      </Stack>
      <TextField
        fullWidth
        name="message"
        label="Send a message"
        type="text"
        id="message"
        variant="outlined"
        sx={{ marginTop: "10px" }}
        disabled={innerLoading}
        value={messageValue}
        onChange={messageChangeHandler}
        InputProps={{
          endAdornment: (
            <IconButton
              position="end"
              color="primary"
              disabled={innerLoading || !messageIsValid}
              onClick={submitHandler}
            >
              <SendOutlined />
            </IconButton>
          ),
        }}
      />
    </Stack>
  );
};

export default TimelineTabContent;
