import {
  CheckOutlined,
  ClearOutlined,
  Close,
  RouterOutlined,
} from "@mui/icons-material";
import {
  Button,
  DialogContentText,
  InputAdornment,
  TextField,
} from "@mui/material";
import { useCallback, useEffect } from "react";
import { useDevices } from "../../context/device-context";
import { useModal } from "../../context/modal-context";
import useInput from "../../hooks/use-input";
import { getTitleForModal } from "../../services/helpers";
import NTSModal from "../UI/NTSModal";
import NTSModalFooter from "../UI/NTSModalFooter";

const DeviceTypeModal = () => {
  const { modalState, closeModal } = useModal();
  const { deviceTypes, addDeviceType, editDeviceType, removeDeviceType } =
    useDevices();

  const {
    value: nameValue,
    isValid: nameIsValid,
    hasError: nameHasError,
    valueChangeHandler: nameChangeHandler,
    valueBlurHandler: nameBlurHandler,
    valueResetHandler: nameResetHandler,
    setValueHandler: setNameHandler,
  } = useInput((value) => value.trim().length > 3);

  const resetForm = useCallback(() => {
    nameResetHandler();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    resetForm();
    if (
      !modalState.type ||
      modalState.type === "add" ||
      modalState.model !== "deviceType"
    )
      return;

    const deviceType = deviceTypes.find((type) => type.id === modalState.id);
    setNameHandler(deviceType?.name);
    // eslint-disable-next-line
  }, [modalState]);

  const submitHandler = (event) => {
    event.preventDefault();
    closeModal();
    const deviceTypeObj = {
      name: nameValue,
    };
    if (modalState.type === "add") {
      addDeviceType(deviceTypeObj);
    }
    if (modalState.type === "edit") {
      editDeviceType(modalState.id, deviceTypeObj);
    }
    if (modalState.type === "delete") {
      removeDeviceType(modalState.id);
    }
    resetForm();
  };

  let title = "";
  let buttons = (
    <>
      <Button onClick={closeModal} endIcon={<ClearOutlined />} color="error">
        Cancel
      </Button>
      <Button
        onClick={submitHandler}
        disabled={!nameIsValid}
        endIcon={<CheckOutlined />}
        color="success"
      >
        Submit
      </Button>
    </>
  );

  if (modalState.open && modalState.type) {
    title = getTitleForModal(modalState.type);
  }
  if (modalState.type === "view") {
    buttons = (
      <Button onClick={closeModal} endIcon={<Close />} color="primary">
        Close
      </Button>
    );
  }

  return (
    modalState.open &&
    modalState.model === "deviceType" && (
      <NTSModal title={`${title} DEVICE TYPE`} maxWidth="xs">
        {modalState.type === "delete" ? (
          <DialogContentText>{`Are you sure that you want to remove device type ${nameValue}?`}</DialogContentText>
        ) : (
          <TextField
            margin="normal"
            required
            fullWidth
            id="name"
            label="Device type name"
            name="name"
            disabled={modalState.type === "view"}
            value={nameValue}
            onChange={nameChangeHandler}
            onBlur={nameBlurHandler}
            error={nameHasError}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <RouterOutlined />
                </InputAdornment>
              ),
            }}
          />
        )}
        <NTSModalFooter
          sx={{
            display: "flex",
            justifyContent: "end",
            marginY: 2,
          }}
        >
          {buttons}
        </NTSModalFooter>
      </NTSModal>
    )
  );
};

export default DeviceTypeModal;
