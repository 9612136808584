import { createContext, useContext, useState } from "react";

export const SnackbarContext = createContext({
  snackbarState: {
    open: true,
    message: "",
    severity: "",
    color: "",
  },
  showSnackbar: (message, severity = "success", color = "green") => {},
  resetSnackbar: () => {},
});

export const SnackbarContextProvider = (props) => {
  const [snackbarState, setSnackbarState] = useState({
    open: false,
    message: null,
    severity: null,
    color: null,
  });

  const showSnackbar = (message, severity = "success", color = "green") => {
    setSnackbarState({
      open: true,
      message,
      severity,
      color,
    });
  };

  const resetSnackbar = () => {
    setSnackbarState({
      open: false,
      message: null,
      severity: null,
      color: null,
    });
  };

  const ctxValue = {
    snackbarState,
    showSnackbar,
    resetSnackbar,
  };

  return (
    <SnackbarContext.Provider value={ctxValue}>
      {props.children}
    </SnackbarContext.Provider>
  );
};

export const useSnackbar = () => useContext(SnackbarContext);
