import { useCallback, createContext, useContext, useReducer } from "react";
import httpReducer from "../reducers/http-reducer";
import {
  createSparePart,
  deleteSparePart,
  getSpareParts,
  updateSparePart,
} from "../services/api-service";
import { useSnackbar } from "./snackbar-context";

export const SparePartContext = createContext({
  spareParts: [],
  loading: false,
  loadSpareParts: () => {},
  addSparePart: (sparePartObj) => {},
  editSparePart: (id, sparePartObj) => {},
  removeSparePart: (id) => {},
});

export const SparePartContextProvider = (props) => {
  const { showSnackbar, resetSnackbar } = useSnackbar();
  const [state, dispatch] = useReducer(httpReducer, {
    data: [],
    loading: false,
  });

  const loadSpareParts = useCallback(async () => {
    dispatch({ type: "START_LOAD" });
    resetSnackbar();
    try {
      const response = await getSpareParts();
      const sparePartsData = response.data.data;
      dispatch({ type: "ADD_DATA", data: sparePartsData });
    } catch (error) {
      dispatch({ type: "END_LOAD" });
      const errorMessage = error.response?.data?.message || error.message;
      showSnackbar(errorMessage, "error", "red");
    }
    // eslint-disable-next-line
  }, []);

  const addSparePart = async (sparePartObj) => {
    dispatch({ type: "START_LOAD" });
    resetSnackbar();
    try {
      const response = await createSparePart(sparePartObj);
      const sparePart = response.data.data;
      dispatch({ type: "ADD_ITEM", item: sparePart });
      const message = response.data.message;
      showSnackbar(message, "success", "green");
    } catch (error) {
      dispatch({ type: "END_LOAD" });
      const errorMessage = error.response?.data?.message || error.message;
      showSnackbar(errorMessage, "error", "red");
    }
  };

  const editSparePart = async (id, sparePartObj) => {
    dispatch({ type: "START_LOAD" });
    resetSnackbar();
    try {
      const response = await updateSparePart(id, sparePartObj);
      const sparePart = response.data.data;
      dispatch({ type: "EDIT_ITEM", id: id, data: sparePart });
      const message = response.data.message;
      showSnackbar(message, "success", "green");
    } catch (error) {
      dispatch({ type: "END_LOAD" });
      const errorMessage = error.response?.data?.message || error.message;
      showSnackbar(errorMessage, "error", "red");
    }
  };

  const removeSparePart = async (id) => {
    dispatch({ type: "START_LOAD" });
    resetSnackbar();
    try {
      const response = await deleteSparePart(id);
      dispatch({ type: "REMOVE_ITEM", id: id });
      const message = response.data.message;
      showSnackbar(message, "success", "green");
    } catch (error) {
      dispatch({ type: "END_LOAD" });
      const errorMessage = error.response?.data?.message || error.message;
      showSnackbar(errorMessage, "error", "red");
    }
  };

  const ctxValue = {
    spareParts: state.data,
    loading: state.loading,
    loadSpareParts,
    addSparePart,
    editSparePart,
    removeSparePart,
  };

  return (
    <SparePartContext.Provider value={ctxValue}>
      {props.children}
    </SparePartContext.Provider>
  );
};

export const useSpareParts = () => useContext(SparePartContext);
