import { Container } from "@mui/material";
import { useEffect } from "react";
import DashboardServiceFilters from "../components/Dashboard/DashboardServiceFilters";
import CRUDHeader from "../components/UI/CRUDHeader";
import { useServices } from "../context/service-context";

const Dashboard = () => {
  const { loadActiveServices } = useServices();

  useEffect(() => {
    loadActiveServices();
    // eslint-disable-next-line
  }, []);

  return (
    <Container maxWidth="xl" sx={{ paddingBottom: "16px" }}>
      <CRUDHeader title="Dashboard" />
      <DashboardServiceFilters />
    </Container>
  );
};

export default Dashboard;
