import { Stack } from "@mui/material";
import ConfirmAction from "./StepActions/ConfirmAction";
import FinishAction from "./StepActions/FinishAction";
import InProgressAction from "./StepActions/InProgressAction";
import WaitingAction from "./StepActions/WaitingAction";

const ActionsTabContent = (props) => {
  const { serviceStep, serviceId, errorDescription, onEditService, loading } =
    props;

  return (
    <Stack direction="column" sx={{ height: "100%" }}>
      {(() => {
        switch (serviceStep) {
          case "Open":
            return (
              <WaitingAction onEditService={onEditService} loading={loading} />
            );
          case "Waiting":
            return (
              <InProgressAction
                onEditService={onEditService}
                loading={loading}
              />
            );
          case "In Progress":
            return (
              <FinishAction
                serviceId={serviceId}
                onEditService={onEditService}
                errorDescription={errorDescription}
                loading={loading}
              />
            );
          case "Closed":
            return (
              <ConfirmAction onEditService={onEditService} loading={loading} />
            );
          default:
            return <></>;
        }
      })()}
    </Stack>
  );
};

export default ActionsTabContent;
