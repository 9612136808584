import {
  CheckOutlined,
  ClearOutlined,
  Close,
  Diversity2Outlined,
  PersonOutline,
  PlaceOutlined,
} from "@mui/icons-material";
import {
  Button,
  DialogContentText,
  InputAdornment,
  TextField,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useGroups } from "../../context/group-context";
import { useLocations } from "../../context/location-context";
import { useModal } from "../../context/modal-context";
import { useSnackbar } from "../../context/snackbar-context";
import useInput from "../../hooks/use-input";
import { getTechnicians } from "../../services/api-service";
import { getTitleForModal } from "../../services/helpers";
import NTSModal from "../UI/NTSModal";
import NTSModalFooter from "../UI/NTSModalFooter";
import NTSMultiSelect from "../UI/NTSMultiSelect";

const GroupModal = () => {
  const { modalState, closeModal } = useModal();
  const { showSnackbar } = useSnackbar();
  const { locations, locationsLoading, loadLocations } = useLocations();
  const {
    groups,
    addGroup,
    editGroup,
    removeGroup,
    removeLocationFromGroup,
    removeTechnicianFromGroup,
    addTechnicianToGroup,
    addLocationToGroup,
  } = useGroups();

  const [technicians, setTechnicians] = useState([]);
  const [assignedTechnicians, setAssignedTechnicians] = useState([]);
  const [assignedLocations, setAssignedLocations] = useState([]);
  const [techniciansLoading, setTechniciansLoading] = useState(false);
  const {
    value: groupNameValue,
    isValid: groupNameIsValid,
    hasError: groupNameHasError,
    valueChangeHandler: groupNameChangeHandler,
    valueBlurHandler: groupNameBlurHandler,
    valueResetHandler: groupNameResetHandler,
    setValueHandler: setGroupNameHandler,
  } = useInput((value) => value.trim().length > 1);
  const {
    isValid: locationIsValid,
    hasError: locationHasError,
    valueBlurHandler: locationBlurHandler,
    valueResetHandler: locationResetHandler,
  } = useInput(() => assignedLocations.length > 0);
  const {
    isValid: technicianIsValid,
    hasError: technicianHasError,
    valueBlurHandler: technicianBlurHandler,
    valueResetHandler: technicianResetHandler,
  } = useInput((value) => assignedTechnicians.length > 0);

  const loadTechnicians = useCallback(async () => {
    setTechniciansLoading(true);
    try {
      const response = await getTechnicians();
      setTechnicians(response.data.data);
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      showSnackbar(errorMessage, "error", "red");
    }
    setTechniciansLoading(false);
    // eslint-disable-next-line
  }, []);

  const resetForm = useCallback(() => {
    setAssignedLocations([]);
    setAssignedTechnicians([]);
    locationResetHandler();
    technicianResetHandler();
    groupNameResetHandler();

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    resetForm();
    if (
      !modalState.type ||
      modalState.type === "add" ||
      modalState.model !== "group"
    )
      return;
    if (modalState.type === "location") loadLocations();
    if (modalState.type === "technician") loadTechnicians();
    const group = groups.find((group) => group.id === modalState.id);
    setGroupNameHandler(group?.show_name);
    // eslint-disable-next-line
  }, [modalState]);

  const submitHandler = (event) => {
    event.preventDefault();
    if (modalState.type === "location") {
      const locationObj = {
        location_ids: assignedLocations.map((location) => location.id),
      };
      addLocationToGroup(modalState.id, locationObj);
    }
    if (modalState.type === "technician") {
      const technicianObj = {
        user_ids: assignedTechnicians.map((technician) => technician.id),
      };
      addTechnicianToGroup(modalState.id, technicianObj);
    }
    if (modalState.type !== "location" && modalState.type !== "technician") {
      const groupObj = {
        show_name: groupNameValue,
      };
      if (modalState.type === "add") {
        addGroup(groupObj);
      }
      if (modalState.type === "edit") {
        editGroup(modalState.id, groupObj);
      }
      if (modalState.type === "delete") {
        if (modalState?.additional?.type === "location") {
          const locationObj = {
            location_id: modalState.additional.details.id,
          };
          removeLocationFromGroup(modalState.id, locationObj);
        } else if (modalState?.additional?.type === "technician") {
          const technicianObj = {
            user_id: modalState.additional.details.id,
          };
          removeTechnicianFromGroup(modalState.id, technicianObj);
        } else {
          removeGroup(modalState.id);
        }
      }
    }
    resetForm();
    closeModal();
  };

  let title = "";
  let buttons = (
    <>
      <Button onClick={closeModal} endIcon={<ClearOutlined />} color="error">
        Cancel
      </Button>
      <Button
        onClick={submitHandler}
        disabled={
          (modalState.type !== "technician" &&
            modalState.type !== "location" &&
            !groupNameIsValid) ||
          (modalState.type === "technician" && !technicianIsValid) ||
          (modalState.type === "location" && !locationIsValid)
        }
        endIcon={<CheckOutlined />}
        color="success"
      >
        Submit
      </Button>
    </>
  );

  if (modalState.open && modalState.type) {
    title = getTitleForModal(modalState.type);
  }
  if (modalState.type === "view") {
    buttons = (
      <Button onClick={closeModal} endIcon={<Close />} color="primary">
        Close
      </Button>
    );
  }

  if (modalState.open && modalState?.additional?.type) {
    return (
      <NTSModal
        title={`${title} ${modalState.additional.type.toUpperCase()}`}
        maxWidth="xs"
      >
        <DialogContentText>{`Are you sure that you want to remove ${modalState.additional.type} ${modalState.additional.details.name} from ${groupNameValue}?`}</DialogContentText>
        <NTSModalFooter
          sx={{
            display: "flex",
            justifyContent: "end",
            marginY: 2,
          }}
        >
          {buttons}
        </NTSModalFooter>
      </NTSModal>
    );
  }

  return (
    modalState.open &&
    modalState.model === "group" && (
      <NTSModal title={`${title} GROUP`} maxWidth="sm">
        {modalState.type === "delete" ? (
          <DialogContentText>{`Are you sure that you want to remove group ${groupNameValue}?`}</DialogContentText>
        ) : (
          modalState.type !== "location" &&
          modalState.type !== "technician" && (
            <TextField
              margin="normal"
              required
              fullWidth
              id="name"
              label="Group name"
              name="name"
              disabled={modalState.type === "view"}
              value={groupNameValue}
              onChange={groupNameChangeHandler}
              onBlur={groupNameBlurHandler}
              error={groupNameHasError}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Diversity2Outlined />
                  </InputAdornment>
                ),
              }}
            />
          )
        )}

        {modalState.type === "location" && (
          <NTSMultiSelect
            color="success"
            disabled={locationsLoading}
            assigns={assignedLocations}
            onChange={setAssignedLocations}
            onBlur={locationBlurHandler}
            required
            error={locationHasError}
            values={locations.filter((location) => !location.location_group)}
            icon={<PlaceOutlined />}
            title="Locations *"
            sx={{ mt: 0 }}
          />
        )}
        {modalState.type === "technician" && (
          <NTSMultiSelect
            color="success"
            disabled={techniciansLoading}
            assigns={assignedTechnicians}
            onChange={setAssignedTechnicians}
            onBlur={technicianBlurHandler}
            required
            error={technicianHasError}
            values={technicians}
            icon={<PersonOutline />}
            title="Technicians *"
            sx={{ mt: 0 }}
          />
        )}
        <NTSModalFooter
          sx={{
            display: "flex",
            justifyContent: "end",
            marginY: 2,
          }}
        >
          {buttons}
        </NTSModalFooter>
      </NTSModal>
    )
  );
};

export default GroupModal;
