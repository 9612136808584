import { Container } from "@mui/material";
import { useEffect } from "react";
import AllDevices from "../components/Device/AllDevices";
import DeviceModal from "../components/Device/DeviceModal";
import DevicesFilter from "../components/Device/DevicesFilter";
import CRUDHeader from "../components/UI/CRUDHeader";
import { useDevices } from "../context/device-context";
import { useModal } from "../context/modal-context";

const Devices = () => {
  const { clearDevicesData, devices } = useDevices();
  const { openModal } = useModal();

  useEffect(() => {
    clearDevicesData();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Container maxWidth="sm" sx={{ paddingBottom: "16px" }}>
        <CRUDHeader
          title="Devices"
          onAddNew={() => openModal("add", "device")}
        />
        <DevicesFilter />
        {devices.length > 0 && <AllDevices />}
      </Container>
      <DeviceModal />
    </>
  );
};

export default Devices;
