import { useCallback, createContext, useContext, useReducer } from "react";
import httpReducer from "../reducers/http-reducer";
import {
  createUser,
  getUsers,
  updateUser,
  deleteUser,
  getUsersByFilter,
} from "../services/api-service";
import { useAuth } from "./auth-context";
import { useSnackbar } from "./snackbar-context";

export const UserContext = createContext({
  users: [],
  loading: false,
  loadUsers: () => {},
  searchUsers: (searchObj) => {},
  addUser: (userObj) => {},
  editUser: (id, userObj) => {},
  removeUser: (id) => {},
  clearUsersData: () => {},
});

export const UserContextProvider = (props) => {
  const { showSnackbar, resetSnackbar } = useSnackbar();
  const { currentUser, rewriteAuthUserAfterEdit } = useAuth();
  const [state, dispatch] = useReducer(httpReducer, {
    data: [],
    loading: false,
  });

  const loadUsers = useCallback(async () => {
    dispatch({ type: "START_LOAD" });
    resetSnackbar();
    try {
      const response = await getUsers();
      const usersData = response.data.data;
      dispatch({ type: "ADD_DATA", data: usersData });
    } catch (error) {
      dispatch({ type: "END_LOAD" });
      const errorMessage = error.response?.data?.message || error.message;
      showSnackbar(errorMessage, "error", "red");
    }
    // eslint-disable-next-line
  }, []);

  const searchUsers = async (searchObj) => {
    dispatch({ type: "START_LOAD" });
    resetSnackbar();
    try {
      const response = await getUsersByFilter(searchObj);
      const usersData = response.data.data;
      if (usersData.length < 1)
        showSnackbar(
          "No results found according to search criteria",
          "warning",
          "orange"
        );
      dispatch({ type: "ADD_DATA", data: usersData });
    } catch (error) {
      dispatch({ type: "END_LOAD" });
      const errorMessage = error.response?.data?.message || error.message;
      showSnackbar(errorMessage, "error", "red");
    }
  };

  const addUser = async (userObj) => {
    dispatch({ type: "START_LOAD" });
    resetSnackbar();
    try {
      const response = await createUser(userObj);
      dispatch({ type: "END_LOAD" });
      const message = response.data.message;
      showSnackbar(message, "success", "green");
    } catch (error) {
      dispatch({ type: "END_LOAD" });
      const errorMessage = error.response?.data?.message || error.message;
      showSnackbar(errorMessage, "error", "red");
    }
  };

  const editUser = async (id, userObj) => {
    dispatch({ type: "START_LOAD" });
    resetSnackbar();
    try {
      const response = await updateUser(id, userObj);
      const user = response.data.data;
      if (user.id === currentUser.id) rewriteAuthUserAfterEdit(user);
      dispatch({ type: "EDIT_ITEM", id: id, data: user });
      const message = response.data.message;
      showSnackbar(message, "success", "green");
    } catch (error) {
      dispatch({ type: "END_LOAD" });
      const errorMessage = error.response?.data?.message || error.message;
      showSnackbar(errorMessage, "error", "red");
    }
  };

  const removeUser = async (id) => {
    dispatch({ type: "START_LOAD" });
    resetSnackbar();
    try {
      const response = await deleteUser(id);
      dispatch({ type: "REMOVE_ITEM", id: id });
      const message = response.data.message;
      showSnackbar(message, "success", "green");
    } catch (error) {
      dispatch({ type: "END_LOAD" });
      const errorMessage = error.response?.data?.message || error.message;
      showSnackbar(errorMessage, "error", "red");
    }
  };

  const clearUsersData = useCallback(() => {
    dispatch({ type: "CLEAR_DATA" });
  }, []);

  const ctxValue = {
    users: state.data,
    loading: state.loading,
    loadUsers,
    searchUsers,
    addUser,
    editUser,
    removeUser,
    clearUsersData,
  };

  return (
    <UserContext.Provider value={ctxValue}>
      {props.children}
    </UserContext.Provider>
  );
};

export const useUsers = () => useContext(UserContext);
