import { useCallback, createContext, useContext, useReducer } from "react";
import httpReducer from "../reducers/http-reducer";
import {
  createCompany,
  getCompanies,
  updateCompany,
  deleteCompany,
} from "../services/api-service";
import { useSnackbar } from "./snackbar-context";

export const CompanyContext = createContext({
  companies: [],
  loading: false,
  loadCompanies: () => {},
  addCompany: (companyObj) => {},
  editCompany: (id, companyObj) => {},
  removeCompany: (id) => {},
});

export const CompanyContextProvider = (props) => {
  const { showSnackbar, resetSnackbar } = useSnackbar();
  const [state, dispatch] = useReducer(httpReducer, {
    data: [],
    loading: false,
  });

  const loadCompanies = useCallback(async () => {
    dispatch({ type: "START_LOAD" });
    resetSnackbar();
    try {
      const response = await getCompanies();
      const companiesData = response.data.data;
      dispatch({ type: "ADD_DATA", data: companiesData });
    } catch (error) {
      dispatch({ type: "END_LOAD" });
      const errorMessage = error.response?.data?.message || error.message;
      showSnackbar(errorMessage, "error", "red");
    }
    // eslint-disable-next-line
  }, []);

  const addCompany = async (companyObj) => {
    dispatch({ type: "START_LOAD" });
    resetSnackbar();
    try {
      const response = await createCompany(companyObj);
      const company = response.data.data;
      dispatch({ type: "ADD_ITEM", item: company });
      const message = response.data.message;
      showSnackbar(message, "success", "green");
    } catch (error) {
      dispatch({ type: "END_LOAD" });
      const errorMessage = error.response?.data?.message || error.message;
      showSnackbar(errorMessage, "error", "red");
    }
  };

  const editCompany = async (id, companyObj) => {
    dispatch({ type: "START_LOAD" });
    resetSnackbar();
    try {
      const response = await updateCompany(id, companyObj);
      const company = response.data.data;
      dispatch({ type: "EDIT_ITEM", id: id, data: company });
      const message = response.data.message;
      showSnackbar(message, "success", "green");
    } catch (error) {
      dispatch({ type: "END_LOAD" });
      const errorMessage = error.response?.data?.message || error.message;
      showSnackbar(errorMessage, "error", "red");
    }
  };

  const removeCompany = async (id) => {
    dispatch({ type: "START_LOAD" });
    resetSnackbar();
    try {
      const response = await deleteCompany(id);
      dispatch({ type: "REMOVE_ITEM", id: id });
      const message = response.data.message;
      showSnackbar(message, "success", "green");
    } catch (error) {
      dispatch({ type: "END_LOAD" });
      const errorMessage = error.response?.data?.message || error.message;
      showSnackbar(errorMessage, "error", "red");
    }
  };

  const ctxValue = {
    companies: state.data,
    loading: state.loading,
    loadCompanies,
    addCompany,
    editCompany,
    removeCompany,
  };

  return (
    <CompanyContext.Provider value={ctxValue}>
      {props.children}
    </CompanyContext.Provider>
  );
};

export const useCompanies = () => useContext(CompanyContext);
