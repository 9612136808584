import { SearchOutlined } from "@mui/icons-material";
import {
  Autocomplete,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  TextField,
} from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useState, useImperativeHandle, forwardRef } from "react";
import { useCompanies } from "../../context/company-context";
import { useLocations } from "../../context/location-context";
import { useMachines } from "../../context/machine-context";
import { useModal } from "../../context/modal-context";
import { TypeOfChanges } from "./TypeOfChanges";

const MachineChangesFilter = forwardRef((props, ref) => {
  const { onSearchComplete } = props;
  const { modalState } = useModal();
  const { searchMachines, loading: machinesLoading } = useMachines();
  const { companies, loading: companiesLoading } = useCompanies();
  const { locations, locationsLoading } = useLocations();

  const [dateFrom, setDateFrom] = useState(dayjs().subtract(1, "days"));
  const [dateTo, setDateTo] = useState(dayjs());
  const [selectedCompany, setSelectedCompany] = useState("all");
  const [selectedLocation, setSelectedLocation] = useState("all");
  const [machineNumber, setMachineNumber] = useState("");
  const [selectedType, setSelectedType] = useState(TypeOfChanges[0]);

  useImperativeHandle(ref, () => ({
    resetFilter: () => {
      setDateFrom(dayjs().subtract(1, "days"));
      setDateTo(dayjs());
      setSelectedCompany("all");
      setSelectedLocation("all");
      setMachineNumber("");
      setSelectedType(TypeOfChanges[0]);
    },
  }));

  const onSearchHandler = () => {
    const searchObj = {
      date_from: dateFrom,
      date_to: dateTo,
      company_id: selectedCompany !== "all" ? selectedCompany : null,
      location_id: selectedLocation !== "all" ? selectedLocation : null,
      machine_number: machineNumber.trim().length > 0 ? machineNumber : null,
      change_type: selectedType,
    };
    searchMachines(searchObj);
    onSearchComplete(selectedType);
  };

  const overallLoading =
    (companiesLoading || locationsLoading || machinesLoading) &&
    !modalState.open;

  return (
    <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
      <Grid item xs={12} md={3}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DesktopDatePicker
            label="Date From"
            inputFormat="DD/MM/YYYY"
            value={dateFrom}
            onChange={(e) => setDateFrom(e)}
            renderInput={(params) => (
              <TextField {...params} sx={{ width: "100%" }} />
            )}
            disabled={overallLoading}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={12} md={3}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DesktopDatePicker
            label="Date To"
            inputFormat="DD/MM/YYYY"
            value={dateTo}
            onChange={(e) => setDateTo(e)}
            renderInput={(params) => (
              <TextField {...params} sx={{ width: "100%" }} />
            )}
            disabled={overallLoading}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={12} md={3}>
        <FormControl fullWidth>
          <Autocomplete
            id="company"
            options={[{ id: "all", groupation_name: "All" }, ...companies]}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            value={
              [{ id: "all", groupation_name: "All" }, ...companies].find(
                (company) => company.id === selectedCompany
              ) || null
            }
            renderInput={(params) => <TextField {...params} label="Company" />}
            getOptionLabel={(option) => option.groupation_name}
            onChange={(event, newOption) => {
              setSelectedCompany(newOption?.id ?? null);
              setSelectedLocation("all");
            }}
            disableClearable
            disabled={overallLoading}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} md={3}>
        <FormControl fullWidth>
          <Autocomplete
            id="locations"
            options={[
              { id: "all", name: "All" },
              ...(selectedCompany !== "all"
                ? locations.filter(
                    (location) => location.company.id === selectedCompany
                  )
                : locations),
            ]}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            value={
              [
                { id: "all", name: "All" },
                ...(selectedCompany !== "all"
                  ? locations.filter(
                      (location) => location.company.id === selectedCompany
                    )
                  : locations),
              ].find((location) => location.id === selectedLocation) || null
            }
            renderInput={(params) => <TextField {...params} label="Location" />}
            getOptionLabel={(option) => option.show_name ?? option.name}
            onChange={(event, newOption) =>
              setSelectedLocation(newOption?.id ?? null)
            }
            disableClearable
            disabled={overallLoading}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} md={3}>
        <TextField
          fullWidth
          id="machine_number"
          label="Machine Number"
          name="machine_number"
          value={machineNumber}
          onChange={(e) => setMachineNumber(e.target.value)}
          disabled={overallLoading}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <FormControl fullWidth>
          <Autocomplete
            id="types"
            options={[...TypeOfChanges]}
            value={selectedType}
            renderInput={(params) => <TextField {...params} label="Type" />}
            onChange={(event, newOption) => setSelectedType(newOption ?? null)}
            disableClearable
            disabled={overallLoading}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} md={3}>
        <Button
          variant="contained"
          sx={{ width: "100%", height: "100%" }}
          startIcon={!overallLoading && <SearchOutlined />}
          onClick={onSearchHandler}
          disabled={overallLoading}
        >
          {overallLoading ? <CircularProgress size={25} /> : "Search"}
        </Button>
      </Grid>
    </Grid>
  );
});

export default MachineChangesFilter;
