import {
  CheckOutlined,
  ClearOutlined,
  Close,
  ConfirmationNumberOutlined,
  NumbersOutlined,
  PercentOutlined,
  SmsOutlined,
  WebAssetOutlined,
} from "@mui/icons-material";
import {
  Autocomplete,
  Button,
  DialogContentText,
  FormControl,
  Grid,
  InputAdornment,
  Stack,
  TextField,
} from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useCallback, useEffect, useState } from "react";
import { useCabinets } from "../../context/cabinet-context";
import { useCompanies } from "../../context/company-context";
import { useLocations } from "../../context/location-context";
import { useMachines } from "../../context/machine-context";
import { useModal } from "../../context/modal-context";
import { useParts } from "../../context/part-context";
import { useSnackbar } from "../../context/snackbar-context";
import useInput from "../../hooks/use-input";
import { getMachine } from "../../services/api-service";
import { getTitleForModal } from "../../services/helpers";
import NTSModal from "../UI/NTSModal";
import NTSModalFooter from "../UI/NTSModalFooter";
import { MachineTypes } from "./MachineTypes";

const MachineModal = () => {
  const { modalState, closeModal } = useModal();
  const { showSnackbar } = useSnackbar();
  const { machines, addMachine, editMachine, removeMachine } = useMachines();
  const { companies, loading: companiesLoading } = useCompanies();
  const { locations, locationsLoading, loadLocations } = useLocations();
  const { cabinets, loading: cabinetsLoading, loadCabinet } = useCabinets();
  const { parts, partsLoading, partTypes, partTypesLoading } = useParts();
  const [innerLoading, setInnerLoading] = useState(false);
  const [imageSrc, setImageSrc] = useState(null);
  const [selectedParts, setSelectedParts] = useState([]);
  const [requiredPartsDetails, setRequiredPartsDetails] = useState([
    {
      name: "Game",
      error: false,
      valid: false,
    },
    {
      name: "Version",
      error: false,
      valid: false,
    },
    {
      name: "Kernel",
      error: false,
      valid: false,
    },
  ]);
  const {
    value: ownershipValue,
    isValid: ownershipIsValid,
    hasError: ownershipHasError,
    valueBlurHandler: ownershipBlurHandler,
    valueResetHandler: ownershipResetHandler,
    setValueHandler: setOwnershipHandler,
  } = useInput((value) => value !== "");
  const {
    value: typeValue,
    isValid: typeIsValid,
    hasError: typeHasError,
    valueBlurHandler: typeBlurHandler,
    valueResetHandler: typeResetHandler,
    setValueHandler: setTypeHandler,
  } = useInput((value) => value !== "");
  const {
    value: cabinetValue,
    isValid: cabinetIsValid,
    hasError: cabinetHasError,
    valueBlurHandler: cabinetBlurHandler,
    valueResetHandler: cabinetResetHandler,
    setValueHandler: setCabinetHandler,
  } = useInput((value) => value !== "");
  const {
    value: locationValue,
    isValid: locationIsValid,
    hasError: locationHasError,
    valueBlurHandler: locationBlurHandler,
    valueResetHandler: locationResetHandler,
    setValueHandler: setLocationHandler,
  } = useInput((value) => value !== "");
  const {
    value: machineNumberValue,
    isValid: machineNumberIsValid,
    hasError: machineNumberHasError,
    valueChangeHandler: machineNumberChangeHandler,
    valueBlurHandler: machineNumberBlurHandler,
    valueResetHandler: machineNumberResetHandler,
    setValueHandler: setMachineNumberHandler,
  } = useInput((value) => value.trim().length >= 1);
  const {
    value: serialNumberValue,
    isValid: serialNumberIsValid,
    hasError: serialNumberHasError,
    valueChangeHandler: serialNumberChangeHandler,
    valueBlurHandler: serialNumberBlurHandler,
    valueResetHandler: serialNumberResetHandler,
    setValueHandler: setSerialNumberHandler,
  } = useInput((value) => value.trim().length >= 1);
  const {
    value: manufacturerDateValue,
    valueChangeHandler: manufacturerDateChangeHandler,
    valueResetHandler: manufacturerDateResetHandler,
    setValueHandler: setManufacturerDateHandler,
  } = useInput((value) => value);
  const {
    value: warrantyValue,
    valueChangeHandler: warrantyChangeHandler,
    valueResetHandler: warrantyResetHandler,
    setValueHandler: setWarrantyHandler,
  } = useInput((value) => value);
  const {
    value: payoutPercentageValue,
    valueChangeHandler: payoutPercentageChangeHandler,
    valueResetHandler: payoutPercentageResetHandler,
    setValueHandler: setPayoutPercentageHandler,
  } = useInput((value) => value !== "");
  const {
    value: metrologyIdValue,
    valueChangeHandler: metrologyIdChangeHandler,
    valueResetHandler: metrologyIdResetHandler,
    setValueHandler: setMetrologyIdHandler,
  } = useInput((value) => value.trim().length >= 3);
  const {
    value: commentValue,
    valueChangeHandler: commentChangeHandler,
    valueResetHandler: commentResetHandler,
    setValueHandler: setCommentHandler,
  } = useInput((value) => value);

  const onChangePartTypeHandler = (event, newOption) => {
    setSelectedParts((prev) => [
      ...prev.filter((item) => item.part_type.id !== newOption.part_type.id),
      newOption,
    ]);
    if (
      requiredPartsDetails.find(
        (partDetails) => partDetails.name === newOption.part_type.name
      )
    )
      setRequiredPartsDetails((prev) =>
        prev.map((item) =>
          item.name === newOption.part_type.name
            ? { ...item, error: false, valid: true }
            : item
        )
      );
  };
  const onBlurPartTypeHandler = (event) => {
    const partDetails = requiredPartsDetails.find(
      (partDetails) => partDetails.name === event.target.id
    );
    if (partDetails && !event.target.value)
      setRequiredPartsDetails((prev) =>
        prev.map((item) => {
          if (item.name === event.target.id) item.error = true;
          return item;
        })
      );
  };

  const resetForm = useCallback(() => {
    ownershipResetHandler();
    locationResetHandler();
    typeResetHandler();
    cabinetResetHandler();
    machineNumberResetHandler();
    serialNumberResetHandler();
    manufacturerDateResetHandler();
    warrantyResetHandler();
    payoutPercentageResetHandler();
    metrologyIdResetHandler();
    commentResetHandler();
    setSelectedParts([]);
    setRequiredPartsDetails([
      {
        name: "Game",
        error: false,
        valid: false,
      },
      {
        name: "Version",
        error: false,
        valid: false,
      },
      {
        name: "Kernel",
        error: false,
        valid: false,
      },
    ]);
    // eslint-disable-next-line
  }, []);

  const setFields = useCallback((machine) => {
    setLocationHandler(machine?.location?.id);
    setOwnershipHandler(machine?.ownership?.id);
    setMachineNumberHandler(machine?.machine_number);
    setSerialNumberHandler(machine?.serial_number);
    !!machine?.manufacturer_date
      ? setManufacturerDateHandler(dayjs(machine?.manufacturer_date))
      : setManufacturerDateHandler(null);
    !!machine.warranty
      ? setWarrantyHandler(dayjs(machine?.warranty))
      : setWarrantyHandler(null);
    setPayoutPercentageHandler(machine?.payout_percentage);
    setMetrologyIdHandler(machine?.metrology_id);
    setTypeHandler(machine?.type);
    setCommentHandler(machine?.comment);
    setCabinetHandler(machine?.cabinet?.id);
    setSelectedParts(machine?.parts);
    machine?.parts.forEach((item) => {
      if (
        requiredPartsDetails.find(
          (partDetails) => partDetails.name === item.part_type.name
        )
      )
        setRequiredPartsDetails((prev) =>
          prev.map((prevItem) =>
            prevItem.name === item.part_type.name
              ? { ...prevItem, error: false, valid: true }
              : prevItem
          )
        );
    });
    setImageSrc(machine?.cabinet?.picture);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    resetForm();
    if (
      !modalState.type ||
      modalState.type === "add" ||
      modalState.model !== "machine"
    )
      return;

    loadLocations();

    if (machines.length > 0) {
      const machine = machines.find((machine) => machine.id === modalState.id);
      if (machine?.cabinet?.id && cabinets.length < 1)
        loadCabinet(machine?.cabinet?.id);
      if (machine) setFields(machine);
    } else if (modalState.id) {
      (async () => {
        setInnerLoading(true);
        try {
          const fetchedMachine = await getMachine(modalState.id);
          const machine = fetchedMachine.data.data;
          if (machine?.cabinet?.id && cabinets.length < 1)
            loadCabinet(machine?.cabinet?.id);
          if (machine) setFields(machine);
        } catch (error) {
          const errorMessage = error.response?.data?.message || error.message;
          showSnackbar(errorMessage, "error", "red");
        }
        setInnerLoading(false);
      })();
    }

    // eslint-disable-next-line
  }, [modalState]);

  const submitHandler = (event) => {
    event.preventDefault();
    closeModal();
    const machineObj = {
      location_id: locationValue,
      ownership_id: ownershipValue,
      machine_number: machineNumberValue,
      serial_number: serialNumberValue,
      manufacturer_date: !!manufacturerDateValue ? manufacturerDateValue : null,
      warranty: !!warrantyValue ? warrantyValue : null,
      payout_percentage: !!payoutPercentageValue ? payoutPercentageValue : null,
      metrology_id: !!metrologyIdValue ? metrologyIdValue : null,
      type: typeValue,
      comment: !!commentValue ? commentValue : null,
      cabinet_id: cabinetValue,
      part_ids: selectedParts.map((part) => part.id),
    };
    if (modalState.type === "add") {
      addMachine(machineObj);
    }
    if (modalState.type === "edit") {
      editMachine(modalState.id, machineObj);
    }
    if (modalState.type === "delete") {
      removeMachine(modalState.id);
    }
    resetForm();
  };

  let title = "";
  let buttons = (
    <>
      <Button onClick={closeModal} endIcon={<ClearOutlined />} color="error">
        Cancel
      </Button>
      <Button
        onClick={submitHandler}
        disabled={
          !locationIsValid ||
          !ownershipIsValid ||
          !machineNumberIsValid ||
          !serialNumberIsValid ||
          !typeIsValid ||
          !cabinetIsValid ||
          requiredPartsDetails.some((item) => !item.valid)
        }
        endIcon={<CheckOutlined />}
        color="success"
      >
        Submit
      </Button>
    </>
  );

  if (modalState.open && modalState.type) {
    title = getTitleForModal(modalState.type);
  }
  if (modalState.type === "view") {
    buttons = (
      <Button onClick={closeModal} endIcon={<Close />} color="primary">
        Close
      </Button>
    );
  }

  return (
    modalState.open &&
    modalState.model === "machine" && (
      <NTSModal
        title={`${title} MACHINE`}
        loading={
          companiesLoading ||
          locationsLoading ||
          cabinetsLoading ||
          partsLoading ||
          partTypesLoading ||
          innerLoading
        }
        loadingSx={{ marginBottom: 5 }}
        maxWidth={modalState.type === "delete" ? "sm" : "lg"}
      >
        {modalState.type === "delete" ? (
          <DialogContentText>{`Are you sure that you want to remove ${typeValue} ${machineNumberValue}?`}</DialogContentText>
        ) : (
          <Stack
            direction={{ xs: "column", md: "row" }}
            spacing={1}
            alignItems="center"
          >
            {(modalState.type === "edit" || modalState.type === "view") && (
              <img
                src={`${process.env.REACT_APP_IMAGES_URL}/cabinets/${imageSrc}`}
                alt="cabinet"
                style={{
                  width: "27.4%",
                  marginTop: "16px",
                  paddingTop: "16px",
                }}
              />
            )}
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth sx={{ mt: 2 }}>
                  <Autocomplete
                    id="location"
                    options={locations}
                    value={
                      locations.find(
                        (location) => location.id === locationValue
                      ) || null
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={locationHasError}
                        label="Location *"
                      />
                    )}
                    getOptionLabel={(option) =>
                      `${option.show_name ?? option.name} - ${
                        option.company.groupation_name
                      }`
                    }
                    onChange={(event, newOption) =>
                      setLocationHandler(newOption?.id ?? null)
                    }
                    onClose={locationBlurHandler}
                    disabled={modalState.type === "view"}
                    required
                    disableClearable
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="machine_number"
                  label="Machine Number"
                  name="machine_number"
                  disabled={modalState.type === "view"}
                  value={machineNumberValue}
                  onChange={machineNumberChangeHandler}
                  onBlur={machineNumberBlurHandler}
                  error={machineNumberHasError}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <ConfirmationNumberOutlined />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth sx={{ mt: 2 }}>
                  <Autocomplete
                    id="cabinet"
                    options={cabinets}
                    value={
                      cabinets.find((cabinet) => cabinet.id === cabinetValue) ||
                      null
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={cabinetHasError}
                        label="Cabinet *"
                      />
                    )}
                    getOptionLabel={(option) => option.show_name ?? option.name}
                    onChange={(event, newOption) =>
                      setCabinetHandler(newOption?.id ?? null)
                    }
                    onClose={cabinetBlurHandler}
                    disabled={modalState.type === "view"}
                    required
                    disableClearable
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="serial_number"
                  label="Serial Number"
                  name="serial_number"
                  disabled={modalState.type === "view"}
                  value={serialNumberValue}
                  onChange={serialNumberChangeHandler}
                  onBlur={serialNumberBlurHandler}
                  error={serialNumberHasError}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <NumbersOutlined />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    label="Manufacturer Date"
                    inputFormat="DD/MM/YYYY"
                    value={
                      !!manufacturerDateValue ? manufacturerDateValue : null
                    }
                    disabled={modalState.type === "view"}
                    onChange={manufacturerDateChangeHandler}
                    renderInput={(params) => (
                      <TextField {...params} sx={{ width: "100%", mt: 2 }} />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} sm={4}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    label="Warranty"
                    inputFormat="DD/MM/YYYY"
                    value={!!warrantyValue ? warrantyValue : null}
                    disabled={modalState.type === "view"}
                    onChange={warrantyChangeHandler}
                    renderInput={(params) => (
                      <TextField {...params} sx={{ width: "100%", mt: 2 }} />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  margin="normal"
                  fullWidth
                  id="payout_percentage"
                  label="Payout Percentage"
                  name="payout_percentage"
                  disabled={modalState.type === "view"}
                  value={payoutPercentageValue}
                  onChange={payoutPercentageChangeHandler}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PercentOutlined />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  margin="normal"
                  fullWidth
                  id="metrology_id"
                  label="Metrology ID"
                  name="metrology_id"
                  disabled={modalState.type === "view"}
                  value={metrologyIdValue}
                  onChange={metrologyIdChangeHandler}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <WebAssetOutlined />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth sx={{ mt: 2 }}>
                  <Autocomplete
                    id="type"
                    options={MachineTypes}
                    value={
                      MachineTypes.find((type) => type === typeValue) || null
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={typeHasError}
                        label="Type *"
                      />
                    )}
                    onChange={(event, newOption) => setTypeHandler(newOption)}
                    onClose={typeBlurHandler}
                    disabled={modalState.type === "view"}
                    required
                    disableClearable
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth sx={{ mt: 2 }}>
                  <Autocomplete
                    id="ownership"
                    options={companies}
                    value={
                      companies.find((type) => type.id === ownershipValue) ||
                      null
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={ownershipHasError}
                        label="Ownership *"
                      />
                    )}
                    getOptionLabel={(option) => option.groupation_name}
                    onChange={(event, newOption) =>
                      setOwnershipHandler(newOption?.id ?? null)
                    }
                    onClose={ownershipBlurHandler}
                    disabled={modalState.type === "view"}
                    required
                    disableClearable
                  />
                </FormControl>
              </Grid>
              {partTypes.map((partType, index) => (
                <Grid key={index} item xs={12} sm={4}>
                  <FormControl fullWidth sx={{ mt: 2 }}>
                    <Autocomplete
                      id={`${partType.name}`}
                      options={parts.filter(
                        (part) => part.part_type.id === partType.id
                      )}
                      value={
                        parts.find(
                          (part) =>
                            part.id ===
                            selectedParts.find(
                              (p) => p.part_type.id === partType.id
                            )?.id
                        ) || null
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={
                            requiredPartsDetails.find(
                              (partDetails) =>
                                partDetails.name === partType.name
                            )?.error
                          }
                          label={`${partType.name} ${
                            !!requiredPartsDetails.find(
                              (partDetails) =>
                                partDetails.name === partType.name
                            )
                              ? "*"
                              : ""
                          }`}
                        />
                      )}
                      getOptionLabel={(option) => option.name}
                      onBlur={onBlurPartTypeHandler}
                      onChange={onChangePartTypeHandler}
                      disabled={modalState.type === "view"}
                      required={
                        !!requiredPartsDetails.find(
                          (partDetails) => partDetails.name === partType.name
                        )
                      }
                      disableClearable
                    />
                  </FormControl>
                </Grid>
              ))}
              <Grid item xs={12}>
                <TextField
                  margin="normal"
                  fullWidth
                  id="comment"
                  label="Comment"
                  name="comment"
                  disabled={modalState.type === "view"}
                  value={commentValue}
                  onChange={commentChangeHandler}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SmsOutlined />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </Stack>
        )}
        <NTSModalFooter
          sx={{
            display: "flex",
            justifyContent: "end",
            marginY: 2,
          }}
        >
          {buttons}
        </NTSModalFooter>
      </NTSModal>
    )
  );
};

export default MachineModal;
