import { Container } from "@mui/material";
import { useEffect } from "react";
import AllDeviceTypes from "../components/DeviceType/AllDeviceTypes";
import DeviceTypeModal from "../components/DeviceType/DeviceTypeModal";
import CRUDHeader from "../components/UI/CRUDHeader";
import { useDevices } from "../context/device-context";
import { useModal } from "../context/modal-context";

const DeviceTypes = () => {
  const { loadDeviceTypes } = useDevices();
  const { openModal } = useModal();

  useEffect(() => {
    loadDeviceTypes();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Container maxWidth="sm" sx={{ paddingBottom: "16px" }}>
        <CRUDHeader
          title="Device Types"
          onAddNew={() => openModal("add", "deviceType")}
          onRefresh={() => loadDeviceTypes()}
        />
        <AllDeviceTypes />
      </Container>
      <DeviceTypeModal />
    </>
  );
};

export default DeviceTypes;
