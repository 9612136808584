import { useModal } from "../../context/modal-context";
import { useSpareParts } from "../../context/spare-part-context";
import NTSTable from "../UI/NTSTable";

const AllSpareParts = () => {
  const { spareParts, loading } = useSpareParts();
  const { openModal } = useModal();

  const cols = [
    { field: "name", headerName: "Name", type: "string", width: 280 },
    {
      field: "article_number",
      headerName: "Article No.",
      type: "string",
      width: 150,
    },
    {
      field: "part_number",
      headerName: "Part No.",
      type: "string",
      width: 150,
    },
  ];

  return (
    <NTSTable
      onEdit={(id) => openModal("edit", "sparePart", id)}
      onRemove={(id) => openModal("delete", "sparePart", id)}
      data={spareParts}
      cols={cols}
      loading={loading}
      actions={true}
    />
  );
};

export default AllSpareParts;
